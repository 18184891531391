<div class="custom-autocomplete" *ngIf="inputPhonenumberFormGroup" [formGroup]="inputPhonenumberFormGroup">
    <!-- <mat-label *ngIf="inputAutocompleteLabel" class="color-primary">{{inputAutocompleteLabel}}<sup class="color-red"
            *ngIf="inputAutocompleteRequiredStatus && !requiredIndicator">&ast;</sup></mat-label> -->
    <mat-label *ngIf="inputLabel" class="color-primary">{{inputLabel}}<sup class="color-red"
            *ngIf="inputRequiredStatus">&ast;</sup></mat-label>
    <div class="d-flex flex-column">
        <!-- <mat-form-field class="example-full-width" [appearance]="inputAutocompleteAppearance"
            [ngClass]="{removeInputBottomPadding: inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.touched && inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.errors && !requiredIndicator}">

            <div class="border">
                <mat-label *ngIf="inputAutocompletePlaceholderLabel">{{inputAutocompletePlaceholderLabel}}</mat-label>
                <input #inputBox type="text" [placeholder]="inputAutocompletePlaceholder" aria-label="Number" matInput
                    [readonly]="inputAutoCompletereadonly" [formControlName]="inputAutocompleteFormControlName"
                    [matAutocomplete]="inputAutoCompleteReference" [required]="inputAutocompleteRequiredStatus">
                <mat-autocomplete #inputAutoCompleteReference="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="SelectedOption($event)">
                    <mat-option *ngFor="let option of filterAutocompleteList | async;let optionIndex=index"
                        [value]="option"
                        [disabled]="inputAutocompleteDisableIndex ? inputAutocompleteDisableState && inputAutocompleteDisableIndex.includes(optionIndex) : inputAutocompleteDisableState">
                        {{option?.hasOwnProperty(inputAutocompleteFilterKey) && option?.[inputAutocompleteFilterKey] ?
                        option?.[inputAutocompleteFilterKey] : option?.name ? option?.name : option}}
                    </mat-option>
                </mat-autocomplete>inputPhonenumberFormGroup
            </div>

            <div class="border">
                <input matInput #inputTemplateReference [id]="inputId" [type]="inputType"
                    [placeholder]="inputPlaceholder" *ngIf="!inputValidationType" [value]="inputValue"
                    [formControlName]="inputAutocompleteFormControlName" [autocomplete]="inputAutocompleteStatus"
                    [minlength]="inputMinLength" [maxlength]="inputMaxLength" [required]="inputRequiredStatus"
                    [readonly]="inputDisableState"
                    (keypress)="inputOnKeyPressFunction && inputOnKeyPressFunction($event)" #inputBox>
            </div>

        </mat-form-field> -->


        <div class="d-flex custom_phonenumber">
            <mat-form-field class="example-full-width flex_div_date" [appearance]="inputPhoneAutocompleteAppearance"
            [ngClass]="{removeInputBottomPadding: inputPhonenumberFormGroup?.get(inputPhoneAutocompleteFormControlName)?.touched && inputPhonenumberFormGroup?.get(inputPhoneAutocompleteFormControlName)?.errors && !requiredIndicator}">
            <mat-label *ngIf="inputPhoneAutocompletePlaceholderLabel">{{inputPhoneAutocompletePlaceholderLabel}}</mat-label>
                <input #inputBox class="country_code" type="text" [placeholder]="inputPhoneAutocompletePlaceholder" aria-label="Number" matInput
                    [readonly]="inputPhoneAutoCompletereadonly" [formControlName]="inputPhoneAutocompleteFormControlName"
                    [matAutocomplete]="inputPhoneAutoCompleteReference" [required]="inputPhoneAutocompleteRequiredStatus">
                <mat-autocomplete #inputPhoneAutoCompleteReference="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="SelectedOption($event)">
                    <mat-option *ngFor="let option of filterAutocompleteList | async;let optionIndex=index"
                        [value]="option"
                        [disabled]="inputPhoneAutocompleteDisableIndex ? inputPhoneAutocompleteDisableState && inputPhoneAutocompleteDisableIndex.includes(optionIndex) : inputPhoneAutocompleteDisableState">
                        {{option?.hasOwnProperty(inputPhoneAutocompleteFilterKey) && option?.[inputPhoneAutocompleteFilterKey] ?
                        option?.[inputPhoneAutocompleteFilterKey] : option?.name ? option?.name : option}}
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>

        <!-- <mat-form-field  class="flex_2"[appearance]="inputAutocompleteAppearance"
        [ngClass]="{removeInputBottomPadding: inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.touched && inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.errors && !requiredIndicator}">
            <input matInput #inputTemplateReference [id]="inputId" [type]="inputType"
                    [placeholder]="inputPlaceholder" *ngIf="!inputValidationType" [value]="inputValue"
                    [formControlName]="inputAutocompleteFormControlName" [autocomplete]="inputAutocompleteStatus"
                    [minlength]="inputMinLength" [maxlength]="inputMaxLength" [required]="inputRequiredStatus"
                    [readonly]="inputDisableState"
                    (keypress)="inputOnKeyPressFunction && inputOnKeyPressFunction($event)" #inputBox>
        </mat-form-field> -->

        <mat-form-field  class="flex_div_2"[appearance]="inputAppearance"
        [ngClass]="inputClass.includes('removeInputBottomPadding') ? inputClass : {removeInputBottomPadding: inputPhonenumberFormGroup.get(inputFormControlName)?.touched && inputPhonenumberFormGroup.get(inputFormControlName)?.errors}">
            <input matInput #inputTemplateReference [id]="inputId" [type]="inputType"
                    [placeholder]="inputPlaceholder" *ngIf="!inputValidationType" [value]="inputValue"
                    [formControlName]="inputFormControlName" [autocomplete]="inputAutocompleteStatus"
                    [minlength]="inputMinLength" [maxlength]="inputMaxLength" [required]="inputRequiredStatus"
                    [readonly]="inputDisableState"
                    (keypress)="inputOnKeyPressFunction && inputOnKeyPressFunction($event)" #inputBox>
        </mat-form-field>
        </div>
        <!-- custom error component -->
        <!-- <custom-error [customErrorLabel]="inputAutoCompleteErrorLabel"
            *ngIf="inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.touched && inputPhonenumberFormGroup?.get(inputAutocompleteFormControlName)?.errors && !requiredIndicator">
        </custom-error> -->
        <custom-error [customErrorLabel]="inputErrorLabel" [customErrorIconShowStatus]="inputErrorIconStatus"
            [customErrorIconName]="inputErrorIconName" [customErrorPreFixPath]="inputErrorIconPrefixPath"
            *ngIf="inputPhonenumberFormGroup.get(inputFormControlName)?.touched && inputPhonenumberFormGroup.get(inputFormControlName)?.errors">
        </custom-error>
    </div>
</div>