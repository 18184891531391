import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'success-modal-prompt',
  templateUrl: './success-modal-prompt.component.html',
  styleUrls: ['./success-modal-prompt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessModalPromptComponent implements OnInit {

  constructor(public _successDialogRef: MatDialogRef<SuccessModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public successDialogData: any) { }

  ngOnInit(): void { }

  dialogClose(params?: any) {
    this._successDialogRef.close(params);
  }
  onCancel(): void {
    // Directly close the dialog without any additional logic
    this._successDialogRef.close();
  }
}
