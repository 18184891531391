import { Pipe, PipeTransform } from '@angular/core';
import { ToWords } from 'to-words';

@Pipe({
  name: 'wordconvert',
  standalone: false
})
export class WordconvertPipe implements PipeTransform {

  converWordText : string = '';

  transform(value: any) {
    const toWords = new ToWords({localeCode: 'en-US'});
    if(value == ''){
      this.converWordText = ''
     }
     else{
      let words = toWords.convert(parseInt(value));
      this.converWordText = words;
     }
     return this.converWordText
  }

}
