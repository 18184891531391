import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common/common.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { ApiMethod, Endpoints } from 'src/app/core/services/utils/constants';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-comments-modal-prompt',
  templateUrl: './comments-modal-prompt.component.html',
  styleUrls: ['./comments-modal-prompt.component.scss']
})
export class CommentsModalPromptComponent implements OnInit {
  supplierStatusForm: FormGroup | any;
  staticText: any = (textConfiguration as any).default;
  rejectStatusList: any;

  // @Output() comment = new EventEmitter();

  constructor(
    private _http: HttpService,
    protected _common: CommonService,
    private formBuilder: FormBuilder,
    private _formValidator: FormValidatorService,
    public _deleteDialogRef: MatDialogRef<CommentsModalPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public commentsDialogData: any) { }

  ngOnInit(): void {
    this.initialDependencies();
  }

  initialDependencies() {
    this.commentsForm();
  }
  commentsForm() {
    this.supplierStatusForm = this.formBuilder.group({
      comments: ['', [this._formValidator.requiredValidationCheck('Comments')]],
      verfication_status: ['',],
    })
  }

  dialogClose(params?: any) {
    this._deleteDialogRef.close(params);
  }

  submitComment(closeDialog: any, data: any, formType: any) {
    this._deleteDialogRef.close({ close: closeDialog, data: data, formType: formType });
  }

}
