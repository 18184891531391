import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldsModule } from './custom-fields/custom-fields.module';
import { MaterialModule } from '../../material/material.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CustomFieldsModule,
    MaterialModule,
  ],
  exports: [
    CustomFieldsModule,
  ]
})
export class MatElementsModule { }
