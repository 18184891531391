import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { DataService } from 'src/app/shared/services/data/data.service';

@Component({
  
  

  selector: 'app-auto-complete-jira',
 
  templateUrl: './auto-complete-jira.component.html',
  styleUrl: './auto-complete-jira.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AutoCompleteJiraComponent implements OnInit {
  @Input() inputAutocompleteArray: any;
  @Input() preferredId: boolean = false;
  @Input() inputAutocompleteLabel: any;
  @Input() inputAutocompleteAppearance: any = 'outline';
  @Input() inputAutocompletePlaceholderLabel: any;
  @Input() inputAutoCompleteErrorLabel: any;
  @Input() inputAutocompletePrefixIcon: any;
  @Input() inputAutocompleteSuffixIcon: string = 'dropdown_arow_down';
  @Input() inputAutocompletePlaceholder: any;
  @Input() inputAutocompleteFormControlName: any = '';
  @Input() inputAutocompleteFilterKey: string = 'display_name';
  @Input() inputAutocompleteFormGroup?: FormGroup;
  @Input() inputAutocompleteRequiredStatus: boolean = false;
  @Input() inputAutoCompletereadonly: boolean = false;
  @Input() inputAutocompletePrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputAutocompleteSuffixIconClass: any = 'pe-3 color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputAutocompleteDisableState: boolean = false;
  @Input() inputAutoCompletereadonlyColor: boolean = false;
  @Input() inputAutocompleteDisableIndex: any;
  @Input() autocompletevaluekey: any;
  @Input() requiredIndicator: boolean = false;
  @Input() ondemand: boolean = false;
  @Output() inputAutoCompleteEmitter = new EventEmitter<any>();
  @Output() inputAutoCompleteValueEmitter = new EventEmitter<any>();
  @Output() clearAction = new EventEmitter<any>();
  @ViewChild('inputBox') inputBox!: ElementRef;
  filterAutocompleteList?: Observable<any[]>;
  @Input() removePadding: boolean = false;
  destroyed = new Subject<void>();
  @ViewChildren('inputAutoCompleteReference') inputAutoCompleteReference!: QueryList<ElementRef>;

  constructor(
    private cdr: ChangeDetectorRef,
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    this._dataService.deSelectAutoComplete$
      .pipe(takeUntil(this.destroyed))
      .subscribe((res: any) => {
        if (res) {
          this.inputAutocompleteFormGroup?.get(res)?.setValue(null);
        }
      });
  }

  ngAfterViewInit(): void {
    this.inputAutocompleteFormGroup?.get(this.inputAutocompleteFormControlName)?.valueChanges.subscribe((value: string) => {
      if (this.inputBox) {
        this.adjustWidth(this.inputBox.nativeElement);
      }
    });
    
    if (this.ondemand) {
      fromEvent(this.inputBox?.nativeElement, 'input')
        .pipe(
          map((e: any) => {
            return (e.target as HTMLInputElement).value;
          }),
          //filter(text => text.length > 2),
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(async (x) => {
            this.inputAutoCompleteValueEmitter.emit(x);
          })
        )
        .subscribe((response: any) => { });
    }
    if (this.inputBox) {
      this.adjustWidth(this.inputBox.nativeElement);
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['inputAutocompleteArray'] && !this.ondemand) {
      this.getFilterAutocompleteList();
    }
  }

  clearValue() {
    const control = this.inputAutocompleteFormGroup?.get(this.inputAutocompleteFormControlName);
    if (control) {
      control.setValue(null);
    }
    setTimeout(() => {
      this.adjustWidth(this.inputBox.nativeElement);
    });

    this.clearAction.emit();
  }

  /* below function will emit selected options */
  protected SelectedOption(option: any) {
    this.inputAutoCompleteEmitter.emit(option?.option?.value);
  }

  /* AutoComplete List for Parent component usage (It can be used in other component) */
  getAPIAutocompleteList() {
    this.getFilterAutocompleteList();
  }

  private getFilterAutocompleteList() {
    this.filterAutocompleteList = this.inputAutocompleteFormGroup
      ?.get(this.inputAutocompleteFormControlName)
      ?.valueChanges.pipe(
        startWith(''),
        map((value: any) =>
          typeof value === 'string'
            ? value
            : value?.[this.inputAutocompleteFilterKey]
        ),
        map((name: any) =>
          name
            ? this._filterArray(
              name,
              this.inputAutocompleteArray,
              this.inputAutocompleteFilterKey
            )
            : this.inputAutocompleteArray?.slice()
        )
      );
  }

  private _filterArray(value: any, arr: any, key: any): string[] {
    arr = arr ?? [];
    const filterValue = value?.toLowerCase();
    return arr.filter((tag: any) =>
      tag?.[key]?.toLowerCase().includes(filterValue)
    );
  }

  protected displayFn = (data: any) => {
    if (data?.[this.inputAutocompleteFilterKey]) {
      return data?.[this.inputAutocompleteFilterKey]
        ? data[this.inputAutocompleteFilterKey]
        : '';
    } else if (data?.name) {
      return data?.name;
    } else {
      return data;
    }
  };

  adjustWidth(inputElement: HTMLInputElement) {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.style.whiteSpace = 'nowrap';
    span.textContent = inputElement.value || inputElement.placeholder || '';
    document.body.appendChild(span);
    const width = span.offsetWidth;
    inputElement.style.width = `${width + 0}px`;
    document.body.removeChild(span);
  }
}
