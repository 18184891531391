import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-text-area',
  templateUrl: './custom-text-area.component.html',
  styleUrls: ['./custom-text-area.component.scss']
})
export class CustomTextAreaComponent implements OnInit {

  @Input() inputTextAreaFormGroup: FormGroup | any;
  @Input() inputTextAreaFormControlName: any;
  @Input() inputTextAreaClass: any = 'example-full-width margin-top-comments';
  @Input() inputTextAreaId: any;
  @Input() inputTextAreaLabel: any;
  @Input() inputTextAreaPlaceholderLabel: any;
  @Input() inputTextAreaAppearance: any = 'outline';
  @Input() inputTextAreaPlaceHolder: any;
  @Input() inputTextAreaErrorLabel: any;
  @Input() inputTextAreaMinRowSize: any = 1;
  @Input() inputTextAreaMaxRowSize: any = 10;
  @Input() inputTextAreaRequiredStatus: boolean = false;
  @Input() inputTextAreaReadOnly: boolean = false;
  @Input() inputTextAreaPrefixIcon: any;
  @Input() inputTextAreaSuffixIcon: any;
  @Input() inputTextAreaPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputTextAreaSuffixIconClass: any = 'p-15 color-gray fs-14 ml-half-rem';
  @Input() inputTextAreaIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputTextAreaMinLength: number | any = 0;
  @Input() inputTextAreaMaxLength: number | any = 256;
  @Input() inputTextAreaInfoMsg: string = '';
  @Input() customBackground:boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
