<div class="custom-date-picker" [formGroup]="inputDatePickerFormGroup">
    <mat-label *ngIf="inputDatePickerLabel" class="color-primary"
        [ngClass]="removePadding?'text-smaller':''">{{inputDatePickerLabel}}<sup class="color-red"
            *ngIf="inputDatePickerRequiredStatus">&ast;</sup></mat-label>
    <div class="d-flex flex-column">
        <!-- custom date picker input -->
        <mat-form-field [appearance]="inputDatePickerAppearance" *ngIf="inputDatePickerType == 'date'"
            [ngClass]="{removeInputBottomPadding: inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.touched && inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.errors,'remove-padding':removePadding}">
            <mat-label *ngIf="inputDatePickerPlaceholderLabel">{{inputDatePickerPlaceholderLabel}}</mat-label>
            <input matInput [matDatepicker]="inputDatePickeReference" [formControlName]="inputDatePickerFormControlName"
                [readonly]="true" [placeholder]="inputDatePickerPlaceHolder" [min]="inputDatePickerMinDate" 
                [max]="inputDatePickerMaxDate" [required]="inputDatePickerRequiredStatus"
                (dateChange)="onDateChanged($event)">
            <mat-hint *ngIf="inputDatePickerHint">{{inputDatePickerHint}}</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="inputDatePickeReference" *ngIf="!inputDisableState">
                <img matDatepickerToggleIcon [src]="inputDatePickerIconImgBasepath+inputDatePickerToggleIcon+'.svg'"
                    [alt]="inputDatePickerToggleIcon">
            </mat-datepicker-toggle>
            <mat-datepicker #inputDatePickeReference [startView]="inputDatePickerStartView"></mat-datepicker>
        </mat-form-field>
        <!-- custom date picker input -->
        <!-- custom date range picker input -->
        <mat-form-field [appearance]="inputDatePickerAppearance" *ngIf="inputDatePickerType == 'range'"
            [ngClass]="{removeInputBottomPadding: inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.touched && inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.errors,'remove-padding':removePadding}">
            <mat-label *ngIf="inputDatePickerPlaceholderLabel">{{inputDatePickerPlaceholderLabel}}</mat-label>
            <mat-date-range-input [formGroupName]="inputDatePickerFormControlName"
                [rangePicker]="inputDatePickeReference" [required]="inputDatePickerRequiredStatus"
                (dateChange)="onDateChanged($event)" [min]="inputDatePickerMinDate" [max]="inputDatePickerMaxDate">
                <input matStartDate placeholder="Date"
                    [formControlName]="filterOption?.formControlName_start || 'start'" class="designAlign"
                    (focus)="openMethod()">
                <input matEndDate placeholder="" [formControlName]="filterOption?.formControlName_end || 'end'"
                    (focus)="openMethod()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="inputDatePickeReference">
                <img matDatepickerToggleIcon [src]="inputDatePickerIconImgBasepath+inputDatePickerToggleIcon+'.svg'"
                    [alt]="inputDatePickerToggleIcon">
            </mat-datepicker-toggle>
            <mat-date-range-picker #inputDatePickeReference
                [startView]="inputDatePickerStartView"></mat-date-range-picker>
        </mat-form-field>
        <!-- custom date range picker input -->

        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputDatePickerErrorLabel"
            *ngIf="inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.touched && inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.errors">
        </custom-error>
    </div>
</div>