import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren, SimpleChanges } from '@angular/core';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { colorCodes, FailedMessage } from 'src/app/core/services/utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-custom-multi-fileupload',
  standalone: false,

  templateUrl: './custom-multi-fileupload.component.html',
  styleUrl: './custom-multi-fileupload.component.scss'
})
export class CustomMultiFileuploadComponent {
  folderName!: string;
  fileValue: Array<any> = [];
  staticText: any = (textConfiguration as any).default;
  enableClear: boolean = false;
  @Output() inputFileOnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputFileOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() disableFileUpload: boolean = false;
  @ViewChildren('inputFilePickeReference') inputFilePickeReference: QueryList<ElementRef> | any;
  @Output() clearFiles: EventEmitter<void> = new EventEmitter<void>(); // New event emitter for clearing files
  @Input() required: boolean = true; // Add required input property
  showError: boolean = false; // Flag to show error message
  errorMessage: string = '';  // Error message to display
  validateOnTrigger: boolean = false; // Flag to trigger validation on button click

  @Input() inputFileFormGroup!: FormGroup;
  @Input() inputFileFormControlName: any = '';
  @Input() inputFileErrorLabel: any;
  @Input() inputFileLabel: any;
  @Input() inputFilePlaceholderLabel: any;
  @Input() inputFileErrorIconStatus: boolean = false;
  @Input() inputFileErrorIconName!: string;
  @Input() inputFileAcceptExtenstions: any = ".jpg, .png, .doc, .xls, .xlxs .ppt, .pdf";
  @Input() maxFileSize: any = 5; // in MB
  @Input() maxFile: any = 5; // in MB

  constructor(
    private _snackBar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.fileValue = this.inputFileFormGroup?.get(this.inputFileFormControlName)?.value || [];
  }

  get getFileFormats(): any {
    return this.inputFileAcceptExtenstions?.replaceAll('.', '');
  }

  bytesToMB(bytes: any) {
    return (bytes / (1024 * 1024));
  }

  mbToByte(mb: any) {
    return mb * 1024 * 1024;
  }

  isAllowedExtension(fileName: any, allowedExtensions: any) {
    // Get the file extension
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check if the file extension is in the allowed extensions list
    return allowedExtensions.includes(`.${fileExtension}`);
  }

  onSelectFile(event: any) {
    const files = event?.target?.files;
    
    const maxFiles = this.maxFile;

    if (this.fileValue.length + files.length > maxFiles) {
      this._snackBar.loadSnackBar(`You can only upload a maximum of ${maxFiles} files.`, colorCodes.ERROR);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      
      const formatArray = this.inputFileAcceptExtenstions?.split(", ")?.map((format: any) => format.trim());
      if (!this.isAllowedExtension(files[i]?.name, formatArray)) {
        this._snackBar.loadSnackBar(`File support format: ${this.getFileFormats}`, colorCodes.ERROR);
      } else if (files[i]?.size > this.mbToByte(this.maxFileSize)) {
        this._snackBar.loadSnackBar(`File size should not exceed ${this.maxFileSize} MB`, colorCodes.ERROR);
      }
      else {
        this.fileValue.push(files[i]);
        this.inputFileOnSelect.emit(files);
      }
    }
  }
  openInNewTab(fileData: any): void {
    if (this.fileValue) {
      // Open the file's attachment URL in a new tab
      window.open(this.fileValue[0]?.attachment, '_blank');
    } else {
      console.error('Attachment URL is not available.');
    }
  }

  removeFile(index: number): void {
    if (index > -1 && index < this.fileValue.length) {
      const removedFile = this.fileValue.splice(index, 1)[0];
      this.inputFileOnDelete.emit(removedFile);
    }
  }

  clearValue() {
    this.fileValue = [];

    // Reset the file input field (to allow re-selection of the same file)
    const fileChooser: any = document.querySelector('.file-chooser');
    if (fileChooser) {
      fileChooser.value = null;
    }
  }
}
