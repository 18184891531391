import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerIntl } from '@angular/material/datepicker';
import { DataService } from 'src/app/shared/services/data/data.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
@Component({
  selector: 'custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomDatePickerComponent implements OnInit {

  @Input() inputDisableState: boolean = false;
  @Input() inputDatePickerFormGroup: FormGroup | any;
  @Input() inputDatePickerHint: any;
  @Input() inputDatePickerLabel: any;
  @Input() inputDatePickerPlaceholderLabel: any;
  @Input() inputDatePickerMinDate: any;
  @Input() inputDatePickerMaxDate: any;
  @Input() inputDatePickerErrorLabel: any;
  @Input() inputDatePickerPlaceHolder: any;
  @Input() inputDatePickerFormControlName: any;
  @Input() inputDatePickerLocale: any = 'en-GB';
  @Input() inputDatePickerStartView: any = 'month';
  @Input() inputDatePickerAppearance: any = 'outline';
  @Input() inputDatePickerRequiredStatus: boolean = false;
  @Input() inputDatePickerIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputDatePickerToggleIcon: string = 'calendar';
  @Output() inputDatePickerchange = new EventEmitter();
  @Input('inputDatePickerType') inputDatePickerType: string = 'date'; // Range
  @Input() filterOption: any = {};
  @Input() removePadding: boolean = false;

  @ViewChildren('inputDatePickeReference') inputDatePickeReference: QueryList<ElementRef> | any;

  constructor(private _adapter: DateAdapter<any>, private _dataService: DataService,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) { }

  ngOnInit(): void {
    this.setLocale();
    this._dataService.deselectedRange$.subscribe((o: any) => {
      if (o) {
        this.inputDatePickerFormGroup?.get(o)?.reset();
      }
    })

  }

  setLocale() {
    this._adapter.setLocale(this.inputDatePickerLocale)
  }


  onDateChanged(ev?: any) {
    console.log('data onDateChanged this.selected: ', ev)
    this.inputDatePickerchange.emit();
  }

  openMethod() {
    this.inputDatePickeReference.first.open();
  }

}
