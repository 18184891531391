<div class="custom-select" [formGroup]="inputSelectFormGroup">
    <mat-label *ngIf="inputSelectLabel" class="color-primary">{{inputSelectLabel}}<sup class="color-red"
            *ngIf="inputSelectRequiredStatus">&ast;</sup></mat-label>
    <div class="d-flex flex-column" [ngClass]="customBackground" [ngClass]="{'disabled-field': inputSelectreadonly, 'disabled-field-color': inputSelectreadonlyColor}">
        <mat-form-field [appearance]="inputSelectAppreance"
            [ngClass]="{removeInputBottomPadding: inputSelectFormGroup?.get(inputSelectFormContolName)?.touched && inputSelectFormGroup?.get(inputSelectFormContolName)?.errors}">
            <!-- custom select field prefix icon -->
            <span matPrefix *ngIf="inputSelectPrefixIcon && !customBackground" [ngClass]="inputSelectPrefixIconClass">
                <!-- below line will render font awesome Icon -->
                <i [class]="inputSelectPrefixIcon" *ngIf="inputSelectPrefixIcon?.includes('fa');else loadImg;"></i>
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputSelectPrefixIcon+'.svg'" alt="icon">
                </ng-template>
            </span>
            <mat-label *ngIf="inputSelectPlaceholderLabel">{{inputSelectPlaceholderLabel}}</mat-label>
            <mat-select [formControlName]="inputSelectFormContolName" [placeholder]="inputSelectPlaceholder"
                (selectionChange)="selectedValueEmit($event)" [disabled]="inputSelectDisableState"
                [multiple]="inputSelectMutipleOptionStatus" [required]="inputSelectRequiredStatus">
                <div *ngIf="!inputSelectGroupBy">
                    <mat-option *ngFor="let select of inputSelectArray;" [value]="preferredId ? select.id : select">
                        {{select?.hasOwnProperty(inputSelectOptionKey) && select?.[inputSelectOptionKey] ?
                        select?.[inputSelectOptionKey] : select?.name ? select?.name : select}}
                    </mat-option>
                </div>
                <mat-select-trigger>
                    {{ getDisplayText }}
                  </mat-select-trigger>
                <div *ngIf="inputSelectGroupBy">
                    <mat-optgroup *ngFor="let select of inputSelectArray" [label]="select.display_name" [disabled]="inputSelectDisableState">
                        <mat-option *ngFor="let data of select?.group" [value]="preferredId ? data?.id : data">
                            {{data.display_name}}
                        </mat-option>
                    </mat-optgroup>

                </div>
            </mat-select>
            <!-- custom select field suffix icon -->
            <span matSuffix *ngIf="inputSelectSuffixIcon && !customBackground && !enableClear" [ngClass]="inputSelectSuffixIconClass">
                <!-- <i class="fa fa-user"></i> -->
                <!-- below line will render font awesome Icon -->
                <i [class]="inputSelectSuffixIcon" *ngIf="inputSelectSuffixIcon?.includes('fa');else loadImg;"></i>
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputSelectSuffixIcon+'.svg'" alt="icon">
                </ng-template>
            </span>
            <span matSuffix *ngIf="enableClear" [ngClass]="inputSelectSuffixIconClass" (click)="clear()">
                <i class="fa fa-xmark"></i>
            </span>
            <span *ngIf="customBackground" matSuffix>
                <i class="fa fa-angle-down" [ngClass]="customBackground === 'opened'?'arrow_white':'arrow_blck'"></i>
            </span>
        </mat-form-field>
        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputSelectErrorLabel"
            *ngIf="inputSelectFormGroup?.get(inputSelectFormContolName)?.touched && inputSelectFormGroup?.get(inputSelectFormContolName)?.errors">
        </custom-error>
    </div>
</div>