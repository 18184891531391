import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, fromEvent, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'custom-input-telnumber',
  templateUrl: './custom-input-telnumber.component.html',
  styleUrls: ['./custom-input-telnumber.component.scss']
})
export class CustomInputTelnumberComponent implements OnInit {

  @Input() inputPhonenumberFormGroup: FormGroup | any;

  // @Input() inputFormGroup: FormGroup | any;
  @Input() inputFormControlName: any;
  @Input() inputClass: any = 'w-100';
  @Input() inputId: any;
  @Input() inputType: any = 'text';
  @Input() inputLabel: any;
  @Input() inputPlaceholderLabel: any;
  @Input() icons: any;
  @Input() inputAppearance: any = 'outline';
  @Input() inputPlaceholder: any;
  @Input() inputAutocompleteStatus: any = 'off';
  @Input() inputValue: any;
  @Input() inputPrefixIcon: any;
  @Input() inputSuffixIcon: any;
  @Input() inputPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputSuffixIconClass: any = 'p-15 color-gray fs-14 ml-half-rem';
  @Input() inputSuffixFunction: any;
  @Input() inputErrorLabel: any;
  @Input() inputMinLength: any;
  @Input() inputMaxLength: any;
  @Input() inputRequiredStatus: boolean = false;
  @Input() inputTemplateReference: HTMLElement | any;
  @Input() inputValidationType: any
  @Input() inputOnKeyPressFunction: any;
  @Input() inputErrorIconStatus: boolean = false;
  @Input() inputErrorIconName!: string;
  @Input() inputErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputDisableState: boolean = false;
  @Output() inputEmitter = new EventEmitter<any>();
 
 
  @ViewChild('inputBox') inputBox!: ElementRef;


  @Input() inputPhoneAutocompleteArray: any;
  @Input() preferredId: boolean = false;
  @Input() inputPhoneAutocompleteLabel: any;
  @Input() inputPhoneAutocompleteAppearance: any = "outline";
  @Input() inputPhoneAutocompletePlaceholderLabel: any;
  @Input() inputPhoneAutoCompleteErrorLabel: any;
  @Input() inputPhoneAutocompletePrefixIcon: any;
  @Input() inputPhoneAutocompleteSuffixIcon: string = "search";
  @Input() inputPhoneAutocompletePlaceholder: any = "+250";
  @Input() inputPhoneAutocompleteFormControlName: any = '';
  @Input() inputPhoneAutocompleteFilterKey: string = 'code';
  // @Input() inputPhoneAutocompleteFormGroup: FormGroup | any;
  @Input() inputPhoneAutocompleteRequiredStatus: boolean = false;
  @Input() inputPhoneAutoCompletereadonly: boolean = false;
  @Input() inputPhoneAutocompletePrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputPhoneAutocompleteSuffixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputPhoneAutocompleteDisableState: boolean = false;
  @Input() inputPhoneAutocompleteDisableIndex: any;
  @Input() requiredIndicator: boolean = false;
  @Input() ondemand: boolean = false;
  @Output() inputPhoneAutoCompleteEmitter = new EventEmitter<any>();
  @Output() inputPhoneAutoCompleteValueEmitter = new EventEmitter<any>();
  filterAutocompleteList: Observable<any[]> | any;

  @ViewChildren('inputPhoneAutoCompleteReference') inputPhoneAutoCompleteReference: QueryList<ElementRef> | any;

  constructor() { }

  ngOnInit(): void {
  }


  // ngAfterViewInit(): void {
  //   if (this.ondemand) {
  //     fromEvent(this.inputBox?.nativeElement, 'input').pipe(
  //       map((e: any) => {
  //         return (e.target as HTMLInputElement).value
  //       }),
  //       //filter(text => text.length > 2),
  //       debounceTime(500),
  //       distinctUntilChanged(),
  //       switchMap(async (x) => {
  //         this.inputPhoneAutoCompleteValueEmitter.emit(x)
  //       })
  //     ).subscribe((response: any) => { });
  //   }

  // }

  ngAfterViewInit(): void {
    fromEvent(this.inputBox?.nativeElement, 'input').pipe(
      map((e: any) => {
        return (e.target as HTMLInputElement).value
      }),
      //filter(text => text.length > 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(async (x) => {
        this.inputEmitter.emit(x);
      })
    ).subscribe(data => { });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['inputPhoneAutocompleteArray'] && !this.ondemand) {
      this.getFilterAutocompleteList();
    }
  }

  /* below function will emit selected options */
  protected SelectedOption(option: any) {
    this.inputPhoneAutoCompleteEmitter.emit(option?.option?.value);
  }

  /* AutoComplete List for Parent component usage (It can be used in other component) */
  getAPIAutocompleteList() {
    this.getFilterAutocompleteList();
  }

  private getFilterAutocompleteList() {
    this.filterAutocompleteList = this.inputPhonenumberFormGroup?.get(this.inputPhoneAutocompleteFormControlName)?.valueChanges.pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.[this.inputPhoneAutocompleteFilterKey]),
      map((name: any) => name ? this._filterArray(name, this.inputPhoneAutocompleteArray, this.inputPhoneAutocompleteFilterKey) : this.inputPhoneAutocompleteArray?.slice())
    );
  }

  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }


  protected displayFn = (data: any) => {
    if (data?.[this.inputPhoneAutocompleteFilterKey]) {
      return data && data?.[this.inputPhoneAutocompleteFilterKey] ? data[this.inputPhoneAutocompleteFilterKey] : '';
    } else if (data?.name) {
      return data && data?.name;
    } else {
      return data;
    }
  }

}
