
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-succeed-modal-prompt',
  templateUrl: './succeed-modal-prompt.component.html',
  styleUrl: './succeed-modal-prompt.component.scss'
})
export class SucceedModalPromptComponent {
  constructor(public _successDialogRef: MatDialogRef<SucceedModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public successDialogData: any) { }

  ngOnInit(): void { }

  dialogClose(params?: any) {
    this._successDialogRef.close(params);
  }
  onCancel(): void {
    // Directly close the dialog without any additional logic
    this._successDialogRef.close();
  }

}
