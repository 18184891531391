<section class="comments-page">
    <div class="comments-card-container d-flex flex-column">
        <div class="comments-body text-center">
            <div class="d-flex flex-column px-4 p-3">
                <label class="register_title mb-3 mt-2">Supplier Registration</label>
                <p class="register_description">Do you want to complete your supplier Pre-qualification Registration?
                </p>
            </div>
            <div class="mb-4 py-1">
                <button class="download-default-style min-width-auto mx-2" mat-stroked-button
                    (click)="dialogClose(false)" [innerHtml]="staticText?.common?.skip_now_button"></button>
                <button mat-raised-button class="create-default-style mx-2"
                    [innerHtml]="staticText?.common?.complete_registration_button"
                    (click)="dialogClose(false)"></button>
            </div>
        </div>
    </div>
</section>