<div class="custom-autocomplete" *ngIf="inputDropdownFormGroup" [formGroup]="inputDropdownFormGroup">
    <mat-label *ngIf="inputLabel" class="color-primary">{{inputLabel}}<sup class="color-red"
            *ngIf="inputRequiredStatus">&ast;</sup></mat-label>
    <div class="d-flex flex-column "  [ngClass]="{'disabled-field': inputDropdownreadonly, 'disabled-field-color': inputDropdownreadonlyColor}">
        <div class="d-flex custom_phonenumber "  [ngClass]="{'disabled-field': inputDropdownreadonly, 'disabled-field-color': inputDropdownreadonlyColor}">

            <!-- dropdown field in the preffix -->
            <mat-form-field class="example-full-width flex_div_date" [appearance]="inputDropdownAppearance"
            [ngClass]="{removeInputBottomPadding: inputDropdownFormGroup?.get(inputDropdownFormControlName)?.touched && inputDropdownFormGroup?.get(inputDropdownFormControlName)?.errors && !requiredIndicator, 'disabled-color': inputDropdownreadonlyColor, 'disabled-field': inputDropdownreadonly}" *ngIf="inputDropdownPreffix">
            <mat-label *ngIf="inputDropdownPlaceholderLabel">{{inputDropdownPlaceholderLabel}}</mat-label>
                <input #inputBox class="country_code" type="text" [placeholder]="inputDropdownPlaceholder" aria-label="Number" matInput
                    [readonly]="true" [formControlName]="inputDropdownFormControlName"
                    [matAutocomplete]="inputDropdownReference" [required]="inputDropdownRequiredStatus" [style.position]="inputDropdownPreffix" [style.color]="inputDropdownreadonlyColor">
                
                <!-- <span matSuffix *ngIf="inputDropdownSuffixIcon" [ngClass]="inputDropdownSuffixIconClass">
                    <i [class]="inputDropdownSuffixIcon"
                    *ngIf="inputDropdownSuffixIcon?.includes('fa');else loadImg;"></i>
                    <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputDropdownSuffixIcon+'.svg'" alt="icon">
                    </ng-template>
                </span> -->
                
                <mat-autocomplete #inputDropdownReference="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="SelectedOption($event)" >
                    <mat-option *ngFor="let option of filterAutocompleteList | async;let optionIndex=index"
                        [value]="option"
                        [disabled]="dropdownReadonly || (inputDropdownDisableIndex ? inputDropdownDisableState && inputDropdownDisableIndex.includes(optionIndex) : inputDropdownDisableState)">
                        {{option?.hasOwnProperty(inputDropdownFilterKey) && option?.[inputDropdownFilterKey] ?
                        option?.[inputDropdownFilterKey] : option?.name ? option?.name : option}}
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>

        <!-- input field -->
        <mat-form-field  class="flex_div_2" [appearance]=""
        [ngClass]="inputClass.includes('removeInputBottomPadding') ? inputClass : {removeInputBottomPadding: inputDropdownFormGroup.get(inputFormControlName)?.touched && inputDropdownFormGroup.get(inputFormControlName)?.errors, 'disabled-color': inputDisableStateColor, 'disabled-field': inputDisableState}">
            <input matInput #inputTemplateReference [id]="inputId" [type]="inputType"
                    [placeholder]="inputPlaceholder" *ngIf="!inputValidationType" [value]="inputValue"
                    [formControlName]="inputFormControlName" [autocomplete]="inputAutocompleteStatus"
                    [minlength]="inputMinLength" [maxlength]="inputMaxLength" [required]="inputRequiredStatus"
                    [readonly]="inputDisableState" [style.color]="inputDisableStateColor" 
                    (keypress)="inputOnKeyPressFunction && inputOnKeyPressFunction($event)" #inputBox>
        </mat-form-field>

        <!-- dropdown field in the suffix -->
        <mat-form-field class="example-full-width" [appearance]="inputDropdownAppearance"
            [ngClass]="{removeInputBottomPadding: inputDropdownFormGroup?.get(inputDropdownFormControlName)?.touched && inputDropdownFormGroup?.get(inputDropdownFormControlName)?.errors && !requiredIndicator,'disabled-color': inputDropdownreadonlyColor, 'disabled-field': inputDropdownreadonly}" *ngIf="inputDropdownSuffix">
            <mat-label *ngIf="inputDropdownPlaceholderLabel">{{inputDropdownPlaceholderLabel}}</mat-label>
                <input #inputBox class="country_code" type="text" [placeholder]="inputDropdownPlaceholder" aria-label="Number" matInput
                    [readonly]="inputDropdownreadonly" [formControlName]="inputDropdownFormControlName"
                    [matAutocomplete]="inputDropdownReference" [required]="inputDropdownRequiredStatus" [style.position]="inputDropdownSuffix " [style.color]="inputDropdownreadonlyColor">
                    <span matSuffix *ngIf="inputDropdownSuffixIcon" [ngClass]="inputDropdownSuffixIconClass">
                        <i [class]="inputDropdownSuffixIcon"
                        *ngIf="inputDropdownSuffixIcon?.includes('fa');else loadImg;"></i>
                        <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputDropdownSuffixIcon+'.svg'" alt="icon">
                        </ng-template>
                    </span>
                <mat-autocomplete #inputDropdownReference="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="SelectedOption($event)">
                    <mat-option *ngFor="let option of filterAutocompleteList | async;let optionIndex=index"
                        [value]="option"
                        [disabled]="inputDropdownDisableIndex ? inputDropdownDisableState && inputDropdownDisableIndex.includes(optionIndex) : inputDropdownDisableState">
                        @if (option?.flag) {
                            <span [innerHTML]="option?.flag" class="fs-18 mx-1"></span>
                        }
                        <span [innerHTML]="option?.hasOwnProperty(inputDropdownFilterKey) && option?.[inputDropdownFilterKey] ?
                        option?.[inputDropdownFilterKey] : option?.name ? option?.name : option"></span>
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>
        <span class= "suffix_text" matSuffix *ngIf="inputSuffixText" [ngClass]="inputSuffixTextClass">
            <span >{{inputSuffixText}}</span>
        </span>
        </div>
        <custom-error [customErrorLabel]="inputErrorLabel" [customErrorIconShowStatus]="inputErrorIconStatus"
            [customErrorIconName]="inputErrorIconName" [customErrorPreFixPath]="inputErrorIconPrefixPath"
            *ngIf="inputDropdownFormGroup.get(inputFormControlName)?.touched && inputDropdownFormGroup.get(inputFormControlName)?.errors">
        </custom-error>
    </div>
</div>
