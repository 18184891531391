import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSizeOptionPipe } from './custom/page-size-option/page-size-option.pipe';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html.pipe';
import { UpdateAttachementFileUrlPipe } from './update-attachement-file-url/update-attachement-file-url.pipe';
import { FileSizeConversionPipe } from './file-size-conversion/file-size-conversion.pipe';
import { TooltipHtmlFilterPipe } from './tooltip-html-filter/tooltip-html-filter.pipe';
import { PatchArrayDataPipe } from './patch-array-data/patch-array-data.pipe';
import { InputWithCommaPipe } from './input-with-comma.pipe';
import { RowspanPipe } from './rowspan.pipe';
import { CommaSeparatorPipe } from './comma-separator.pipe';
import { NumberToWordsPipe } from './number-to-words/number-to-words.pipe';
import { GetFilenamePipe } from './get-filename.pipe';
import { UpdateFileUrlPipe } from './update-file-url.pipe';
import { PercentagePipe } from './percentage/percentage.pipe';
import { PercentageToValuePipe } from './percentage-to-value/percentage-to-value.pipe';
import { WordconvertPipe } from './wordconvert.pipe';


const pipes: any = [
  PageSizeOptionPipe,
  SanitizeHtmlPipe,
  UpdateAttachementFileUrlPipe,
  FileSizeConversionPipe,
  TooltipHtmlFilterPipe,
  PatchArrayDataPipe,
  InputWithCommaPipe,
  RowspanPipe,CommaSeparatorPipe,
  NumberToWordsPipe,
  GetFilenamePipe,
  UpdateFileUrlPipe,
  PercentagePipe,
  PercentageToValuePipe,
  WordconvertPipe
]

@NgModule({
  declarations: [...pipes],
  imports: [
    CommonModule
  ],
  exports: [...pipes]
})
export class PipesModule { }
