<div class="warning-modal-card p-0">
    <div class="warning-modal-custom-background ">
        <div>
            <img [src]="warningDialogData?.icon ?'./assets/images/shared/modal/'+ warningDialogData?.icon +'.svg' : './assets/images/shared/modal/alert.svg'"
            alt="success_tick" >
        </div>
        <h2 class=""
        [innerHtml]="warningDialogData?.title ? warningDialogData?.title :'Are you sure?'">
    </h2>
    </div>
    <div class="warning-modal-container">
        <div class="d-flex flex-column px-4 p-4">
            <div class="warning-modal-body text-center">
                <p [innerHtml]="warningDialogData?.paragraph ? warningDialogData?.paragraph:''" class=" my-2">
                </p>
            </div>
        </div>
        <mat-divider></mat-divider>
       
        <div class="warning-modal-actions d-flex flex-row justify-content-between cursor-pointer px-4 py-3 fs-16">
            <div class="warning-modal-close-button px-2">
                <button class="modal-cancel-default-style cancel-default-style" mat-stroked-button (click)="dialogClose(false)"
                    [innerHtml]="warningDialogData?.close_button ? warningDialogData?.close_button:'Cancel'"></button>
            </div>
            <div class="warning-modal-event-button px-2">
                <button mat-raised-button class="modal-submit-default-style error_btn_login "
                    [innerHtml]="warningDialogData?.close_button ? warningDialogData?.main_event_button:'Yes'"
                    (click)="dialogClose(true)"></button>
            </div>
        </div>
    </div>
</div>