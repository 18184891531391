import { AutoCompleteJiraComponent } from './auto-complete-jira/auto-complete-jira.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { CustomAutocompleteComponent } from './custom-autocomplete/custom-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { CustomTextAreaComponent } from './custom-text-area/custom-text-area.component';
import { CustomCheckboxComponent } from './custom-checkbox/custom-checkbox.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { CustomChipsComponent } from './custom-chips/custom-chips.component';
import { CustomErrorComponent } from './custom-error/custom-error.component';
import { CustomRadioComponent } from './custom-radio/custom-radio.component';
import { CustomFileUploadComponent } from './custom-file-upload/custom-file-upload.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CustomMultiSelectComponent } from './custom-multiselect/custom-multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CustomInputTelnumberComponent } from './custom-input-telnumber/custom-input-telnumber.component';
import { CustomMultiselectJiraComponent } from './custom-multiselect-jira/custom-multiselect-jira.component';
import { CustomRangeDatePickerComponent } from './custom-range-date-picker/custom-range-date-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomInputDropdownComponent } from './custom-input-dropdown/custom-input-dropdown.component';
import { CustomFileAttachmentComponent } from './custom-file-attachment/custom-file-attachment.component';
import { CustomMultiSelectGroupComponent } from './custom-multiselect-group/custom-multiselect-group.component';
import { CustomMultiFileuploadComponent } from './custom-multi-fileupload/custom-multi-fileupload.component';



@NgModule({
  declarations: [
    CustomMultiselectJiraComponent,
    AutoCompleteJiraComponent,
    CustomInputComponent,
    CustomAutocompleteComponent,
    CustomTextAreaComponent,
    CustomCheckboxComponent,
    CustomDatePickerComponent,
    CustomSelectComponent,
    CustomMultiSelectComponent,
    CustomChipsComponent,
    CustomErrorComponent,
    CustomRadioComponent,
    CustomFileUploadComponent,
    CustomInputTelnumberComponent,
    CustomRangeDatePickerComponent,
    CustomInputDropdownComponent,
    CustomFileAttachmentComponent,
    CustomMultiSelectGroupComponent,
    CustomMultiFileuploadComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDaterangepickerMd
  ],
  exports: [
    CustomInputComponent,
    CustomAutocompleteComponent,
    CustomTextAreaComponent,
    CustomCheckboxComponent,
    CustomDatePickerComponent,
    CustomSelectComponent,
    CustomMultiSelectComponent,
    CustomChipsComponent,
    CustomRadioComponent,
    CustomFileUploadComponent,
    CustomFileAttachmentComponent,
    CustomInputTelnumberComponent,
    CustomRangeDatePickerComponent,
    CustomInputDropdownComponent,
    CustomMultiSelectGroupComponent,
    CustomMultiFileuploadComponent,
    CustomMultiselectJiraComponent,
    AutoCompleteJiraComponent

  ]
})
export class CustomFieldsModule { }
