import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { MaterialModule } from './material/material.module';
import { MatDividerModule } from '@angular/material/divider';

/* Shared Modules (Modules will be used in other Modules) */

const sharedModules: any[] = [
  FormsModule,
  PipesModule,
  ComponentsModule,
  ReactiveFormsModule,
  MaterialModule,
  MatDividerModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...sharedModules],
  exports: [...sharedModules],
})
export class SharedModule {}
