import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
@Component({
  selector: 'app-register-modal-prompt',
  templateUrl: './register-modal-prompt.component.html',
  styleUrls: ['./register-modal-prompt.component.scss']
})
export class RegisterModalPromptComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  constructor(public _registerDialogRef: MatDialogRef<RegisterModalPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public commentsDialogData: any) { }

  ngOnInit(): void {
  }

  dialogClose(params?: any) {
    this._registerDialogRef.close(params);
  }

}
