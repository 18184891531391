<div class="custom-input">
    <div class="d-flex flex-column" *ngIf="inputFileAttachmentShow">
        <mat-label *ngIf="inputFileLabel ? inputFileLabel : ''" class="color-primary mb-1">{{inputFileLabel ?
            inputFileLabel : ''}}<sup class="color-red" *ngIf="inputFileRequiredStatus">&ast;</sup>
        </mat-label>

        <ng-container *ngIf="inputFileFormGroup && inputFileFormControlName  && !inputFile;else attachedfileShow"
            [formGroup]="inputFileFormGroup">
            <input type="file" [formControlName]="inputFileFormControlName" [name]="inputFileId" [id]="inputFileId"
                [multiple]="inputFileAllowMultiple" [accept]="inputFileAcceptExtenstions" #inputFilePickeReference
                hidden (change)="inputFileOnChange($event)">

            <div mat-button class=" file_border_btn" [class]="inputFileUploadLabelClass"
                (click)="inputFilePickeReference.click()">{{inputFileUploadLabel}}<span class="choose_btn_list"
                    [innerHtml]="'Browse'"> </span></div>
            <small class="text-muted flex flex-wrap file_info" *ngIf="!disableFileUpload"> <i class="fa fa-info-circle"> </i>
                <span>
                Supported : <span class="text-uppercase" [innerHTML]="getFileFormats"></span><span>;</span>
                </span>
                <span class="">Max Size:{{maxFileSize}}MB</span></small>
            <!-- below will show selected File name -->
            <div class=" file-upload-bg  d-flex flex-row justify-content-start align-items-center py-2 cursor-pointer"
                *ngIf="selectedFile && inputFileNameShow">
                <img *ngIf="(selectedFile[0]?.name)?.includes('.xls') || (selectedFile[0]?.name)?.includes('.xlsx') || (selectedFile[0]?.name)?.includes('.csv')"
                    class="cursor-pointer" src="/assets/images/common/icons/excel.svg" alt="excel">
                <img *ngIf="(selectedFile[0]?.name)?.includes('.doc') || (selectedFile[0]?.name)?.includes('.docx')"
                    class="cursor-pointer" src="/assets/images/common/icons/word_doc.svg" alt="Doc">
                <img *ngIf="(selectedFile[0]?.name)?.includes('.png') || (selectedFile[0]?.name)?.includes('.jpg') || (selectedFile[0]?.name)?.includes('.svg')"
                    class="cursor-pointer" src="/assets/images/common/icons/image.svg" alt="svg">
                <img *ngIf="(selectedFile[0]?.name)?.includes('.pdf')" class="cursor-pointer"
                    src="/assets/images/common/icons/pdf.svg" alt="pdf">
                <p class="mb-0 mx-2 custom-word-ellipse" [innerHTML]="selectedFile[0]?.name">
                </p>
            </div>
            <!---newly added--->
            <ng-container *ngIf="customDownloadDelete && !fileSizeExceeded">
                <div class="d-flex flex-row align-items-center justify-content-between"
                    [ngClass]="{ 'file_upload': selectedFile }">
                    <ng-container *ngIf="selectedFile">
                        <ng-container
                            *ngTemplateOutlet="customFileOption;context:{ $implicit: selectedFile, name:selectedFile?.[0]?.name,download:true }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="fileName">
                        <a [href]="assignFileLink | updateAttachementFileUrl" [target]="inputFileLinkTarget">
                            <ng-container
                                *ngTemplateOutlet="customFileOption;context:{ $implicit: true, name: fileName,download:false}">
                            </ng-container>
                        </a>
                    </ng-container>
                    <!-- <div class="px-1 my-1  cursor-pointer" (click)="inputDeleteFile()" *ngIf="selectedFile || fileName">
                        <img src="/assets/images/common/icons/delete.svg" alt="delete">
                    </div> -->
                    <span class="close_icn_file" (click)="inputDeleteFile()" *ngIf="selectedFile || fileName">

                        <img src="/assets/images/common/icons/close_black.svg" alt="icon">
                    </span>
                </div>

            </ng-container>
            <!-- below will show selected File size  -->
            <!-- <small *ngIf="selectedFile?.length === 1 && inputFileSizeShow">
                {{ selectedFile[0]?.name | fileSizeConversion}}
            </small> -->
            <!-- below will show File size warning Msg -->
            <!-- <small class="text-muted" *ngIf="inputFileSizeWarningMsg" [innerHTML]="inputFileSizeWarningMsg"></small> -->
        </ng-container>



        <!-- below will show attached file -->
        <ng-template #attachedfileShow>
            <ng-container *ngIf="inputFileShowFileDownloadIcon;else renderDeleteContent">
                <div class="d-flex flex-row">
                    <a [href]="inputFile | updateAttachementFileUrl" [target]="inputFileLinkTarget">
                        <div
                            class="d-flex flex-row align-items-center cursor-pointer attachment-hover-download delete-icon-default-block">
                            <!-- <img class="px-1" src="/assets/images/common/icons/excel.svg" alt="excel"> -->
                            <!-- below will show selected File name -->
                            <div class=" file-upload-bg  d-flex flex-row justify-content-start align-items-center py-2 cursor-pointer"
                                *ngIf="inputFile?.split('/').pop()">
                                <img *ngIf="(inputFile?.split('/').pop())?.includes('.xls') || (inputFile?.split('/').pop())?.includes('.xlsx') || (inputFile?.split('/').pop())?.includes('.csv')"
                                    class="cursor-pointer" src="/assets/images/common/icons/excel.svg" alt="excel">
                                <img *ngIf="(inputFile?.split('/').pop())?.includes('.doc') || (inputFile?.split('/').pop())?.includes('.docx')"
                                    class="cursor-pointer" src="/assets/images/common/icons/word_doc.svg" alt="Doc">
                                <img *ngIf="(inputFile?.split('/').pop())?.includes('.png') || (inputFile?.split('/').pop())?.includes('.jpg') || (inputFile?.split('/').pop())?.includes('.svg')"
                                    class="cursor-pointer" src="/assets/images/common/icons/image.svg" alt="svg">
                                <img *ngIf="(inputFile?.split('/').pop())?.includes('.pdf')" class="cursor-pointer"
                                    src="/assets/images/common/icons/pdf.svg" alt="pdf">
                                <p class="mb-0 mx-2 custom-word-ellipse" [innerHTML]="inputFile?.split('/').pop()">
                                </p>
                            </div>
                            <!-- <p class="px-1 color-Dark-Spring-Green mb-0 text-decoration-underline custom-word-ellipse">
                                {{inputFile?.split('/').pop()}}</p> -->
                            <div class="p-1" *ngIf="inputFileShowFileDownloadIcon">
                                <img class="download-icon" src="/assets/images/common/icons/download-bg.svg"
                                    alt="delete">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-container>

            <ng-template #renderDeleteContent>
                <div
                    class="d-flex flex-row align-items-center cursor-pointer attachment-hover-download delete-icon-default-block">
                    <!-- <img class="px-1" src="/assets/images/common/icons/excel.svg" alt="excel"> -->
                    <div class=" file-upload-bg  d-flex flex-row justify-content-start align-items-center py-2 cursor-pointer"
                        *ngIf="inputFile?.split('/').pop()">
                        <img *ngIf="(inputFile?.split('/').pop())?.includes('.xls') || (inputFile?.split('/').pop())?.includes('.xlsx') || (inputFile?.split('/').pop())?.includes('.csv')"
                            class="cursor-pointer" src="/assets/images/common/icons/excel.svg" alt="excel">
                        <img *ngIf="(inputFile?.split('/').pop())?.includes('.doc') || (inputFile?.split('/').pop())?.includes('.docx')"
                            class="cursor-pointer" src="/assets/images/common/icons/word_doc.svg" alt="Doc">
                        <img *ngIf="(inputFile?.split('/').pop())?.includes('.png') || (inputFile?.split('/').pop())?.includes('.jpg') || (inputFile?.split('/').pop())?.includes('.svg')"
                            class="cursor-pointer" src="/assets/images/common/icons/image.svg" alt="svg">
                        <img *ngIf="(inputFile?.split('/').pop())?.includes('.pdf')" class="cursor-pointer"
                            src="/assets/images/common/icons/pdf.svg" alt="pdf">
                        <p class="mb-0 mx-2 custom-word-ellipse" [innerHTML]="inputFile?.split('/').pop()">
                        </p>
                    </div>
                    <!-- <p class="px-1 color-Dark-Spring-Green mb-0 text-decoration-underline custom-word-ellipse">
                        {{inputFile?.split('/').pop()}}</p> -->
                    <div class="p-1 my-1 ms-1 delete-icon-default-style" (click)="inputDeleteFile()"
                        *ngIf="inputFileShowFileDeleteIcon">
                        <img src="/assets/images/common/icons/delete.svg" alt="delete">
                    </div>
                </div>
            </ng-template>
        </ng-template>

        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputFileErrorLabel" [customErrorIconShowStatus]="inputFileErrorIconStatus"
            [customErrorIconName]="inputFileErrorIconName" [customErrorPreFixPath]="inputFileErrorIconPrefixPath"
            *ngIf="inputFileFormGroup.get(inputFileFormControlName)?.touched && inputFileFormGroup.get(inputFileFormControlName)?.errors">
        </custom-error>
    </div>
</div>

<ng-template #customFileOption let-data let-name="name" let-download="download">
    <ng-container *ngIf="name && data">
        <div class="d-flex flex-row align-items-center file-upload-bg p-2 cursor-pointer"
            (click)="download ? downloadFile(data):false" [matTooltip]="name" *ngIf="!fileSizeExceeded">
            <img *ngIf="name?.includes('.xls') || name?.includes('.xlsx') || name?.includes('.csv')"
                class="cursor-pointer" src="/assets/images/common/icons/excel.svg" alt="excel">
            <img *ngIf="name?.includes('.doc') || name?.includes('.docx')" class="cursor-pointer"
                src="/assets/images/common/icons/word_doc.svg" alt="Doc">
            <img *ngIf="name?.includes('.png') || name?.includes('.jpg') || name?.includes('.svg')"
                class="cursor-pointer" src="/assets/images/common/icons/image.svg" alt="svg">
            <img *ngIf="name?.includes('.pdf')" class="cursor-pointer" src="/assets/images/common/icons/pdf.svg"
                alt="pdf">
            <p class="mb-0 mx-2 custom-word-ellipse" (click)="openInNewTab(data)">{{name}}
            </p>
        </div>
    </ng-container>
</ng-template>