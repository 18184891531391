import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { uploadAction } from '../../interface/custom-table/custom-table-actions';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {

  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<any[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  //below variable holds page label
  PageTitleLabel: any;
  breadCrumbLength: any;
  action_items: any;
  text_action: any;
  doc_counts: any;
  navigation: any;
  uploadAction: uploadAction | undefined;
  showAction = new Subject<boolean>();
  showNavigation = new Subject<boolean>();
  previousUrl: string = '';
  enableBreadCrumb: boolean = true;

  constructor(private router: Router) { this.getBreadCrumb(); }

  private getBreadCrumb() {
    this.router.events.pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // Construct the breadcrumb hierarchy
      if (this.previousUrl !== event.url) {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: any[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        this.breadCrumbLength = breadcrumbs?.length;
        this._breadcrumbs$.next(breadcrumbs);
        this.action_items = null;
      }
      this.previousUrl = event.url;
    });
  }

  public setCustomBreadCrumb(breadcrumbs: any) {
    this.breadCrumbLength = breadcrumbs?.length;
    this._breadcrumbs$.next(breadcrumbs);
  }
  public setCustomBreadCrumbAction(actions: any) {

    this.action_items = actions;
    if (actions?.permission?.length) {
      this.showAction.next(true)
    } else {
      this.showAction.next(actions?.actionBtn?.permission?.length ? true : false)
    }
  }
  public setCustomBreadCrumbTextAction(actions: any) {
    this.text_action = actions;
    this.showAction.next(true)
  }

  public setUploadAction(actions?: uploadAction) {
    this.uploadAction = actions;
  }
  public breadCrumbView(status: boolean) {
    this.enableBreadCrumb = status;
  }

  public setNavigation(navgiationObj: any = {}) {
    this.navigation = navgiationObj;
    this.showNavigation.next(true);
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot | any, parentUrl: string[], breadcrumbs: any[]) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map((url: any) => url.path));

      // Add an element for the current route part
      const breadcrumb = {
        label: this.getLabel(route.data),
        url: '/' + routeUrl.join('/')
      };

      if (route.data.breadcrumb && route?.routeConfig?.data?.breadcrumb) {

        if (breadcrumb?.label.includes('|')) {
          let splittedValue = breadcrumb?.label.split("|");
          splittedValue.forEach((splitValue: any) => {
            breadcrumbs.push({ label: splitValue, url: breadcrumb?.url });
          });
        } else {
          breadcrumbs.push(breadcrumb);
        }
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    /* data label for respective page bread crumb title below will split nd call first index */
    this.PageTitleLabel = data?.['page_title'] ? data?.['page_title'] : '';
    this.doc_counts = data?.['documentsDetails'] ? data?.['documentsDetails'] : '';
    // this.action_items = data?.['button'] ? data?.['button'] : '';
    this.text_action = data?.['text_action'] ? data?.['text_action'] : '';
    this.navigation = data?.['navigation'] ? data?.['navigation'] : '';
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data['breadcrumb'] === 'function' ? data['breadcrumb'](data) : data['breadcrumb'];
  }


  resolve(route?: ActivatedRouteSnapshot | any) {
    return route.params;
  }


}
