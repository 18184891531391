<div class="custom-text-area" *ngIf="inputTextAreaFormGroup" [formGroup]="inputTextAreaFormGroup"
    [ngClass]="inputTextAreaReadOnly ? 'label_inactive':''">
    <div class="d-flex flex-column">
        <!-- Text Area Label -->
        <mat-label *ngIf="inputTextAreaLabel" class="color-primary textarea-label font_8">{{inputTextAreaLabel}}<sup class="color-red"
                *ngIf="inputTextAreaRequiredStatus">&ast;</sup></mat-label>
        <!-- Text Area info Label -->
        <mat-label class="text-muted" *ngIf="inputTextAreaInfoMsg" [innerHtml]="inputTextAreaInfoMsg"></mat-label>
    </div>
    <div class="d-flex flex-column" [ngClass]="{'custom-style':customBackground}">
        <!-- custom text area input -->
        <mat-form-field [class]="inputTextAreaClass " [appearance]="inputTextAreaAppearance"
            [ngClass]="{removeInputBottomPadding: inputTextAreaFormGroup.get(inputTextAreaFormControlName).touched && inputTextAreaFormGroup.get(inputTextAreaFormControlName)?.errors}">
            <!-- custom input field prefix icon -->
            <span matPrefix *ngIf="inputTextAreaPrefixIcon" [ngClass]="inputTextAreaPrefixIconClass">
                <!-- below line will render font awesome Icon -->
                <i [class]="inputTextAreaPrefixIcon" *ngIf="inputTextAreaPrefixIcon?.includes('fa');else loadImg;"></i>
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img
                        [src]="inputTextAreaIconImgBasepath+inputTextAreaPrefixIcon+'.svg'" alt="icon"></ng-template>
            </span>
            <mat-label *ngIf="inputTextAreaPlaceholderLabel">{{inputTextAreaPlaceholderLabel}}</mat-label>
            <textarea [id]="inputTextAreaId" matInput [placeholder]="inputTextAreaPlaceHolder" cdkTextareaAutosize
                [cdkAutosizeMinRows]="inputTextAreaMinRowSize" [cdkAutosizeMaxRows]="inputTextAreaMaxRowSize"
                [formControlName]="inputTextAreaFormControlName" [required]="inputTextAreaRequiredStatus"
                [readonly]="inputTextAreaReadOnly" [minLength]="inputTextAreaMinLength"
                [maxLength]="inputTextAreaMaxLength" tabindex="-1" ></textarea>
            <!-- custom select field suffix icon -->
            <span matSuffix *ngIf="inputTextAreaSuffixIcon" [ngClass]="inputTextAreaSuffixIconClass">
                <!-- below line will render font awesome Icon -->
                <i [class]="inputTextAreaSuffixIcon" *ngIf="inputTextAreaSuffixIcon?.includes('fa');else loadImg;"></i>
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img [src]="inputTextAreaIconImgBasepath+inputTextAreaSuffixIcon+'.svg'" alt="icon">
                </ng-template>
            </span>
        </mat-form-field>
        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputTextAreaErrorLabel"
            *ngIf="inputTextAreaFormGroup.get(inputTextAreaFormControlName).touched && inputTextAreaFormGroup.get(inputTextAreaFormControlName)?.errors">
        </custom-error>
    </div>
</div>