import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageSizeOption',
  pure: true
})
export class PageSizeOptionPipe implements PipeTransform {

  transform(options: any[], arrayLength: number): any {
    let lower = options.filter((filterLowestSize: any) => filterLowestSize?.value <= arrayLength);
    return lower;
  }

}
