import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { ApiMethod, Endpoints } from 'src/app/core/services/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public deSelectDropdown = new BehaviorSubject<any>(false);
  deSelectDropdown$ = this.deSelectDropdown.asObservable();
  public deSelectAutoComplete = new BehaviorSubject<any>(false);
  deSelectAutoComplete$ = this.deSelectAutoComplete.asObservable();
  public deSelectInput = new BehaviorSubject<any>(false);
  deSelectInput$ = this.deSelectInput.asObservable();
  public deselectedRange = new BehaviorSubject<any>(false);
  deselectedRange$ = this.deselectedRange.asObservable();
  public chartRender = new BehaviorSubject<any>(false);
  chartRender$ = this.chartRender.asObservable();
  dashboardSelectedFilter:any;
  bulkAction:any[] = [];
  // public setFilterData = new BehaviorSubject<any>(false);
  // setFilterData$ = this.setFilterData.asObservable();
  mzoOption:any[]=[];
  districtOption:any[] = [];
  countyOption:any[]=[];
  subCountyOption:any[]=[];
  parishOption:any[]=[];
  villageOption:any[] =[];
  userList:any[] = [];

  private tableDeleteKey = new BehaviorSubject<any>(null);
  tableDeleteKeyItem = this.tableDeleteKey.asObservable();

  private tablePaginationStartEnd = new BehaviorSubject<any>({});
  tablePaginationStartEndValue = this.tablePaginationStartEnd.asObservable();

  private sharedData = new BehaviorSubject<any>({});
  sharedData$ = this.sharedData.asObservable();

  private supplierDataKey = new BehaviorSubject<any>({});
  supplierData$ = this.supplierDataKey.asObservable();

  private supplierSubmission = new BehaviorSubject<any>(false);
  supplierSubmissionEvent$ = this.supplierSubmission.asObservable();

  private supplierAdminSubmission = new BehaviorSubject<any>(false);
  supplierAdminSubmissionEvent$ = this.supplierAdminSubmission.asObservable();


  
  constructor(private _lodash:LodashService,private _http:HttpService) { }

  setTableDeleteKey(data: any) {
    this.tableDeleteKey.next(data);
  }

  setTablePaginationStartEndValue(data: any) {
    this.tablePaginationStartEnd.next(data);
  }

  getSharedData() {
    return this.sharedData;
  }

  setSharedData(updatedData: any) {
    this.sharedData.next(updatedData)
  }
  setSupplierData(data: any) {
    this.supplierDataKey.next(data);
  }

  supplierSubmissionMethod(data: any) {
    this.supplierSubmission.next(data);
  }

  supplierAdminSubmissionMethod(data: any) {
    this.supplierAdminSubmission.next(data);
  }

  fetchData(data:any,single?:boolean){
  const id = this._lodash.isArray(data?.currentDate) ? this.getIdStringFromArr(data?.currentDate) : (data?.currentDate?.id || '');
  //  const id = !single ? this.getIdStringFromArr(data?.currentDate):(data?.currentDate?.id || '');
   let endpoint:any
   switch(data?.referenceData?.key){
    case "region":
      if (id) 
        endpoint = Endpoints.GET_DROPDOWN_MZO_LIST+'?region='+id;
      else
        return of([]);
     break;
     case "zone":
      if (id) 
        endpoint = Endpoints.GET_DROPDOWN_DISTRICT_LIST+'?zone='+id;
      else
        return of([]);
     break;
     case "property_district":
      if (id) 
        endpoint = Endpoints.GET_DROPDOWN_COUNTY_LIST+'?district='+id;
      else
        return of([]);
     break;
     case "property_county":
      if (id)
        endpoint = Endpoints.GET_DROPDOWN_SUBCOUNTY_LIST+'?county='+id;
      else
        return of([]);
     break;
     case "property_sub_county":
      if (id)
        endpoint = Endpoints.GET_DROPDOWN_PARISH_LIST+'?sub_county='+id;
      else
        return of([]);
     break;
     case "property_parish":
      if (id)
        endpoint = Endpoints.GET_DROPDOWN_VILLAGE_LIST+'?parish='+id;
      else
        return of([]);
     break;
     case "role":
      if (id)
        endpoint = Endpoints.GET_DROPDOWN_USERS_LIST+'?groups='+id;
      else
        return of([]);
   }
   return this._http.requestCall(endpoint,ApiMethod.GET)

  }
  getIdStringFromArr(inputArr = [], fieldName = 'id') {
    return (this._lodash.mapData(inputArr, fieldName) || []).toString();
  }

}
