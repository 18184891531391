<div class="custom-input" [formGroup]="inputCheckBoxFormGroup" *ngIf="inputCheckBoxFormGroup">
    <mat-label *ngIf="inputCheckBoxLabel && !displayLabelEnd" class="color-primary margin_scp">
        {{inputCheckBoxLabel}} <sup class="color-red" *ngIf="inputCheckBoxRequiredStatus">&ast;</sup>
    </mat-label>
    <div class="d-flex flex-column checkbox-wrapper">
        <!-- custom Checkbox -->
        <mat-checkbox [id]="inputCheckBoxId" #checkbox [class]="inputCheckBoxClass" [color]="inputCheckBoxColor"
            [formControlName]="inputCheckBoxFormControlName" [required]="inputCheckBoxRequiredStatus" [disabled]="inputCheckBoxDisableState"
            (change)="fieldsChange($event)" [checked]="CheckedValue">
            {{inputCheckBoxValue}}
        </mat-checkbox>
        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputCheckBoxLabel+' Required'" [customErrorIconShowStatus]="inputCheckBoxIconStatus"
            [customErrorIconName]="inputCheckBoxErrorIconName" [customErrorPreFixPath]="inputCheckBoxIconPrefixPath"
            *ngIf="inputCheckBoxFormGroup.get(inputCheckBoxFormControlName)?.touched && inputCheckBoxFormGroup.get(inputCheckBoxFormControlName)?.errors">
        </custom-error>
    </div>
    <mat-label *ngIf="inputCheckBoxLabel && displayLabelEnd" class="color-primary margin_scp cursor-pointer" (click)="toggleCheckbox()">
        {{inputCheckBoxLabel}} <sup class="color-red" *ngIf="inputCheckBoxRequiredStatus">&ast;</sup>
    </mat-label>
</div>