import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputWithComma'
})
export class InputWithCommaPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const inputComma = value ? Number(value).toLocaleString() : '';
    return inputComma;
  }

}
