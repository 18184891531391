<div class="custom-input" *ngIf="inputFormGroup" [formGroup]="inputFormGroup">
    <mat-label *ngIf="inputAppearance !== 'outline' && inputLabel" class="color-primary"
        [ngClass]="removePadding ? 'text-smaller' : ''">
        {{ inputLabel }}
        <sup class="color-red" *ngIf="inputRequiredStatus">&ast;</sup>
    </mat-label>




    <div class="d-flex flex-column"
        [ngClass]="{'applyInputLabelSpace': inputPrefixIcon, 'custom-search':customstyle, 'disabled-field-color': inputDisableStateColor, 'disabled-field': inputDisableState,'remove-padding':removePadding}">
        <mat-form-field [appearance]="inputAppearance"
            [ngClass]="inputClass.includes('removeInputBottomPadding') ? inputClass : {removeInputBottomPadding: inputFormGroup.get(inputFormControlName)?.touched && inputFormGroup.get(inputFormControlName)?.errors}">
            <!-- custom input field prefix icon -->
            <span matPrefix *ngIf="inputPrefixIcon" [ngClass]="inputPrefixIconClass">
                <!-- below line will render font awesome Icon -->
                <!-- <i [class]="inputPrefixIcon" *ngIf="inputPrefixIcon?.includes('fa');else loadImg;"></i> -->
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputPrefixIcon+'.svg'"
                        alt="icon"></ng-template>
            </span>

            <span class="prefix_text" matPrefix *ngIf="inputPrefixText" [ngClass]="inputPrefixTextClass">
                <span>{{inputPrefixText}}</span>
            </span>
            <mat-label *ngIf="inputPlaceholderLabel">{{inputPlaceholderLabel}}</mat-label>
            <input [ngClass]="inputType" matInput #inputTemplateReference [id]="inputId"
                [type]="inputCommaSeparator? 'text':inputType" [placeholder]="inputPlaceholder"
                *ngIf="!inputValidationType" [value]="inputValue" [formControlName]="inputFormControlName"
                [autocomplete]="inputAutocompleteStatus" [minlength]="inputMinLength" [maxlength]="inputMaxLength"
                [required]="inputRequiredStatus" [readonly]="inputDisableState" [style.color]="inputDisableStateColor"
                (keypress)="validateKeyPress($event)" #inputBox (change)="keywordSearch($event)"
                (keyup.enter)="keywordSearch($event)" (input)="inputCommaSeparator? onInput($event): false">
            <mat-icon matSuffix *ngIf="icons">search</mat-icon>

            <span matSuffix *ngIf="inputSuffixIcon" [attr.class]="inputSuffixFunction ? 'cursor-pointer':''"
                [ngClass]="inputSuffixIconClass" (click)="inputSuffixFunction && inputSuffixFunction()">
                <!-- below line will render font awesome Icon -->
                <!-- <i [class]="inputSuffixIcon" *ngIf="inputSuffixIcon?.includes('fa');else loadImg;"></i> -->
                <!-- below line will render svg icon (located in images->common->icons) -->
                <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputSuffixIcon+'.svg'"
                        alt="icon"></ng-template>
            </span>
            <span matSuffix *ngIf="inputCloseIcon && inputBox?.nativeElement?.value" class="cursor-pointer"
                (click)="inputBox.nativeElement.value = '';clearSearch()">
                <img [src]="inputIconImgBasepath+inputCloseIcon+'.svg'" alt="icon">
            </span>

            <span class="suffix_text" matSuffix *ngIf="inputSuffixText" [ngClass]="inputSuffixTextClass">
                <span>{{inputSuffixText}}</span>
            </span>

            <ng-container *ngIf="inputFormGroup.get(inputFormControlName)?.value && enableClear">
                <span class="clearIcnSearch" (click)="clearValue()">
                    <!-- <i class="fa fa-close"></i> -->
                    <img src="/assets/images/common/icons/close_black.svg" alt="icon">
                </span>
            </ng-container>

        </mat-form-field>
        <!-- custom error component -->

        <custom-error [customErrorLabel]="inputErrorLabel" [customErrorIconShowStatus]="inputErrorIconStatus"
            [customErrorIconName]="inputErrorIconName" [customErrorPreFixPath]="inputErrorIconPrefixPath"
            *ngIf="inputFormGroup.get(inputFormControlName)?.touched && inputFormGroup.get(inputFormControlName)?.errors">
        </custom-error>
    </div>
</div>