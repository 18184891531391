import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripOutHtml'
})
export class TooltipHtmlFilterPipe implements PipeTransform {

  transform(data: any): any {
    if (data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');
      return doc.body.textContent || ''; // Extract text content, which strips HTML tags
    }
    return data;
  }

}
