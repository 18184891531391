import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-modal-prompt',
  templateUrl: './delete-modal-prompt.component.html',
  styleUrls: ['./delete-modal-prompt.component.scss']
})
export class DeleteModalPromptComponent implements OnInit {

  constructor(public _deleteDialogRef: MatDialogRef<DeleteModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public deleteDialogData: any) { }

  ngOnInit(): void { }
  
  dialogClose(params?: any) {
    this._deleteDialogRef.close(params);
  }
  

}
