import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, fromEvent, map, switchMap, takeUntil } from 'rxjs';
import { InputWithCommaPipe } from 'src/app/shared/pipes/input-with-comma.pipe';
import { DataService } from 'src/app/shared/services/data/data.service';

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [InputWithCommaPipe],
  encapsulation: ViewEncapsulation.None
})
export class CustomInputComponent implements OnInit, AfterViewInit {

  @Input() inputFormGroup: FormGroup | any;
  @Input() inputFormControlName: any;
  @Input() inputClass: any = 'w-100 ';
  @Input() inputId: any;
  @Input() inputType: any = 'text';
  @Input() inputLabel: any;
  @Input() inputPlaceholderLabel: any;
  @Input() icons: any;
  @Input() inputAppearance: any = 'outline';
  @Input() inputAppearanceoutline: any = 'outline';
  @Input() inputPlaceholder: any;
  @Input() inputAutocompleteStatus: any = 'off';
  @Input() inputValue: any;
  @Input() inputPrefixIcon: any;
  @Input() inputSuffixIcon: any;
  @Input() inputPrefixIconClass: any = '';
  @Input() inputSuffixIconClass: any = 'p-15 color-gray fs-14 ml-half-rem';
  @Input() inputSuffixTextClass: any = 'color-gray fs-14 ml-half-rem';
  @Input() inputSuffixFunction: any;
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputErrorLabel: any;
  @Input() inputMinLength: any;
  @Input() inputMaxLength: any;
  @Input() inputRequiredStatus: boolean = false;
  @Input() inputTemplateReference: HTMLElement | any;
  @Input() inputValidationType: any
  @Input() inputOnKeyPressFunction: any;
  @Input() inputErrorIconStatus: boolean = false;
  @Input() inputErrorIconName!: string;
  @Input() inputErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputDisableState: boolean = false;
  @Input() inputDisableStateColor: boolean = false;
  @Output() inputEmitter = new EventEmitter<any>();
  @Output() keywordEmiiter = new EventEmitter<any>();
  @ViewChild('inputBox') inputBox!: ElementRef;
  @Input() inputCloseIcon:any;
  @Input() customstyle : any;
  @Output() clearKeyWordSearch = new EventEmitter<any>();
  @Input() inputSuffixText: any; 
  @Input () inputCommaSeparator: boolean = false;
  @Input()removePadding:boolean = false;
  @Input()enableClear:boolean = false;
  destroyed = new Subject<void>();
  
  constructor(private _dataService:DataService) { }

  ngOnInit(): void {
    this._dataService.deSelectInput$.pipe(takeUntil(this.destroyed)).subscribe((res:any)=>{
      if(res){
        this.inputFormGroup.get(res).setValue(null);
      }
    })
  }
  ngAfterViewInit(): void {
    fromEvent(this.inputBox?.nativeElement, 'input').pipe(
      map((e: any) => {
        return (e.target as HTMLInputElement).value
      }),
      //filter(text => text.length > 2),
      debounceTime(1000),
      // distinctUntilChanged(),
      switchMap(async (x) => {
        this.inputEmitter.emit(x);
      })
    ).subscribe(data => { });
  }

  keywordSearch(e:any){  
   this.keywordEmiiter.emit(e?.target?.value)
  }

  clearSearch(){
    this.inputFormGroup.get(this.inputFormControlName).value = '';
    this.clearKeyWordSearch.emit(true);
  }

  clearValue(){
    this.inputFormGroup.get(this.inputFormControlName).setValue(null);
    this.inputEmitter.emit(null);
    this.clearKeyWordSearch.emit(true);
  }
  
  onInput(value: any) {
    // this.inputValue = value?.target?.value? value?.target?.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Apply formatting

    let inputValue = value?.target?.value;

     // Remove non-numeric characters and leading zeros
     inputValue = inputValue.replace(/[^\d.]+/g, '').replace(/^0+/, '');

     inputValue = inputValue.replace(/\.{2,}/g, "");

     // Format the value with commas for thousands separator
     inputValue = new Intl.NumberFormat().format(inputValue);
    //  inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (inputValue.split('.')?.length < 3) {
      if (inputValue.split('.')?.length == 1) {
        value.target.value = inputValue
      } else {
        // Update the input value
        value.target.value = inputValue?.split('.')?.[0] +'.'+ ((inputValue?.split('.')?.[1]) ? inputValue?.split('.')?.[1]?.substring(0, 2) : '');
      }
      this.inputValue = value.target.value;
    }
  }

  validateKeyPress(event: KeyboardEvent) {
    if (this.inputId) {
      return (event.charCode === 0 || /\d/.test(String.fromCharCode(event.charCode)));
    } else {
      return (this.inputOnKeyPressFunction && this.inputOnKeyPressFunction(event));
    }
  }

}
