<div class="delete-modal-card p-0">
   
    <div class="add-row-card-container d-flex flex-column">
       

        <div class="add-row-header d-flex flex-column justify-content-center">
           

            <div class="container add-row-title">
                <div class="d-flex flex-row justify-content-between align-items-center">
        
                    <label class="fw-600 fs-20 modalTitle" [innerHtml]="deleteDialogData?.title ? deleteDialogData?.title :'Are you sure?'"></label>
                    
                  <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
                    (click)="dialogClose()" />
                </div>
              </div>
            
        </div>
        <p  [innerHtml]="deleteDialogData?.paragraph ? deleteDialogData?.paragraph:''" class=" my-3 delete_para px-4 mx-1">
        </p>
        
        <div class="major-modal-actions cursor-pointer p-3  cursor-pointer px-4 py-3 fs-16">
            <div class="warning-modal-close-button px-1">
                <button class="btn_default_user" mat-stroked-button (click)="dialogClose(false)"
                    [innerHtml]="deleteDialogData?.close_button ? deleteDialogData?.close_button:'Cancel'"></button>
            </div>
            <div class="warning-modal-event-button px-1">
                <button mat-raised-button class="btn_default_user_delete"
                    [innerHtml]="deleteDialogData?.main_event_button ? deleteDialogData?.main_event_button:'Yes, delete'"
                    (click)="dialogClose(true)"></button>
            </div>
        </div>
    </div>
</div>






