import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessModalPromptComponent } from './success-modal-prompt/success-modal-prompt.component';
import { MaterialModule } from '../../material/material.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { WarningModalPromptComponent } from './warning-modal-prompt/warning-modal-prompt.component';
import { CommonModalPromptComponent } from './common-modal-prompt/common-modal-prompt.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DeleteModalPromptComponent } from './delete-modal-prompt/delete-modal-prompt.component';
import { CommentsModalPromptComponent } from './comments-modal-prompt/comments-modal-prompt.component';
import { CustomFieldsModule } from "../mat-elements/custom-fields/custom-fields.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterModalPromptComponent } from './register-modal-prompt/register-modal-prompt.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TagInputModule } from 'ngx-chips';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { SucceedModalPromptComponent } from './succeed-modal-prompt/succeed-modal-prompt.component';


@NgModule({
  declarations: [
    SuccessModalPromptComponent,
    WarningModalPromptComponent,
    CommonModalPromptComponent,
    DeleteModalPromptComponent,
    CommentsModalPromptComponent,
    RegisterModalPromptComponent,
    FilePreviewComponent,
    SucceedModalPromptComponent
  ],
  exports: [
    SuccessModalPromptComponent,
  ],
  imports: [
    FormsModule,
    PipesModule,
    CommonModule,
    MaterialModule,
    CustomFieldsModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    NgxPermissionsModule,
    TagInputModule,
    AngularEditorModule
  ]
})
export class ModalModule { }
