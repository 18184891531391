<div class="common-modal-card p-0">
   
    <!-- modal header -->
    <ng-container *ngIf="!commonDialogData?.customHeader;else renderCustomHeader">
        <div class="common-modal-custom-background d-flex flex-column justify-content-center">

            <div class="container common-title">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <label class="text-dark fw-600 fs-20" ><i *ngIf="commonDialogData?.header.showInfoIcon" class="fa-solid fa-circle-info"></i> {{commonDialogData?.header?.title ?? ''}}</label>
                   
                    <img class="cursor-pointer"
                        [src]="commonDialogData?.header?.icon ? './assets/images/common/icons/'+commonDialogData?.header?.icon+'.svg' : './assets/images/common/icons/close_black.svg'"
                        [alt]="commonDialogData?.header?.icon" (click)="dialogClose(false)">
                </div>
            </div>
            <!-- <div class="container">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <label [innerHtml]="commonDialogData?.header?.title"></label>
                    <img class="cursor-pointer"
                        [src]="commonDialogData?.header?.icon ? './assets/images/common/icons/'+commonDialogData?.header?.icon+'.svg' : './assets/images/common/icons/close.svg'"
                        [alt]="commonDialogData?.header?.icon" (click)="dialogClose(false)">
                </div>
            </div> -->
        </div>
    </ng-container>

    <ng-template #renderCustomHeader>
        <div [innerHtml]="commonDialogData?.customHeader"></div>
    </ng-template>
    <!-- modal header -->

    <div class="common-modal-container">

        <!-- modal body -->
        <div class="d-flex flex-column px-4 p-3">
            <div class="common-modal-body text-center">
                <!-- below will render body with help of inner Html -->
                <ng-container *ngIf="commonDialogData?.body;else renderNgTemplate">
                    <div [innerHtml]="commonDialogData?.body"></div>
                </ng-container>

                <!-- below will render template -->
                <ng-template #renderNgTemplate>
                    <ng-container *ngTemplateOutlet="commonDialogData?.template"></ng-container>
                </ng-template>
            </div>
        </div>

        <!-- modal body -->

        <mat-divider></mat-divider>

        <!-- modal footer -->
        <ng-container *ngIf="!commonDialogData?.customFooter;else renderCustomFooter">
            <div class="common-modal-actions d-flex flex-row justify-content-between cursor-pointer px-4 py-3 fs-16">
                <div class="common-modal-close-button px-2">
                    <button  *ngIf="commonDialogData.hideCancel !== true" class="cancel-default-style" mat-stroked-button (click)="dialogClose(false)"
                        [innerHtml]="commonDialogData?.close_button || staticText?.common?.cancel_button"></button>
                </div>
                <div class="common-modal-event-button px-2">
                    <button mat-raised-button class="modal-create-default-style create_common_btn"
                        [innerHtml]="commonDialogData?.create_button || staticText?.common?.create_button"
                        (click)="dialogClose(true)"></button>
                </div>
            </div>
        </ng-container>

        <ng-template #renderCustomFooter>
            <div [innerHtml]="commonDialogData?.footer"></div>
        </ng-template>

        <!-- modal footer -->
    </div>
</div>