import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit {

  @Input() customErrorLabel: any;
  @Input() customErrorPreFixPath: any = '/assets/images/common/icons/';
  @Input() customErrorIconName: any;
  @Input() customErrorIconShowStatus: boolean = false;
  @Input() customErrorClass: any = 'pt-0';

  constructor() { }

  ngOnInit(): void {
  }

}
