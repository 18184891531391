import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, takeUntil } from 'rxjs';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { DataService } from 'src/app/shared/services/data/data.service';

interface OptionGroup {
  name: string;
  options: Option[];
}

interface Option {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'custom-multiselect-group',
  templateUrl: './custom-multiselect-group.component.html',
  styleUrls: ['./custom-multiselect-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomMultiSelectGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedItems: any = [];
  dropdownSettings: IDropdownSettings = {};
  @Input() inputSelectFormGroup: FormGroup | any = new FormGroup({});
  @Input() preferredId: boolean = false;
  @Input() inputSelectFormContolName: any;
  @Input() inputSelectLabel: any;
  @Input() inputSelectPlaceholderLabel: any = 'All';
  @Input() inputSelectArray: any[] = [];
  @Input() inputSelectPlaceholder: any;
  @Input() inputSelectErrorLabel: any;
  @Input() customDropdownSettings = {};
  @Input() inputSelectAppreance: any = 'outline';
  @Input() inputSelectDisableState: boolean = false;
  @Input() inputSelectRequiredStatus: boolean = false;
  @Input() inputSelectOptionKey: string = 'display_name';
  @Input() inputSelectOptionValue: string = 'id';
  @Input() inputSelectMutipleOptionStatus: boolean = false;
  @Input() inputSelectPrefixIcon: any;
  @Input() inputSelectSuffixIcon: string = "dropdown_arow_down";
  @Input() inputSelectPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputSelectSuffixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputSelectGroupBy: boolean = false;
  @Input() inputSourceData: string = '';
  @Output() inputEmitSelected = new EventEmitter();
  @ViewChild('mselect') mselect!: ElementRef;
  isDropdownChanged: boolean = false;
  isDropClosed: boolean = true;
  toggleParent: boolean = false;
  destroyed = new Subject<void>();
  @Input() removePadding: boolean = false;
  @Output() removeSelected = new EventEmitter();

  optionGroups: OptionGroup[] = [
    {
      name: 'Group 1',
      options: [
        { value: 'option1', viewValue: 'Option 1' },
        { value: 'option2', viewValue: 'Option 2' }
      ]
    },
    {
      name: 'Group 2',
      options: [
        { value: 'option3', viewValue: 'Option 3' },
        { value: 'option4', viewValue: 'Option 4' }
      ]
    }
  ];

  selectedValues: string[] = [];


  constructor(
    private _lodash: LodashService, private _dataService: DataService) {
    this.selectedItems = [
    ];
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: this.inputSourceData || 'id',
      textField: 'display_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 1,
      maxHeight: 100,
      allowSearchFilter: true
    };

    this._dataService.deSelectDropdown$.pipe(takeUntil(this.destroyed)).subscribe((res: any) => {
      if (res) {
        this.inputSelectFormGroup.get(res).setValue([]);
      }
    })
  }

  updateSelectedValues(event: any) {
    this.selectedValues = event.value;
  }

  getDisplayText(): string {
    if (this.selectedValues.length <= 2) {
      return this.selectedValues.map(value => this.getOptionViewValue(value)).join(', ');
    } else {
      const firstTwo = this.selectedValues.slice(0, 2).map(value => this.getOptionViewValue(value));
      return `${firstTwo.join(', ')}, ${this.selectedValues.length - 2}+ more...`;
    }
  }

  getOptionViewValue(value: string): string {
    for (let group of this.optionGroups) {
      for (let option of group.options) {
        if (option.value === value) {
          return option.viewValue;
        }
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngAfterViewInit(): void {
  }

  emitData(data: any) {
    this.inputEmitSelected.emit(data);
  }

  selectedValueEmit(data: any) {
    if (this.isDropdownChanged) {
      this.emitData(this.inputSelectFormGroup.get(this.inputSelectFormContolName).value);
      this.isDropdownChanged = false;
    }
  }

  onDropDownClose() {
    this.selectedValueEmit(this.selectedItems);

  }

  setDropdownChangeStatus() {
    this.isDropdownChanged = true;
  }

  onItemSelect(item: any) {
    this.setDropdownChangeStatus();
  }

  onSelectAll(items: any) {
    this.setDropdownChangeStatus();
  }

  onClickDropdown(e: any) {
    console.log('on onClickDropdown', e);
    if (!this.mselect.nativeElement.querySelector('.dropdown-list').hasAttribute('hidden') &&
      this.mselect.nativeElement.querySelector('.dropdown-list .item1 .filter-textbox input')) {
      this.mselect.nativeElement.querySelector('.dropdown-list .item1 .filter-textbox input').focus();
    }
  }

  onDeSelectAll() {
    this.setDropdownChangeStatus();
  }

  onDeSelect(item: any) {
    this.mselect.nativeElement.querySelector('.dropdown-list').hasAttribute('hidden');
    this.emitData(this.inputSelectFormGroup.get(this.inputSelectFormContolName).value);
  }
}
