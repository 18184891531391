 <div class="custom-select" [formGroup]="inputSelectFormGroup">
  <mat-label *ngIf="inputSelectLabel" class="color-primary" [ngClass]="removePadding?'text-small':''">{{inputSelectLabel}}<sup class="color-red"
      *ngIf="inputSelectRequiredStatus">&ast;</sup></mat-label>
  <div class="d-flex flex-column" #mselect>

      <!-- <mat-form-field [appearance]="inputSelectAppreance"
        [ngClass]="{removeInputBottomPadding: inputSelectFormGroup?.get(inputSelectFormContolName)?.touched && inputSelectFormGroup?.get(inputSelectFormContolName)?.errors}">
        <span matPrefix *ngIf="inputSelectPrefixIcon" [ngClass]="inputSelectPrefixIconClass">
          <i [class]="inputSelectPrefixIcon" *ngIf="inputSelectPrefixIcon?.includes('fa');else loadImg;"></i>
          <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputSelectPrefixIcon+'.svg'" alt="icon">
          </ng-template>
        </span> -->
        <!-- <mat-label *ngIf="inputSelectPlaceholderLabel">{{inputSelectPlaceholderLabel}}</mat-label> -->
        <!-- <mat-select [formControlName]="inputSelectFormContolName" multiple [placeholder]="inputSelectPlaceholder"
          [required]="inputSelectRequiredStatus" [disabled]="inputSelectDisableState"
          (selectionChange)="selectedValueEmit($event)">
          <mat-option *ngFor="let select of inputSelectArray" [value]="select.id">
            {{ select?.name }}
          </mat-option>
        </mat-select> -->
        <ng-multiselect-dropdown [ngClass]="removePadding?'remove-padding':''"
        [disabled]="inputSelectDisableState"
        (click)="onClickDropdown($event)"
        [formControlName]="inputSelectFormContolName"
        [placeholder]="inputSelectPlaceholderLabel"
        [settings]="dropdownSettings"
        [data]="inputSelectArray"
        (onDropDownClose)="onDropDownClose()"
        (onSelect)="onItemSelect($event)"
        (onDeSelect)="onDeSelect($event)"
        (onDeSelectAll)="onDeSelectAll()"
        (onSelectAll)="onSelectAll($event)"
        [required]="inputSelectRequiredStatus">
        </ng-multiselect-dropdown
        >
        <!-- [(ngModel)]="selectedItems" -->
        <!-- <span matSuffix *ngIf="inputSelectSuffixIcon" [ngClass]="inputSelectSuffixIconClass">
          <i [class]="inputSelectSuffixIcon" *ngIf="inputSelectSuffixIcon?.includes('fa');else loadImg;"></i>
          <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputSelectSuffixIcon+'.svg'" alt="icon">
          </ng-template>
        </span> -->
      <!-- </mat-form-field> -->
      <custom-error [customErrorLabel]="inputSelectErrorLabel"
        *ngIf="inputSelectFormGroup?.get(inputSelectFormContolName)?.touched && inputSelectFormGroup?.get(inputSelectFormContolName)?.errors">
      </custom-error>
  </div>
</div>

