import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { colorCodes } from 'src/app/core/services/utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'custom-file-attachment',
  templateUrl: './custom-file-attachment.component.html',
  styleUrls: ['./custom-file-attachment.component.scss']
})
export class CustomFileAttachmentComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() inputFile: any;
  @Input() inputFileFormGroup!: FormGroup;
  @Input() inputFileFormControlName: any = '';
  @Input() inputFileId: any;
  @Input() inputFileLabel: string = '';
  @Input() inputFileUploadLabel: string = '';
  @Input() inputFileUploadLabelClass: string = '';
  @Input() inputFileRequiredStatus: boolean = false;
  @Input() inputFileAcceptExtenstions: any = ".jpg, .png, .doc, .xls, .xlxs .ppt, .pdf";
  @Input() inputFileSizeWarningMsg: any = this.staticText?.prequalification?.create?.add?.file_size
  @Input() inputFileErrorLabel: any;
  @Input() inputFileErrorIconStatus: boolean = false;
  @Input() inputFileErrorIconName!: string;
  @Input() inputFileAllowMultiple: boolean = false;
  @Input() inputFileLabelShow: boolean = true;
  @Input() inputFileNameShow: boolean = false;
  @Input() inputFileSizeShow: boolean = false;
  @Input() inputFileShowFileDownloadIcon: boolean = false;
  @Input() inputFileShowFileDeleteIcon: boolean = false;
  @Input() inputFileAttachmentShow: boolean = true;
  @Input() inputFileErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputFileLinkTarget: string = "_blank";
  @Output() inputFileOnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputFileOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('inputFilePickeReference') inputFilePickeReference: QueryList<ElementRef> | any;
  @Input() fileName: any;
  @Input() assignFileLink: any;
  @Input() customDownloadDelete: boolean = false;
  selectedFile: any;
  @Input() maxFileSize: any = 5; // in MB
  fileSizeExceeded: boolean = false;

  constructor(private _snackBar: SnackbarService) {
  }

  ngOnInit(): void {
    this.inputFileUploadLabel = this.inputFileUploadLabel || this.staticText?.common?.choose_file_button;
    this.selectedFile =  this.inputFileFormGroup.get(this.inputFileFormControlName)?.value ?? "";
    this.selectedFile = (this.selectedFile && !Array.isArray(this.selectedFile)) ? [this.selectedFile] : this.selectedFile;
    this.selectedFile = (this.selectedFile && Array.isArray(this.selectedFile) && this.selectedFile.length) ?  this.selectedFile : null;
  }

  bytesToMB(bytes:any) {
    return (bytes / (1024 * 1024));
  }

  mbToByte(mb:any){
    return mb * 1024 * 1024;
  }

  get getFileFormats():any {
    return this.inputFileAcceptExtenstions?.replaceAll('.', '');
  }

  isAllowedExtension(fileName:any, allowedExtensions:any) {
    // Get the file extension
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check if the file extension is in the allowed extensions list
    return allowedExtensions.includes(`.${fileExtension}`);
  }

  inputFileOnChange(data: any) {
    // this.selectedFile = data.target.files;
    // if (this.selectedFile?.[0].size > 5 * 1024 * 1024) {
    //   this.fileSizeExceeded = true;
    //   this._snackBar.loadSnackBar(this.staticText?.common?.file_error_msg, colorCodes.ERROR);
    //   data.target.files = '';
    //   return;
    // }
    const formatArray = this.inputFileAcceptExtenstions?.split(", ")?.map((format:any) => format.trim());
    if(!this.isAllowedExtension(data.target.files?.[0]?.name, formatArray)){
      this._snackBar.loadSnackBar(`File support format: ${this.getFileFormats}`, colorCodes.ERROR);
    }else if (data.target.files?.[0].size > this.mbToByte(this.maxFileSize)) {
      this._snackBar.loadSnackBar(`File size should not exceed ${this.maxFileSize} MB`, colorCodes.ERROR);
    }
    else {
      this.selectedFile = data.target.files;
      this.inputFileOnSelect.emit(data.target.files);
      this.fileSizeExceeded = false;
    }
  }

  downloadFile(data: any) {
    if (data?.[0]?.size) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data?.[0]);
      link.download = data?.[0]?.name;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }

  inputDeleteFile(file?: any) {
    this.inputFilePickeReference?.toArray()?.forEach((element: any) => {
      element.nativeElement.value = "";
    });
    this.selectedFile = null;
    this.fileName = null;
    this.inputFileOnDelete.emit(true);
    this.inputFileFormGroup.get(this.inputFileFormControlName)?.setValue(null);
  }

  openInNewTab(fileData: any): void {
    if (fileData[0]?.attachment) {
      // Open the file's attachment URL in a new tab
      window.open(fileData[0].attachment, '_blank');
    } else {
      console.error('Attachment URL is not available.');
    }
  }
}