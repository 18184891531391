<div class="custom-table-container d-flex flex-column">
  <ng-container [ngSwitch]="tableViewType">
    <!-- below case will render list view -->
    <ng-container *ngSwitchCase="'list'">
      <div class="custom-list">
        <!-- <perfect-scrollbar [config]="customTableFilterScrollconfigListView"> -->
        <ng-container *ngIf="rowFinalData?.length > 0">
          <div class="custom-list-card row p-3" *ngFor="
              let row of rowFinalData
                | paginate
                  : {
                      id: tableId,
                      itemsPerPage: this.pageSize,
                      currentPage: this.currentPage,
                      totalItems: this.tableTotalRecords
                    };
              index as rowIndex;
              odd as rowOdd;
              even as rowEven
            ">
            <div class="col-11 px-2 custom-list-card-context d-flex flex-column">
              <ng-container *ngFor="let listProperty of colData; let columnIndex = index"
                [ngSwitch]="listProperty[1]?.list_options?.text_type">
                <div class="d-flex flex-row" *ngSwitchCase="'title'">
                  <span *ngIf="listProperty[1]?.list_options?.serial?.status"
                    [class]="listProperty[1]?.list_options?.serial?.class"
                    [innerHtml]="row['serial_no'] + '. &nbsp;' | sanitizeHtml"></span>
                  <h5 [class]="listProperty[1]?.list_options?.class" [innerHtml]="row[listProperty[0]] | sanitizeHtml">
                  </h5>
                </div>
                <div class="d-flex flex-row" *ngSwitchCase="'paragraph'">
                  <span *ngIf="
                      listProperty[1]?.list_options?.serial?.status &&
                      listProperty[1] === 'serial_no'
                    " [class]="listProperty[1]?.list_options?.serial?.class"
                    [innerHtml]="row['serial_no'] + '. &nbsp;' | sanitizeHtml"></span>
                  <p [class]="listProperty[1]?.list_options?.class" [innerHtml]="row[listProperty[0]] | sanitizeHtml">
                  </p>
                </div>
                <div class="d-flex flex-row" *ngSwitchDefault>
                  <span *ngIf="
                      listProperty[1]?.list_options?.serial?.status &&
                      listProperty[1] === 'serial_no'
                    " [class]="listProperty[1]?.list_options?.serial?.class"
                    [innerHtml]="row['serial_no'] + '. &nbsp;' | sanitizeHtml"></span>
                  <span [class]="listProperty[1]?.list_options?.class"
                    [innerHtml]="row[listProperty[0]] | sanitizeHtml"></span>
                </div>
              </ng-container>
            </div>
            <div class="col-1 px-2 table-actions d-flex justify-content-start">
              <div class="row">
                <ng-container [ngSwitch]="row[actionBlockKey]">
                  <ng-container *ngSwitchCase="actionBlockKey ? actionBlockValue : false">
                    <span>&dash;</span>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngFor="let action of row?.route">
                      <ng-container>
                        <!-- *ngxPermissionsOnly="action?.mode ==='edit' ? tableCustomActionPermissions?.edit : tableCustomActionPermissions?.delete" -->
                        <div class="col pe-0" *ngIf="action?.status && action?.mode !== 'view'">
                          <img class="cursor-pointer" [alt]="action?.mode" [src]="
                              '/assets/images/common/icons/' +
                              action?.mode +
                              '.svg'
                            " (click)="tableAction(row, action, '')" />
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="rowFinalData?.length == 0">
          <table class="w-100">
            <caption></caption>
            <th></th>
            <tbody>
              <tr class="text-center">
                <td colspan="12">
                  <p class="no-records" [innerHTML]="staticText?.common?.no_record"></p>
                  1111
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <!-- </perfect-scrollbar> -->
      </div>
    </ng-container>

    <!-- below case will render table view -->
    <ng-container *ngSwitchDefault>
      <!-- <perfect-scrollbar [config]="customTableFilterScrollconfig"> -->
      <!-- custom Table -->
      <div class="custom-table {{ reduceFontsize ? 'reduce-padding' : '' }}" [ngClass]="tableClass">
        <table class="table mb-0" [ngClass]="{
            'sticky-table-last':
              lastColFreezed && tableRowData?.length && rowFinalData?.length,
            'sticky-table-last-two':
              lastTwoColFreezed && tableRowData?.length && rowFinalData?.length
          }">
          <caption></caption>
          <thead [ngClass]="
              expansionalTable ? 'expansioanl-table-head' : 'custom-table-head'
            " class="">
            <tr class="custom-table-head-row" #headerRow>
              <!-- table-head-right-space-adjust -->
              <td *ngIf="!haveMultiSelect" class="custom-table-cell-head"></td>
              <td *ngIf="haveMultiSelect" class="custom-table-checkbox custom-table-cell-head {{
                  haveMultiSelect ? 'border' : ''
                }} {{
                  !tableCustomActionSelectAll.multiSelect
                    ? 'disable-checkbox-opacity'
                    : ''
                }} {{
                  !rowFinalData?.length
                    ? 'disable-checkbox-opacity pointer-event-none'
                    : ''
                }}">
                <mat-checkbox [(ngModel)]="tableCustomActionSelectAll.model" [color]="'primary'"
                  (change)="toggleHeaderRowCheckbox()" [disabled]="!tableCustomActionSelectAll.multiSelect">
                </mat-checkbox>
              </td>
              <!-- table-head-right-space-adjust -->
              <ng-container *ngFor="
                  let columnProperty of colData;
                  let columnIndex = index;
                  last as isLast;
                  first as first
                ">
                <ng-container *ngIf="
                    columnProperty[1]?.column_options?.hasOwnProperty(
                      'column_status'
                    )
                      ? columnProperty[1]?.column_options?.column_status
                      : true
                  ">
                  <th class="custom-table-cell-head  {{
                      expansionalTable
                        ? 'border-0'
                        : !expansionalTable && !isLast && !first
                        ? 'border'
                        : ''
                    }}" [ngClass]="{
                      'reduce-ft-size': reduceFontsize,
                      'reduce-padding': reduceFontsize
                    }" [style.height]="
                      columnProperty[1]?.column_options?.column_height
                    " [style.min-height.px]="
                      columnProperty[1]?.column_options?.column_minHeight
                    " [style.max-height.px]="
                      columnProperty[1]?.column_options?.column_maxHeight
                    " [style.width]="
                      columnProperty[1]?.column_options?.column_width
                    " [style.min-width.px]="
                      columnProperty[1]?.column_options?.column_minWidth
                    " [style.max-width.px]="
                      columnProperty[1]?.column_options?.column_maxWidth
                    " *ngIf="!columnProperty[1]?.column_options?.column_sortable" data-bs-toggle="tooltip"
                    container="body" data-bs-placement="bottom" [title]="columnProperty[1]?.field | stripOutHtml"
                    [innerHtml]="columnProperty[1]?.field"></th>
                </ng-container>
                <ng-container *ngIf="
                    columnProperty[1]?.column_options?.hasOwnProperty(
                      'column_status'
                    )
                      ? columnProperty[1]?.column_options?.column_status
                      : true
                  ">
                  <th class="custom-table-cell-head {{
                      expansionalTable
                        ? 'border-0'
                        : !expansionalTable && !isLast && !first
                        ? 'border'
                        : ''
                    }}" [ngClass]="{ 'reduce-ft-size': reduceFontsize }" [style.height]="
                      columnProperty[1]?.column_options?.column_height
                    " [style.min-height.px]="
                      columnProperty[1]?.column_options?.column_minHeight
                    " [style.max-height.px]="
                      columnProperty[1]?.column_options?.column_maxHeight
                    " [style.width.px]="
                      columnProperty[1]?.column_options?.column_width
                    " [style.min-width.px]="
                      columnProperty[1]?.column_options?.column_minWidth
                    " [style.max-width.px]="
                      columnProperty[1]?.column_options?.column_maxWidth
                    " *ngIf="columnProperty[1]?.column_options?.column_sortable" class="cursor-pointer"
                    data-bs-toggle="tooltip" container="body" data-bs-placement="bottom"
                    [title]="columnProperty[1]?.field | stripOutHtml" (click)="
                      columnProperty[1]?.column_options?.column_sortable &&
                        sortColumn(columnIndex, columnProperty)
                    ">
                    <div class="d-flex flex-row align-items-center {{
                        reduceFontsize ? 'th-lable-align' : ''
                      }}" [ngClass]="{ sorting: sortingColumns[columnIndex] }">
                      <span [innerHtml]="columnProperty[1]?.field"></span>
                      <span class="ms-2" *ngIf="
                          columnProperty[1]?.column_options?.column_sortable ||
                          false
                        ">
                        <img src="/assets/images/common/icons/sort-bg-balck.svg" alt="sort"
                          style="width: 5px; margin-bottom: 0.3rem" /></span>
                    </div>
                  </th>
                </ng-container>
              </ng-container>
              <!-- table-head-left-space-adjust -->
              <!-- <th class="custom-table-cell-head"></th> -->
              <!-- table-head-left-space-adjust -->
            </tr>
            <ng-container *ngIf="localColumnSearch">
              <ng-container *ngTemplateOutlet="columSearchTemplate; context: {}"></ng-container>
            </ng-container>
          </thead>
          <tbody class="custom-table-body" *ngIf="rowFinalData?.length > 0">
            <tr *ngFor="
                let row of rowFinalData
                  | paginate
                    : {
                        id: tableId,
                        itemsPerPage: this.pageSize,
                        currentPage: this.currentPage,
                        totalItems: this.tableTotalRecords
                      };
                index as rowIndex;
                odd as rowOdd;
                even as rowEven
              " class="custom-table-body-row" #dataRow>
              <!-- table-row-right-space-adjust -->
              <td *ngIf="!haveMultiSelect" class="custom-table-body-cell"></td>
              <td *ngIf="haveMultiSelect" class="custom-table-checkbox custom-table-body-cell {{
                  haveMultiSelect ? 'border' : ''
                }} {{
                  row?.row_checkbox_disable ? 'disable-checkbox-opacity' : ''
                }}">
                <mat-checkbox [(ngModel)]="row.select" [color]="'primary'" (change)="rowSelect(row)"
                  [disabled]="row?.row_checkbox_disable">
                </mat-checkbox>
              </td>
              <!-- table-row-right-space-adjust -->
              <ng-container *ngFor="
                  let columnProperty of colData;
                  index as columnIndex;
                  last as isLast;
                  first as first
                ">
                <ng-container *ngIf="
                    columnProperty[1]?.column_options?.hasOwnProperty(
                      'column_status'
                    )
                      ? columnProperty[1]?.column_options?.column_status
                      : true
                  ">
                  <!-- below will render other cell -->
                  <ng-container *ngIf="
                      !isLast || columnProperty[0] !== 'ACTION';
                      else renderActionContent
                    ">
                    <ng-container *ngIf="tableCellClick; else noEventContainer">
                      <ng-container *ngFor="let clickEvent of tableCellClick">
                        <!-- below will render cell with action -->
                        <td class="custom-table-body-cell" *ngIf="
                            clickEvent?.elementKeyName === columnProperty[0];
                            else noEventContainer
                          " data-bs-toggle="tooltip" data-bs-placement="bottom" [title]="
                            row[columnProperty[0] + columnTitleFieldName] ||
                              row[columnProperty[0]] | stripOutHtml
                          ">
                          <span (click)="clickEvent?.onClick(row)"
                            [innerHtml]="row[columnProperty[0]] | sanitizeHtml"></span>
                        </td>
                      </ng-container>
                    </ng-container>
                    <ng-template #noEventContainer>
                      <ng-container *ngIf="
                          columnProperty[1].view_Action !== true;
                          else renderView
                        ">
                        <!-- {{columnIndex}} -->
                        <td class="custom-table-body-cell {{
                            expansionalTable
                              ? ''
                              : !expansionalTable && columnIndex > 1
                              ? 'border'
                              : ''
                          }}" data-bs-toggle="tooltip" [ngClass]="{
                            'reduce-ft-size': reduceFontsize,
                            currecycolumn:
                              columnProperty[1].column_options?.text_align_end
                          }" container="body" data-bs-placement="bottom" [title]="
                            row[columnProperty[0] + columnTitleFieldName] ||
                              row[columnProperty[0]] | stripOutHtml
                          ">
                          <!-- <ng-container *ngIf="columnProperty[1]?.dropdown_config">
                                                            <select class="custom-grid-dropdown"
                                                                [class]="columnProperty[1]?.dropdown_config?.className"
                                                                [ngClass]="[row[columnProperty[0]] ? 'active': 'inactive',row?.disabled_is_active? 'disable-active-dropdown' : '']"
                                                                (change)="onDropdownChange(columnProperty[1]?.dropdown_config?.events?.change, $event, columnProperty[0], row)"
                                                                [title] = "row[columnProperty[0]] === true? 'Active' : 'Inactive'">
                                                                <option
                                                                    *ngFor="let o of columnProperty[1]?.dropdown_config?.options"
                                                                    [attr.selected]="row[columnProperty[0]] == o.value ? true : null"
                                                                    value="{{o.value}}">{{o.key}}</option>
                                                            </select>
                                                        </ng-container> -->

                          <ng-container *ngIf="columnProperty[1]?.dropdown_config">
                            <span class="custom-grid-span" [class]="
                                columnProperty[1]?.dropdown_config?.className
                              " [ngClass]="[
                                row[columnProperty[0]] ? 'active' : 'inactive',
                                row?.disabled_is_active
                                  ? 'disable-active-dropdown'
                                  : ''
                              ]" [title]="
                                row[columnProperty[0]] === true
                                  ? 'Active'
                                  : 'Inactive'
                              ">
                              {{
                              row[columnProperty[0]] === true
                              ? "Active"
                              : "Inactive"
                              }}
                            </span>
                          </ng-container>
                          @if (!columnProperty[1]?.dropdown_config) { @if
                          (columnProperty[1]?.column_options?.currency) {
                          <span [innerHTML]="
                              row[columnProperty[0]] | commaSeparator
                            "></span>
                          } @if (!columnProperty[1]?.column_options?.currency) {
                          @if (columnProperty[1]?.column_options?.show_popover)
                          {
                          <span [ngbPopover]="popoverTemplate" triggers="mouseenter:mouseleave" placement="end"
                            autoClose="true" [popoverContext]="{
                              template:
                                (row[
                                  columnProperty[1]?.column_options
                                    ?.popover_template_name
                                ] | sanitizeHtml)
                            }" [innerHTML]="row[columnProperty[0]] | sanitizeHtml"></span>
                          } @else if (columnProperty[0] == 'status') {
                          <span>
                            <span [ngStyle]="{
                                '--background-color':
                                  row[columnProperty[0]]?.color_code,
                                '--border-radius': '0',
                                '--text-color':
                                  row[columnProperty[0]]?.text_color_code
                              }" class="document_status {{row[columnProperty[0]]?.class}}" data-toggle="tooltip"
                              data-placement="top" title="{{row[columnProperty[0]]?.label}}">
                              {{ row[columnProperty[0]]?.label }}
                            </span>
                          </span>
                          } @else {
                          <span [innerHTML]="row[columnProperty[0]] | sanitizeHtml"></span>
                          } } }
                        </td>
                      </ng-container>
                      <!-- below will render cell with view function -->
                      <ng-template #renderView>
                        <!-- below will render when it has no route path -->
                        <ng-container *ngIf="
                            _common.gotPermission(
                              tableCustomActionPermissions?.view,
                              tableCustomActionPermissionsStatus
                            ) && row?.route?.length;
                            then hasRoutePath;
                            else noPermission
                          ">
                        </ng-container>
                        <!--    {{row?.route?.length}} -->
                        <ng-template #noPermission>
                          <td class="custom-table-body-cell" data-bs-toggle="tooltip" container="body"
                            data-bs-placement="bottom" [title]="row[columnProperty[0]] | stripOutHtml">
                            <span [innerHtml]="
                                row[columnProperty[0]] | sanitizeHtml
                              "></span>111
                          </td>
                        </ng-template>
                        <!-- below will render cell with view function coz it has route path -->
                        <ng-template #hasRoutePath>
                          <ng-container *ngFor="let viewAction of row?.route">
                            <ng-container>
                              <td class="custom-table-body-cell {{
                                  !expansionalTable ? 'border' : ''
                                }}" data-bs-toggle="tooltip" container="body" data-bs-placement="bottom"
                                [title]="row[columnProperty[0]] | stripOutHtml" *ngIf="
                                  viewAction?.status &&
                                  viewAction?.mode === 'view'
                                ">
                               
                                <span class="{{
                                    columnProperty[1]?.column_options?.currency

                                      ? 'currecy_view'
                                      : 'default_view'
                                  }}" [innerHtml]="
                                    columnProperty[1]?.column_options?.currency
                                      ? (row[columnProperty[0]]
                                        | commaSeparator)
                                      : (row[columnProperty[0]] | sanitizeHtml)
                                  " (click)="
                                    tableAction(
                                      row,
                                      viewAction,
                                      columnProperty[0]
                                    )
                                  ">
                                </span>
                              </td>
                            </ng-container>
                          </ng-container>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </ng-container>

                  <!-- below will render last action cell -->
                  <ng-template #renderActionContent>
                    <td [style.height.px]="dataRow.offsetHeight" class="custom-table-body-cell">
                      <div class="table-actions d-flex justify-content-start">
                        <div class="row">
                          <ng-container [ngSwitch]="row[actionBlockKey]">
                            <ng-container *ngSwitchCase="
                                actionBlockKey ? actionBlockValue : false
                              ">
                              <span>&dash;</span>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              <ng-container *ngFor="let action of row?.route">
                                <ng-container
                                  *ngIf="(action?.mode === 'edit' || action?.icon === 'view_action' ? _common.gotPermission(tableCustomActionPermissions?.[action?.icon === 'view_action' ? 'view' : action?.mode], tableCustomActionPermissionsStatus) :_common.gotPermission(tableCustomActionPermissions?.delete, tableCustomActionPermissionsStatus))">
                                  <ng-container *ngIf="action?.mode !== 'view'">
                                    <div class="col pe-0" [class.hidden]="action?.hidden" *ngIf="
                                        columnProperty[1]?.action_options?.hasOwnProperty(
                                          'edit_status'
                                        )
                                          ? columnProperty[1]?.action_options
                                              ?.edit_status
                                          : columnProperty[1]?.action_options?.hasOwnProperty(
                                              'delete_status'
                                            )
                                          ? columnProperty[1]?.action_options
                                              ?.delete_status
                                          : action?.status
                                      " [title]="
                                        action?.tooltip
                                          ? action?.tooltip
                                          : staticText?.common?.delete
                                      ">
                                      <img class="cursor-pointer" [class.disabled]="action?.disable"
                                        [alt]="action?.mode" [src]="
                                          action?.icon
                                            ? '/assets/images/common/icons/' +
                                              action?.icon +
                                              '.svg'
                                            : action?.disable
                                            ? '/assets/images/common/icons/' +
                                              action?.mode +
                                              '_disable' +
                                              '.svg'
                                            : '/assets/images/common/icons/' +
                                              action?.mode +
                                              '.svg'
                                        " (click)="
                                          tableAction(
                                            row,
                                            action,
                                            columnProperty[0]
                                          )
                                        " />
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngFor="let customAction of tableCustomActionButton">
                            <div class="col pe-0" *ngIf="customAction?.status" [title]="
                                customAction?.mode === 'edit'
                                  ? staticText?.common?.edit
                                  : staticText?.common?.view
                              ">
                              <ng-container
                                *ngIf="_common.gotPermission(tableCustomActionPermissions?.[customAction?.mode], tableCustomActionPermissionsStatus)">
                                <img class="cursor-pointer" [alt]="customAction?.mode" src="/assets/images/common/icons/{{
                                    customAction?.mode
                                  }}.svg" (click)="tableCustomAction(row, customAction)" />
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </td>
                  </ng-template>
                </ng-container>
              </ng-container>
              <!-- table-row-left-space-adjust -->
              <!-- <td class="custom-table-body-cell"></td> -->
              <!-- table-row-left-space-adjust -->
            </tr>
          </tbody>

          <tbody>
            <tr class="text-center">
              <td colspan="12" *ngIf="showLoader">
                <img src="/assets/images/common/icons/table_spinner.gif" class="spinner-style" alt="FirstPage" />
              </td>

              <td colspan="12" *ngIf="!showLoader && rowFinalData?.length == 0">
                <p class="no-records" [innerHTML]="staticText?.common?.no_record"></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- </perfect-scrollbar> -->
    </ng-container>
  </ng-container>
  <!-- <mat-divider *ngIf="tableShowPagination"></mat-divider> -->
  <!-- custom pagination block -->
  <div class="custom-Pagination-block d-flex flex-wrap flex-row align-items-center" *ngIf="tableShowPagination">
    <!-- custom page size dropdown -->
    <div class="singlepage-total-cnt" *ngIf="tableRowData?.length" [ngClass]="{
        'd-none':
          tableTotalRecords >=
          (_tableSerivce.pageSize ||
            staticText?.common?.custom_table_properties
              ?.custom_table_pagination_block?.custom_table_page_size?.endRow)
      }">
      <span [innerHtml]="
          staticText?.common?.custom_table_properties
            ?.custom_table_pagination_block?.custom_table_page_size
            ?.custom_table_page_size_label_displaying
        "></span>
      <span class="ms-1">{{ tableTotalRecords || 0 }} </span>
      <span [innerHtml]="
          staticText?.common?.custom_table_properties
            ?.custom_table_pagination_block?.custom_table_page_size
            ?.custom_table_page_size_label_totalRecords
        "></span>
    </div>
    <div class="" *ngIf="(selectOptions | pageSizeOption : tableTotalRecords)?.length">
      <div class="d-flex flex-row align-items-center">
        <label class="me-2 showing_details" [innerHtml]="
            staticText?.common?.custom_table_properties
              ?.custom_table_pagination_block?.custom_table_page_size
              ?.custom_table_page_size_label_displaying
          "></label>
        <select class="custom-pagination-size-picker" (change)="selectedValue($event)" [(ngModel)]="selectedpageSize"
          [disabled]="
            tableTotalRecords >=
            (_tableSerivce.pageSize ||
              staticText?.common?.custom_table_properties
                ?.custom_table_pagination_block?.custom_table_page_size?.endRow)
              ? false
              : true
          ">
          <option *ngFor="
              let pageSize of selectOptions | pageSizeOption : tableTotalRecords
            " [value]="pageSize?.value">
            {{ pageSize?.name }}
          </option>
        </select>
        <label class="showing_details ms-1">
          <span [innerHtml]="
              staticText?.common?.custom_table_properties
                ?.custom_table_pagination_block?.custom_table_page_size
                ?.custom_table_page_size_label_of
            "></span>
          <span class="ms-1">{{ tableTotalRecords || 0 }}</span>
          <span class="ms-1" [innerHtml]="
              staticText?.common?.custom_table_properties
                ?.custom_table_pagination_block?.custom_table_page_size
                ?.custom_table_page_size_label_totalRecords
            "></span>
        </label>
      </div>
    </div>
    <!----------- custom pagination ---------->
    <div class="p-1" [ngClass]="{ invisible: !(tableTotalRecords > 0) }">
      <!-- standard pagination -->
      <ng-container *ngIf="!tablePaginationCustom">
        <pagination-controls [id]="tableId" (pageChange)="pageChanged($event)"
          (pageBoundsCorrection)="pageChanged($event)" [autoHide]="tablePaginationHide"
          [maxSize]="tablePaginationMaxSize" [responsive]="tablePaginationResponsive"
          [previousLabel]="tablePaginationPreviousLabel" [nextLabel]="tablePaginationNextLabel"
          screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page">
        </pagination-controls>
      </ng-container>
      <!-- custom pagination -->
      <ng-container *ngIf="tablePaginationCustom">
        <pagination-template [id]="tableId" #customTablePagination="paginationApi" (pageChange)="pageChanged($event)"
          [maxSize]="tablePaginationMaxSize">
          <div class="custom-table-pagination py-3">
            <div class="row row-cols-3">
              <div class="col px-2">
                <div
                  class="custom-table-pagination-previous-first d-flex flex-row justify-content-end align-items-center">
                  <div class="px-2 firstPage d-flex justify-content-center align-items-center cursor-pointer"
                    [class.disabled]="!shouldEnableFirstPage" (click)="customTablePagination.setCurrent(1)">
                    <img src="../../../../assets/images/common/icons_usp/white_previous_double.svg" alt="FirstPage" />
                  </div>
                  <div class="px-2 mx-1 previousPage d-flex justify-content-center align-items-center cursor-pointer"
                    [class.disabled]="customTablePagination.isFirstPage()" (click)="customTablePagination.previous()">
                    <img src="/assets/images/common/icons/previous.svg" alt="PreviousPage" />
                  </div>
                </div>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <div class="page-align d-flex flex-row justify-content-around fs-12">
                  <!-- <p class="px-2 mb-0 d-flex justify-content-center align-items-center color-dark"
                                        [innerHtml]="staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_pagination?.custom_table_pagination_label_page">
                                    </p> -->
                                    <div class="me-2">Page</div>
                  <div class="active-page px-2 d-flex justify-content-center align-items-center">
                    {{ customTablePagination.getCurrent() }}
                  </div>
                  <div class="total-page px-2 d-flex justify-content-center align-items-center color-dark">
                    <span class="pe-1" [innerHtml]="
                        staticText?.common?.custom_table_properties
                          ?.custom_table_pagination_block
                          ?.custom_table_pagination
                          ?.custom_table_pagination_label_of
                      "></span><span>{{ getLastPage() }}</span>
                  </div>
                </div>
              </div>
              <div class="col px-2">
                <div class="custom-table-pagination-next-last d-flex flex-row justify-content-start align-items-center">
                  <div class="px-2 mx-1 nextPage d-flex justify-content-center align-items-center cursor-pointer"
                    [class.disabled]="customTablePagination.isLastPage()" (click)="customTablePagination.next()">
                    <img src="/assets/images/common/icons/next.svg" alt="NextPage" />
                  </div>
                  <div class="px-2 lastPage d-flex justify-content-center align-items-center cursor-pointer"
                    [class.disabled]="!shouldEnableLastPage" (click)="customTablePagination.setCurrent(lastPage)">
                    <img class="rotate-img" src="../../../../assets/images/common/icons_usp/white_previous_double.svg" alt="LastPage" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </pagination-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #popoverTemplate let-template="template">
  <span class="sadfsfadsd" [innerHTML]="template"></span>
</ng-template>

<ng-template #columSearchTemplate>
  <tr class="custom-search column-search bg-white">
    <td *ngIf="!haveMultiSelect" class="custom-table-cell-head {{ !expansionalTable ? '' : '' }}"></td>
    <td *ngIf="haveMultiSelect" class="custom-table-cell-head {{ haveMultiSelect ? 'border' : '' }}"></td>
    <ng-container *ngFor="
        let columnProperty of colData;
        index as columnIndex;
        last as isLast;
        first as isFirst;
        let first = first
      ">
      <td class="custom-table-body-cell column-search-td {{
          expansionalTable ? 'border-0' : ''
        }}" data-bs-toggle="tooltip" [ngClass]="{
          'invisible-border':
            !columnProperty[1]?.column_options?.column_search_config
              ?.search_enabled
        }" [style.height]="columnProperty[1]?.column_options?.column_height" [style.min-height.px]="
          columnProperty[1]?.column_options?.column_minHeight
        " [style.max-height.px]="
          columnProperty[1]?.column_options?.column_maxHeight
        " [style.width]="columnProperty[1]?.column_options?.column_width" [style.min-width.px]="
          columnProperty[1]?.column_options?.column_minWidth
        " [style.max-width.px]="
          columnProperty[1]?.column_options?.column_maxWidth
        " container="body" data-bs-placement="bottom" style="background-color: white !important">
        <ng-container *ngIf="columnProperty?.[0] !== 'ACTION'">
          <select (change)="onChangeColSearchSelect($event, columnProperty)" *ngIf="
              columnProperty[1]?.column_options?.column_search_config
                ?.search_enabled &&
              columnProperty[1]?.column_options?.column_search_config
                ?.search_type == 'select'
            ">
            <option [selected]="
                !columnProperty[1]?.column_options?.column_search_config
                  ?.column_search_val ||
                columnProperty[1]?.column_options?.column_search_config
                  ?.column_search_val == searchSelectColDefaultVal
              " value="default-val">
              Select
            </option>
            <option *ngFor="
                let option of getColSearchOptionList(
                  columnProperty[1]?.column_options?.column_search_config
                    ?.search_colname
                )
              " [value]="option?.id" [selected]="
                option?.id ==
                columnProperty[1]?.column_options?.column_search_config
                  ?.column_search_val
              ">
              {{ option?.name }}
            </option>
          </select>
          <input *ngIf="
              !columnProperty[1]?.column_options?.column_search_config ||
              !columnProperty[1]?.column_options?.column_search_config
                ?.search_enabled ||
              columnProperty[1]?.column_options?.column_search_config
                ?.search_type == 'text'
            " type="text" [attr.col-name]="columnProperty[0]" [ngClass]="{
              invisible:
                !columnProperty[1]?.column_options?.column_search_config
                  ?.search_enabled
            }" (keyup)="onSearchColumn($event, columnIndex, columnProperty)" [attr.value]="
              columnProperty[1]?.column_options?.column_search_config
                ?.column_search_val
            " [placeholder]="  columnProperty[1]?.field | stripOutHtml" />
          <div class="start_end_date" *ngIf="
              columnProperty[1]?.column_options?.column_search_config
                ?.search_type === 'date-range'
            ">
            <mat-form-field>
              <mat-date-range-input [formGroup]="ranges[columnIndex]" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="
                    onSearchColumn($event, columnIndex, columnProperty)
                  " />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="
                    onSearchColumn($event, columnIndex, columnProperty)
                  " />
              </mat-date-range-input>

              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button mat-icon-button *ngIf="
                ranges[columnIndex].get('start')?.value ||
                ranges[columnIndex].get('end')?.value
              " matSuffix (click)="clearDateRange($event, columnIndex, columnProperty)">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="columnProperty?.[0] === 'ACTION'">
          <div class="actionCol"></div>
        </ng-container>
      </td>
    </ng-container>

    <!-- <td class="custom-table-cell-head"></td> -->
  </tr>
</ng-template>