import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-modal-prompt',
  templateUrl: './warning-modal-prompt.component.html',
  styleUrls: ['./warning-modal-prompt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarningModalPromptComponent implements OnInit {

  constructor(public _warningDialogRef: MatDialogRef<WarningModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public warningDialogData: any) { }

  ngOnInit(): void {}

  dialogClose(params?: any) {
    this._warningDialogRef.close(params);
  }
}
