import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, fromEvent, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'custom-input-dropdown',
  templateUrl: './custom-input-dropdown.component.html',
  styleUrls: ['./custom-input-dropdown.component.scss']
})
export class CustomInputDropdownComponent implements OnInit {

  
  @Input() inputDropdownFormGroup: FormGroup | any;

  @Input() inputFormControlName: any;
  @Input() inputClass: any = 'w-100';
  @Input() inputId: any;
  @Input() inputType: any = 'text';
  @Input() inputLabel: any;
  @Input() inputPlaceholderLabel: any;
  @Input() icons: any;
  @Input() inputAppearance: any = 'outline';
  @Input() inputPlaceholder: any;
  @Input() inputAutocompleteStatus: any = 'off';
  @Input() inputValue: any;
  @Input() inputPrefixIcon: any;
  @Input() inputSuffixIcon: any;
  @Input() inputPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputSuffixIconClass: any = 'p-15 color-gray fs-14 ml-half-rem';
  @Input() inputSuffixFunction: any;
  @Input() inputErrorLabel: any;
  @Input() inputMinLength: any;
  @Input() inputMaxLength: any;
  @Input() inputRequiredStatus: boolean = false;
  @Input() inputTemplateReference: HTMLElement | any;
  @Input() inputValidationType: any
  @Input() inputOnKeyPressFunction: any;
  @Input() inputErrorIconStatus: boolean = false;
  @Input() inputErrorIconName!: string;
  @Input() inputErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputDisableState: boolean = false;
  @Input() inputDisableStateColor: boolean = false;
  @Output() inputEmitter = new EventEmitter<any>();
 
  @Input() inputSuffixText: any;
  @Input() inputSuffixTextClass: any = 'color-gray fs-14 ml-half-rem';
 
  @ViewChild('inputBox') inputBox!: ElementRef;


  @Input() inputDropdownArray: any = [];
  @Input() preferredId: boolean = false;
  @Input() inputDropdownLabel: any;
  @Input() inputDropdownAppearance: any = "outline";
  @Input() inputDropdownPlaceholderLabel: any;
  @Input() inputDropdownErrorLabel: any;
  @Input() inputDropdownPrefixIcon: any;
  @Input() inputDropdownSuffixIcon: string = "dropdown_arow_down";
  @Input() inputDropdownPlaceholder: any;
  @Input() inputDropdownFormControlName: any = '';
  @Input() inputDropdownFilterKey: string = 'code';
  @Input() inputDropdownRequiredStatus: boolean = false;
  @Input() inputDropdownreadonly: boolean = false;
  @Input() dropdownReadonly: boolean = false;
  @Input() inputDropdownPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputDropdownSuffixIconClass: any = 'pe-2  color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputDropdownDisableState: boolean = false;
  @Input() inputDropdownreadonlyColor: boolean = false;
  @Input() inputDropdownDisableIndex: any;
  @Input() requiredIndicator: boolean = false;
  @Input() ondemand: boolean = false;
  @Output() inputDropdownEmitter = new EventEmitter<any>();
  @Output() inputDropdownValueEmitter = new EventEmitter<any>();
  filterAutocompleteList: Observable<any[]> | any;
  @Input() inputDropdownPreffix: boolean = false;
  @Input() inputDropdownSuffix: boolean = false;

  @ViewChildren('inputDropdownReference') inputDropdownReference: QueryList<ElementRef> | any;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    fromEvent(this.inputBox?.nativeElement, 'input').pipe(
      map((e: any) => {
        return (e.target as HTMLInputElement).value
      }),
      //filter(text => text.length > 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(async (x) => {
        this.inputEmitter.emit(x);
      })
    ).subscribe(data => { });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['inputDropdownArray'] && !this.ondemand) {
      this.getFilterAutocompleteList();
    }
  }

  /* below function will emit selected options */
  protected SelectedOption(option: any) {
    this.inputDropdownEmitter.emit(option?.option?.value);
  }

  /* AutoComplete List for Parent component usage (It can be used in other component) */
  getAPIAutocompleteList() {
    this.getFilterAutocompleteList();
  }

  private getFilterAutocompleteList() {
    this.filterAutocompleteList = this.inputDropdownFormGroup?.get(this.inputDropdownFormControlName)?.valueChanges.pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.[this.inputDropdownFilterKey]),
      map((name: any) => name ? this._filterArray(name, this.inputDropdownArray, this.inputDropdownFilterKey) : this.inputDropdownArray?.slice())
    );
    console.log(this.filterAutocompleteList, "filter")
  }

  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }


  protected displayFn = (data: any) => {
    if (data?.[this.inputDropdownFilterKey]) {
      return data && data?.[this.inputDropdownFilterKey] ? data[this.inputDropdownFilterKey] : '';
    } else if (data?.name) {
      return data && data?.name;
    } else {
      return data;
    }
  }

}
