








import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, takeUntil } from 'rxjs';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { DataService } from 'src/app/shared/services/data/data.service';

@Component({
  selector: 'app-custom-multiselect-jira',
  templateUrl: './custom-multiselect-jira.component.html',
  styleUrls: ['./custom-multiselect-jira.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomMultiselectJiraComponent
  implements OnInit, OnDestroy {
  selectedItems: any = [];
  dropdownSettings: IDropdownSettings = {};
  @Input() inputSelectFormGroup: any = new FormGroup({});
  @Input() preferredId: boolean = false;
  @Input() inputSelectFormContolName: any;
  @Input() inputSelectLabel: any;
  @Input() inputSelectPlaceholderLabel: any = 'All';
  @Input() inputSelectArray: any[] = [];
  @Input() inputSelectPlaceholder: any;
  @Input() inputSelectErrorLabel: any;
  @Input() customDropdownSettings = {};
  @Input() inputSelectAppreance: any = 'outline';
  @Input() inputSelectDisableState: boolean = false;
  @Input() inputSelectRequiredStatus: boolean = false;
  @Input() inputSelectOptionKey: string = 'display_name';
  @Input() inputSelectOptionValue: string = 'id';
  @Input() inputSelectMutipleOptionStatus: boolean = false;
  @Input() inputSelectPrefixIcon: any;
  @Input() inputSelectSuffixIcon: string = 'dropdown_arow_down';
  @Input() inputSelectPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputSelectSuffixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputSelectGroupBy: boolean = false;
  @Input() inputSourceData: string = '';
  @Output() inputEmitSelected = new EventEmitter();
  @ViewChild('mselect') mselect!: ElementRef;
  isDropdownChanged: boolean = false;
  isDropClosed: boolean = true;
  toggleParent: boolean = false;
  destroyed = new Subject<void>();
  @Input() removePadding: boolean = false;
  @Output() removeSelected = new EventEmitter();
  constructor(
    private _lodash: LodashService,
    private _dataService: DataService
  ) {
    this.selectedItems = [
      // { id: 3, display_name: 'Pune' },
      // { id: 4, display_name: 'Navsari' }
    ];
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: this.inputSourceData || 'id',
      textField: this.inputSelectOptionKey || 'display_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 1,
      maxHeight: 100,
      allowSearchFilter: true,
    };
    this._dataService.deSelectDropdown$
      .pipe(takeUntil(this.destroyed))
      .subscribe((res: any) => {
        if (res) {
          this.inputSelectFormGroup.get(res).setValue([]);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }


  emitData(data: any) {
    this.inputEmitSelected.emit(data);
  }

  selectedValueEmit(data: any) {
    if (this.isDropdownChanged) {
      this.emitData(
        this.inputSelectFormGroup.get(this.inputSelectFormContolName).value
      );
      this.isDropdownChanged = false;
    }
  }

  onDropDownClose() {
    this.selectedValueEmit(this.selectedItems);
  }

  setDropdownChangeStatus() {
    this.isDropdownChanged = true;
  }

  onItemSelect(item: any) {
    this.setDropdownChangeStatus();
  }

  onSelectAll(items: any) {
    this.setDropdownChangeStatus();
  }

  onClickDropdown(e: any) {
    console.log('on onClickDropdown', e);
    if (
      !this.mselect.nativeElement
        .querySelector('.dropdown-list')
        .hasAttribute('hidden') &&
      this.mselect.nativeElement.querySelector(
        '.dropdown-list .item1 .filter-textbox input'
      )
    ) {
      this.mselect.nativeElement
        .querySelector('.dropdown-list .item1 .filter-textbox input')
        .focus();
    }
  }

  onDeSelectAll() {
    this.setDropdownChangeStatus();
  }

  onDeSelect(item: any) {
    this.mselect.nativeElement
      .querySelector('.dropdown-list')
      .hasAttribute('hidden');
    this.emitData(
      this.inputSelectFormGroup.get(this.inputSelectFormContolName).value
    );
  }
}
