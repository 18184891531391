import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparator'
})
export class CommaSeparatorPipe implements PipeTransform {

  transform(value: any): any {
    return Number(value).toLocaleString();
    // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
