import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';



@Component({
  selector: 'custom-table-filter',
  templateUrl: './custom-table-filter.component.html',
  styleUrls: ['./custom-table-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomTableFilterComponent implements OnInit {
  defaultSelectedIndex:number = 0;
 customTableFilterFormGroup: FormGroup | any;
  // protected toggleFilter: boolean = false;
  protected staticText: any = (textConfiguration as any).default;
  @Input() standAlone: boolean = false;
  @Input() toggleFilter: boolean = true;
  @Input() customTableFilterSource: any[] = [];
  @Input() customCategoryTableFilterSource:any[]=[];
  @Input() customTableFilterButtonColor: string = '';
  @Input() customTableFilterButtonClass: string = 'b-shadow-none';
  @Input() customTableFilterTitle: string = '';
  @Input() customTableFilterTitleIcon: string = 'filter-black';
  @Input() customTableFilterAccordionIcon: string = 'filter_down';
  @Input() customTableFilterLabel: string = '';
  @Input() customTableFilterLabelClass: string = 'ms-1';
  @Input() customTableFilterFormClass: string = '';
  @Input() customTableFilterColor: any = '';
  @Input() customTableFilterClass: string = 'text-capitalize b-shadow-none';
  @Input() customTableFilterContainerClass: string = '';
  @Input() customTableFilterIcon: string = 'filter_black';
  @Input() customTableResetFilterLabel: string = '';
  @Input() customTableResetFilterLabelClass: string = 'ms-1';
  @Input() customTableResetFilterColor: any = 'primary';
  @Input() customTableResetFilterClass: string = 'text-capitalize b-shadow-none';
  @Input() customTableResetFilterIcon: string = 'clear_filter';
  @Input() customTableFilterIconsBasePath: string = '/assets/images/common/icons/';
  @Input() customTableFilterScrollconfig: PerfectScrollbarConfigInterface = {};
  @Input() customTableFilterInDependentType: boolean = false;
  @Input() customTableFilterShowActionGroup: boolean = true;
  @Input() customTableFilterHeaderBoxShow: boolean = false;
  @Output() customTableFilterValueEmitter = new EventEmitter();
  @Output() customTableInDependentFilterValueEmitter = new EventEmitter();
  @Output() dependencyFilter = new EventEmitter();
  @Output() emitCommonType = new EventEmitter();
  @Input() DashboardSet:boolean = false;
  @Input()ReportsPatchValue:any;
  @Input()errorCheck:boolean = false;
  filterSourceKeydata: any[] = [];
  toggleCategory:boolean[] = [];
  advanceTypeSelectedItem:any[] = [];
  checkAdvanceid:any[] = [];
  checkCommonid:any [] = [];
   selectedDateValue:any;
  @Input() customCategoryfilter:boolean = false;
  setControl:any[] = [];
  showOverflowClass:boolean = false;
  selectedCreatedOn:any;
  selectedLastModifiedOn:any;
  filterSource = this.staticText?.daily_case_manangement?.list?.table_top_filter;

  constructor(private formbuilder: FormBuilder, private _formValidator: FormValidatorService,private _tableService:CustomTableService,private dataService:DataService,private _loadsh:LodashService) { }

  ngOnInit(): void {
    this.toggleCategory = new Array(3).fill(false);
    this.toggleCategory[0] = true;
    if (this.customTableFilterInDependentType) { this.independentControlListenValueChanges();   
    }
    if(this.customCategoryfilter){
      this.creatForm(this.customCategoryTableFilterSource)
      this.getSelectedValue();
    }

    // if(this.DashboardSet){
    //   this.getDashboardSelectedValue()
    // }
    if(this.ReportsPatchValue){
      this.patchDataReports();
    }
    
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes['customTableFilterSource']){
      this.creatForm(this.customTableFilterSource)  
    }
  }
  
  patchDataReports(){
    this.customTableFilterFormGroup.patchValue({
      valuation:this.ReportsPatchValue?.[0]
    });
    this.customTableFilterFormGroup.get('valuation')?.disable();
  }

  getSelectedValue() {
    this.customTableFilterFormGroup.valueChanges.subscribe((x:any) => {
      this.advanceTypeSelectedItem = [{}, {}, {}];
      const keyToRemove: string = 'date_range';
      const { [keyToRemove]: removedKey, ...newObject } = x;
      for (const key in newObject) {
        if (typeof newObject[key] === "object") {
          if (Array.isArray(newObject[key])) {
            this.advanceTypeSelectedItem[0][key] = newObject[key];
          } else {
            this.advanceTypeSelectedItem[1][key] = newObject[key];
          }
        } else {
          this.advanceTypeSelectedItem[2][key] = newObject[key];
        }
      };
      this.selectedDateValue = x?.date_range?.start_date && x?.date_range?.end_date ? x?.date_range:null;
      this.selectedCreatedOn = x?.created_on?.valuation_created_on_start && x?.created_on?.valuation_created_on_end ? x?.created_on:null;
      this.selectedLastModifiedOn = x?.last_modified_on?.valuation_last_updated_on_start && x?.last_modified_on?.valuation_last_updated_on_end ? x?.last_modified_on:null;
      console.log(x);
      
    });  
  }
  

  getDashboardSelectedValue(){
    this.customTableFilterFormGroup.valueChanges.subscribe((x:any) => {
     this.dataService.dashboardSelectedFilter = x; 
    })
  }

  filterToggle(){
    this.toggleFilter = !this.toggleFilter;
  }

  resetToggle(){
    this.toggleCategory[0] = true;
    this.toggleCategory[1] = false;
    this.toggleCategory[2] = false;
    // this.setInitialValue();
    this.showOverflowClass = false
  }

  setInitialValue(){
    this.toggleCategory.map((o:any, index:number) => {
      if (index === 0)
        o = true;
       else 
        o = false;
      return o;
    })
  }
  setFormControl(data: any) {
    this.filterSourceKeydata = data;
    this.customTableFilterFormGroup = this.formbuilder.group({});
    this.filterSourceKeydata?.forEach((source: any) => {
      if (source['isRange']) {
        this.customTableFilterFormGroup.addControl(source['key'] || source['name'], new FormGroup({
          [source.formControlName_start || 'start_date']: new FormControl<Date | null>(null, Validators.required),
          [source.formControlName_end || 'end_date']: new FormControl<Date | null>(null, Validators.required)
        }));
        return;
      } else if (source['isCustomRange']) {
        this.customTableFilterFormGroup.addControl(source['key'] || source['name'], [
          {
            startDate: null,
            endDate: null
          }
        ]);
        return;
      } else if(source['type'] == "input_dropdown" && source['key']?.length){
        for (let index = 0; index < source['key'].length; index++) {
          const element = source['key'][index];
          this.customTableFilterFormGroup.addControl(element, new FormControl(''));
        }
        return
      }
      this.customTableFilterFormGroup.addControl(source['key'] || source['name'], new FormControl(''));
    });
  }

  creatForm(data: any) {  
    if(this.customCategoryfilter){
     this.customTableFilterFormGroup = this.formbuilder.group({});
     this.customCategoryTableFilterSource.map((o:any)=>this.setFormControl(o?.filter))
    }
    else{
      this.customTableFilterFormGroup = this.formbuilder.group({});
      this.setFormControl(data)
    }
  }

  deSelectedRange(control:any){
  this.dataService.deselectedRange.next(control);
  }

  toggleCategoryBtn(i:any){
    this.toggleCategory[i] = !this.toggleCategory[i];
    const length:any = this.toggleCategory.filter((o:any)=>o);  
    this.showOverflowClass = length?.length  ? true : false; 
    if(i == 1){
      this.defaultSelectedIndex+=1;
      this.defaultSelectedIndex == 1 && this.emitCommonType.emit(true)
    }
  }
  
  deSelectedValue(key:any,boolean?:any){
    if(boolean)
    this.dataService.deSelectDropdown.next(key);
    else{
      const checkControl = this.filterSource[1]?.filter?.map((o:any)=>(o?.key));
      if(checkControl?.includes(key))
      this.dataService.deSelectAutoComplete.next(key); 
      else
      this.dataService.deSelectInput.next(key); 
    } 
    
  }
  

 

 

  independentControlListenValueChanges() {
    this.filterSourceKeydata?.forEach((source: any) => {
      this.customTableFilterFormGroup.get(`${source?.key}`)?.valueChanges?.subscribe((Data: any) => {
        if (Data instanceof Object && (source?.is_IndependentFilter ? source?.is_IndependentFilter : false)) {
          this.customTableInDependentFilterValueEmitter.emit({ isFilter: source?.is_IndependentFilter, value: { data: Data } })
        }
      })
    })
  }

  setFormDataByKey(fieldName:string, fieldVal:any) {
    this.customTableFilterFormGroup.patchValue({[fieldName]: fieldVal});
    this.customTableFilterFormGroup.updateValueAndValidity();
  }

  setFormDataByObj(patchObj:any) {
    this.customTableFilterFormGroup.patchValue(patchObj);
    this.customTableFilterFormGroup.updateValueAndValidity();
  }

  getFormGroup() {
    return this.customTableFilterFormGroup;
  }

  applyFilter() {
    this.customTableFilterValueEmitter.emit({ isFilter: true, value: this.customTableFilterFormGroup.value });
  }

  resetFilter() {
  this.customTableFilterFormGroup.reset();
  this.customTableFilterValueEmitter.emit({ isFilter: true, value: null });
};

  filterValidation() {
    let rangePicker = this.customTableFilterSource.filter(x => x.isRange == true);
    let result: Boolean = true;
    if (rangePicker.length) {
      result = this.validation(this.customTableFilterFormGroup?.value);
    }
    else if(this.errorCheck){
      result = this.validation(this.customTableFilterFormGroup?.value);
    }
    else {
      result = this.customTableFilterFormGroup?.errors?.hasValue;
    }
    return result;
  }

  validation(value?: any): Boolean {
    let result = true;
    try {
      for (let [key, data] of Object.entries(value || {})) {
        let item: any = data;
        // console.log('validation item: ', key, data)
        if (!result)
          break;
        if (item && Array.isArray(item)) {
          result = !item.length ? true : false;
        } else if (typeof (item) == "object" && item != null) {
          result = !(item[Object.keys(item)[0]]) ? true : false;//&& item[Object.keys(item)[1]]
        } else {
          result = !item ? true : false;
        }
      }
      // console.log('validation: ', value, result)
      return result;
    } catch (ex: any) {
      return result;
    }

  }

  accumulater(...data: any) {
    try {
      let [currentDate, index, referenceData, isFilter] = [...data]
      return {
        isFilter: isFilter,
        currentDate: currentDate,
        index: index,
        referenceData: referenceData
      }
    } catch (ex: any) {
      return {}
    }
  }

  inputAutoCompleteEmitter(data: any) {
    this.dependencyFilter.emit(data)
  }



}
