<div class="success-modal-card p-0">
    <div class="success-modal-custom-background d-flex header_popup">
        <div>
            <!-- <img src="../../../assets/images/common/icons/stamp.svg" alt="info" /> -->
        </div>
        <div class="popup_clse">
            <div class="succeed_msg">
                
                <img class="cursor-pointer" src="../../../../../assets/images/common/icons_usp/stamp.png" alt="close"/>
<!-- {{successDialogData | json}} -->
<h2 class="mb-0" [innerHtml]="successDialogData ? successDialogData?.header :'Success'"></h2>
                <!-- <h2 class="mb-0" [innerHtml]="successDialogData?.header ? successDialogData?.title :'Success'"></h2> -->
            </div>
           
           
        </div>
    </div>
    <div class="success-modal-container pb-2">
        <div class="d-flex flex-column px-4 py-4">
            <div class="success-modal-body">
               
                
                @if(successDialogData?.msg){
                    <p class=" success-text"  [innerHTML]="successDialogData?.msg"></p>
                }
                @else {
                    <p class=" success-text">
                        Changes have been successfully updated.
                    </p>
                }
                


            </div>

            @if (successDialogData?.template) {

                <div [innerHTML]="successDialogData?.template">

                </div>
            }

        </div>
        <!-- <mat-divider></mat-divider> -->
        <div class="success-modal-actions text-center cursor-pointer px-4 p-3 pb-4 mb-0 d-flex justify-content-center align-item-center"
            (click)="dialogClose(true)">
            <div class="success-modal-close-button">
                <p [innerHtml]="successDialogData?.close_button ? successDialogData?.close_button:'Done'"
                    class="fs-18 m-1 color-dark-blue"></p>
            </div>
        </div>
    </div>
</div>