import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSelectComponent implements OnInit, AfterViewInit {

  @Input() inputSelectFormGroup: FormGroup | any = new FormGroup({});
  @Input() preferredId: boolean = false;
  @Input() inputSelectFormContolName: any;
  @Input() inputSelectLabel: any;
  @Input() inputSelectPlaceholderLabel: any;
  @Input() inputSelectArray: any[] = [];
  @Input() inputSelectPlaceholder: any;
  @Input() inputSelectErrorLabel: any;
  @Input() inputSelectAppreance: any = 'outline';
  @Input() inputSelectDisableState: boolean = false;
  @Input() inputSelectreadonly: boolean = false;
  @Input() inputSelectRequiredStatus: boolean = false;
  @Input() inputSelectOptionKey: string = 'display_name';
  @Input() inputSelectMutipleOptionStatus: boolean = false;
  @Input() inputSelectreadonlyColor: boolean = false;
  @Input() enableClear: boolean = false;
  @Input() inputSelectPrefixIcon: any;
  @Input() inputSelectSuffixIcon: string = "dropdown_arow_down";
  @Input() inputSelectPrefixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputSelectSuffixIconClass: any = 'p-15 color-gray fs-14';
  @Input() inputIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputSelectGroupBy: boolean = false;
  @Output() inputEmitSelected = new EventEmitter();
  @Input() customBackground: any;
  selectedValues: any = [];
  constructor() { }

  ngOnInit(): void { }

  get getDisplayText(): string {
    if (typeof this.selectedValues === "object" && this.selectedValues !== null && !Array.isArray(this.selectedValues)) {
      return this.selectedValues[this.inputSelectOptionKey];
    }
    if (this.selectedValues?.length <= 2) {
      return this.selectedValues?.map((x: any) => x.display_name)?.toString()
    } else {
      const firstTwo = this.selectedValues?.slice(0, 2);
      return `${firstTwo?.map((x: any) => x.display_name)?.toString()}, ${this.selectedValues?.length - 2}+ more...`;
    }
  }

  ngAfterViewInit(): void {
    console.log('inputSelectArray: ', this.inputSelectArray);
    setTimeout(() => {
      this.selectedValues = this.inputSelectFormGroup?.get(this.inputSelectFormContolName)?.value || [];
    }, 1000);
  }

  selectedValueEmit(data: any) {
    this.selectedValues = data?.source?.value
    this.inputEmitSelected.emit(data?.source?.value);
  }

  clear(){
    this.inputSelectFormGroup?.get(this.inputSelectFormContolName)?.setValue(null);
    this.selectedValues = null;
    this.inputEmitSelected.emit(null);
  }

}
