<div class="success-modal-card p-0">
    <div class="success-modal-custom-background d-flex header_popup">
        <div>
        <!-- <img src="../../../assets/images/common/icons/stamp.svg" alt="info" /> -->
    </div>
    <div class="popup_clse">
        <h2 class="mb-0" [innerHtml]="successDialogData?.title ? successDialogData?.title :'Success'"></h2>
        <div>
            <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close" (click)="onCancel()" />

        </div>
        <!-- Changes has been Saved! -->
    </div>
  </div>
    <div class="success-modal-container pb-2">
        <div class="d-flex flex-column px-4 py-4">
            <div class="success-modal-body">
                
                
                <p [innerHtml]="successDialogData?.paragraph ? successDialogData?.paragraph:'created successfully'"
                    class=" success-text">
                </p>
            </div>
        </div>
        <!-- <mat-divider></mat-divider> -->
        <div class="success-modal-actions text-center cursor-pointer px-4 p-3 pb-4 mb-0 d-flex justify-content-center align-item-center"     
            (click)="dialogClose(true)">
            <div class="success-modal-close-button">
                <p [innerHtml]="successDialogData?.close_button ? successDialogData?.close_button:'Done'"
                    class="fs-18 m-1 color-dark-blue"></p>
            </div>
        </div>
    </div>
</div>