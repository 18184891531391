<div class="custom-date-picker" [formGroup]="inputDatePickerFormGroup">
    <mat-label *ngIf="inputDatePickerLabel" class="color-primary"
        [ngClass]="removePadding?'text-smaller':''">{{inputDatePickerLabel}}<sup class="color-red"
            *ngIf="inputDatePickerRequiredStatus">&ast;</sup></mat-label>
    <div class="d-flex flex-column">
        <!-- custom date range picker input -->
        <mat-form-field [appearance]="inputDatePickerAppearance"
            [ngClass]="{removeInputBottomPadding: inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.touched && inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.errors,'remove-padding':removePadding}">
            <!-- <mat-label *ngIf="inputDatePickerPlaceholderLabel">{{inputDatePickerPlaceholderLabel}}</mat-label> -->
            <!-- <mat-date-range-input [formGroupName]="inputDatePickerFormControlName"
                [rangePicker]="inputDatePickeReference" [required]="inputDatePickerRequiredStatus"
                (dateChange)="onDateChanged($event)" [min]="inputDatePickerMinDate" [max]="inputDatePickerMaxDate">
                <input matStartDate placeholder="Start date" [formControlName]="filterOption?.formControlName_start || 'start'" class="designAlign"
                    (focus)="openMethod()">
                <input matEndDate placeholder="End date" [formControlName]="filterOption?.formControlName_end || 'end'" (focus)="openMethod()">
            </mat-date-range-input> -->
            <!-- <mat-datepicker-toggle matSuffix [for]="inputDatePickeReference">
                <img matDatepickerToggleIcon [src]="inputDatePickerIconImgBasepath+inputDatePickerToggleIcon+'.svg'"
                    [alt]="inputDatePickerToggleIcon">
            </mat-datepicker-toggle> -->
            <!-- <mat-date-range-picker #inputDatePickeReference
                [startView]="inputDatePickerStartView"></mat-date-range-picker> -->
            <input type="text" matInput autocomplete="off" ngxDaterangepickerMd [startKey]="inputStartKeyName"
                [endKey]="inputEndKeyName" [showCustomRangeLabel]="inputShowCustomRangeLabel"
                [alwaysShowCalendars]="inputAwaysShowCalendars" [ranges]="ranges"
                [linkedCalendars]="inputLinkedCalendars" [showRangeLabelOnInput]="inputShowRangeLabelOnInput"
                (datesUpdated)="onDateChanged($event)" opens="left" [showClearButton]="inputShowClearButton"
                [showCancel]="inputShowCancel" [placeholder]="inputDatePickerPlaceholderLabel"
                [formControlName]="inputDatePickerFormControlName" name="daterange" />
            <img alt="" class="calender_icn_width" src="/assets/images/common/icons/calendar.svg" class="daterange-icon" />
            <img alt="" *nfIf="" class="calender_icn_width" src="/assets/images/common/icons/calendar.svg"
                class="daterange-icon" />
        </mat-form-field>
        <!-- custom date range picker input -->

        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputDatePickerErrorLabel"
            *ngIf="inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.touched && inputDatePickerFormGroup?.get(inputDatePickerFormControlName)?.errors">
        </custom-error>
    </div>
</div>