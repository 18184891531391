import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';

@Component({
  selector: 'app-common-modal-prompt',
  templateUrl: './common-modal-prompt.component.html',
  styleUrls: ['./common-modal-prompt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonModalPromptComponent implements OnInit {

  staticText: any = (textConfiguration as any).default;

  constructor(public _commonDialogRef: MatDialogRef<CommonModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public commonDialogData: any) { }

  ngOnInit(): void { }

  dialogClose(params?: any) {
    this._commonDialogRef.close(params);
  }
  
}
