import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { colorCodes } from 'src/app/core/services/utils/constants';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
@Component({
  selector: 'custom-file',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss']
})
export class CustomFileUploadComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() inputFile: any;
  @Input() inputFileFormGroup!: FormGroup;
  @Input() inputFileFormControlName: any = '';
  @Input() inputFileId: any;
  @Input() inputFileLabel: string = '';
  @Input() inputFileUploadLabel: string = 'Choose File';
  @Input() inputFileUploadLabelClass: string = '';
  @Input() inputFileRequiredStatus: boolean = false;
  @Input() inputFileAcceptExtenstions: any = ".jpg, .png, .doc, .xls, .xlxs .ppt, .pdf";
  @Input() inputFileSizeWarningMsg: any = this.staticText?.prequalification?.create?.add?.file_size
  @Input() inputFileErrorLabel: any;
  @Input() inputFileErrorIconStatus: boolean = false;
  @Input() inputFileErrorIconName!: string;
  @Input() inputFileAllowMultiple: boolean = false;
  @Input() inputFileLabelShow: boolean = true;
  @Input() inputFileNameShow: boolean = false;
  @Input() inputFileSizeShow: boolean = false;
  @Input() inputFileShowFileDownloadIcon: boolean = false;
  @Input() inputFileShowFileDeleteIcon: boolean = false;
  @Input() inputFileAttachmentShow: boolean = true;
  @Input() inputFileErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputFileLinkTarget: string = "_blank";
  @Output() inputFileOnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputFileOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('inputFilePickeReference') inputFilePickeReference: QueryList<ElementRef> | any;
  @Input() fileName: any;
  @Input() assignFileLink: any;
  @Input() customDownloadDelete: boolean = false;

  constructor(private _snackBar: SnackbarService) {
  }

  ngOnInit(): void {
    this.inputFileUploadLabel = this.inputFileUploadLabel || this.staticText?.common?.choose_file_button;
    console.log('inputFileUploadLabel::: ', this.inputFileUploadLabel, this.inputFileUploadLabelClass)

  }

  bytesToMB(bytes:number) {
    return (bytes / (1024 * 1024));
  }

  isAllowedExtension(fileName: any, allowedExtensions: any) {
    // Get the file extension
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check if the file extension is in the allowed extensions list
    return allowedExtensions.includes(fileExtension);
  }

  onFileChange(data: any) {
    if(!this.isAllowedExtension(data.target.files?.[0]?.name, this.inputFile.file_name_extension)){
      this._snackBar.loadSnackBar(`File support format: ${this.inputFile.file_name_extension.toString()}`, colorCodes.ERROR);
    }else if (data.target.files?.[0].size > this.inputFile.file_size) {
      this._snackBar.loadSnackBar(`File size should not exceed ${this.bytesToMB(this.inputFile.file_size)} MB`, colorCodes.ERROR);
    } 
    else {
      this.inputFile.file = data.target.files;
      this.inputFileOnSelect.emit(data.target.files);
    }
  }

  downloadFile(data: any) {
    if (data?.[0]?.size) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data?.[0]);
      link.download = data?.[0]?.name;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }

  inputDeleteFile(file?: any) {
    this.inputFilePickeReference.first.nativeElement.value = '';
    this.inputFile.file = null;
    this.fileName = null;
    this.inputFileOnDelete.emit(true);
  }
}
