    <div class="custom-autocomplete" *ngIf="inputAutocompleteFormGroup" [formGroup]="inputAutocompleteFormGroup">
      <mat-label *ngIf="inputAutocompleteLabel" class="color-primary" [ngClass]="removePadding?'text-smaller':''">{{inputAutocompleteLabel}}<sup class="color-red"
          *ngIf="inputAutocompleteRequiredStatus && !requiredIndicator">&ast;</sup></mat-label>
      <div class="d-flex flex-column drp_down_img" [ngClass]="{'disabled-field': inputAutoCompletereadonly, 'disabled-field-color': inputAutoCompletereadonlyColor,'remove-padding':removePadding}">
        <mat-form-field class="example-full-width" [appearance]="inputAutocompleteAppearance"
          [ngClass]="{removeInputBottomPadding: inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.touched && inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.errors && !requiredIndicator}">
          <!-- custom input field prefix icon -->
          <span matPrefix *ngIf="inputAutocompletePrefixIcon" [ngClass]="inputAutocompletePrefixIconClass">
            <!-- below line will render font awesome Icon -->
            <i [class]="inputAutocompletePrefixIcon"
              *ngIf="inputAutocompletePrefixIcon?.includes('fa');else loadImg;"></i>
            <!-- below line will render svg icon (located in images->common->icons) -->
            <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputAutocompletePrefixIcon+'.svg'" alt="icon">
            </ng-template>
          </span>
          <mat-label *ngIf="inputAutocompletePlaceholderLabel">{{inputAutocompletePlaceholderLabel}}</mat-label>
          <input #inputBox type="text" [placeholder]="inputAutocompletePlaceholder" aria-label="Number" matInput 
            [readonly]="inputAutoCompletereadonly" [style.color]="inputAutoCompletereadonlyColor" [formControlName]="inputAutocompleteFormControlName"
            [matAutocomplete]="inputAutoCompleteReference" [required]="inputAutocompleteRequiredStatus">
          <!-- custom input field suffix icon -->
          <span matSuffix *ngIf="inputAutocompleteSuffixIcon" [ngClass]="inputAutocompleteSuffixIconClass">

            <ng-container *ngIf="inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.value">
              <span class="clearIcn" (click)="clearValue()">
                <!-- <i class="fa fa-close"></i> -->
                <img src="/assets/images/common/icons/close_black.svg" alt="icon">
              </span>
            </ng-container>
           
            <!-- below line will render font awesome Icon -->
            <i [class]="inputAutocompleteSuffixIcon"
              *ngIf="inputAutocompleteSuffixIcon?.includes('fa');else loadImg;"></i>
            <!-- below line will render svg icon (located in images->common->icons) -->
            <ng-template #loadImg> <img [src]="inputIconImgBasepath+inputAutocompleteSuffixIcon+'.svg'" alt="icon" [ngClass]="{'position-relative': inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.value }">
            </ng-template>
          </span>
          <mat-autocomplete [requireSelection]="requireSelection" #inputAutoCompleteReference="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="SelectedOption($event)">
            <mat-option *ngFor="let option of filterAutocompleteList | async;let optionIndex=index" [value]="option"
              [disabled]="inputAutocompleteDisableIndex ? inputAutocompleteDisableState && inputAutocompleteDisableIndex.includes(optionIndex) : inputAutocompleteDisableState" title="{{option?.hasOwnProperty(inputAutocompleteFilterKey) && option?.[inputAutocompleteFilterKey] ?  option?.[inputAutocompleteFilterKey] : option?.name ? option?.name : option}}">
              {{option?.hasOwnProperty(inputAutocompleteFilterKey) && option?.[inputAutocompleteFilterKey] ?  option?.[inputAutocompleteFilterKey] : option?.name ? option?.name : option}}
            </mat-option>
            
          </mat-autocomplete>
        </mat-form-field>
        <!-- custom error component -->

        <custom-error [customErrorLabel]="inputAutoCompleteErrorLabel"
            *ngIf="inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.touched && inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.invalid">
        </custom-error>
      </div>
    </div>

