import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-radio',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent implements OnInit {

  @Input() inputRadioFormGroup: FormGroup | any;
  @Input() inputRadioFormControlName: any;
  @Input() inputRadioClass: any = 'w-100';
  @Input() inputRadioRequiredStatus: boolean = false;
  @Input() inputRadioDisableState: boolean = false;
  @Input() inputRadioErrorLabel: any;
  @Input() inputRadioErrorIconStatus: boolean = false;
  @Input() inputRadioErrorIconName!: string;
  @Input() inputRadioErrorIconPrefixPath: string = "/assets/images/common/icons/";
  @Input() inputRadioOptions: any[] = [{ "id": 1, "name": "satisfied", "value": true }, { "id": 2, "name": "Not Satisfied", "value": false }];
  @Input() inputRadioOptionsDefault: any[] = [{ "id": 1, "name": "Yes", "value": true }, { "id": 2, "name": "No", "value": false }];
  @Input() inputRadioOptionClass: string = "me-4";
  @Input() inputRadioOptionGroupClass: string = "d-flex flex-row justify-content-around align-items-center";
  @Input() inputRadioOptionGroupColor: any = 'Orient';

  @Output() selectedValueEmit = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  radioSelectedValue(data: any) {
    this.selectedValueEmit.emit(data);
  }

}
