<div class="custom-input" *ngIf="inputRadioFormGroup" [formGroup]="inputRadioFormGroup">
    <div class="d-flex flex-column">
        <!-- custom Radio Input-->
        <mat-radio-group class="d-flex" [formControlName]="inputRadioFormControlName" [required]="inputRadioRequiredStatus"
           (change)="radioSelectedValue($event)" [color]="inputRadioOptionGroupColor">
            <ng-container *ngFor="let option of inputRadioOptions" [class]="inputRadioOptionGroupClass" >
                <mat-radio-button [value]="option?.value !=undefined ? option?.value : option?.name" [disabled]="inputRadioDisableState"
                    *ngIf="option?.name" [class]="inputRadioOptionClass">
                    {{option?.name}}
                </mat-radio-button>
            </ng-container>
        </mat-radio-group>

        <!-- custom error component -->
        <custom-error [customErrorLabel]="inputRadioErrorLabel" [customErrorIconShowStatus]="inputRadioErrorIconStatus"
            [customErrorIconName]="inputRadioErrorIconName" [customErrorPreFixPath]="inputRadioErrorIconPrefixPath"
            *ngIf="inputRadioFormGroup.get(inputRadioFormControlName)?.touched && inputRadioFormGroup.get(inputRadioFormControlName)?.errors">
        </custom-error>
    </div>
</div>