import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { PaginationControlsDirective } from 'ngx-pagination';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RouterService } from 'src/app/core/services/router/router.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { Actions } from '../../interface/custom-table/custom-table-actions';
import { CustomTableService } from '../../services/custom-table/custom-table.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { Router } from '@angular/router';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { DataService } from '../../services/data/data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[CustomTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTableComponent implements OnInit, AfterViewInit,OnDestroy{

  @Input() tableCustomActionSelectAll: any;
  @Input('multi') haveMultiSelect: Boolean = false;
  @Input() lastColFreezed: boolean = true;
  @Input() lastTwoColFreezed: boolean = true;
  @Input() localColumnSearch: boolean = false;
  @Input() internalColumnSearch: boolean = true; //This value depand on API (or) Local serach
  @Input() columnSearchOptionsList: any = {};
  @Input() columnDefs: any = {};
  @Input() tableClass: string = '';
  @Input() tableId: string = 'cust-tbl';
  @Input() tableViewType: string = '';
  @Input() columnTitleFieldName: string = 'Title';
  @Input() tableRowData: any = [];
  rowFinalData: any = [];
  showLoader:boolean = true;
  initialTotalRecord: any = 0;
  activePageNoBeforeSearch: any = 1;
  searchSelectColDefaultVal:string = 'default-val';
  @Input() tableCellClick: any;
  @Input() tableTotalRecords: any = 0;
  @Input() tablePaginationCustom: boolean = true;
  @Input() tablePaginationMaxSize: any = 9;
  @Input() tablePaginationHide: boolean = true;
  @Input() tablePaginationResponsive: boolean = true;
  @Input() tablePaginationPreviousLabel: any;
  @Input() tablePaginationNextLabel: any;
  @Input() tableColumnDataManipulationParams: any;
  @Input() customTableFilterScrollconfig: PerfectScrollbarConfigInterface = {};
  @Input() customTableFilterScrollconfigListView: PerfectScrollbarConfigInterface = { suppressScrollX: true };
  @Input() tableCustomActionButton: any;
  @Input() tableCustomActionPermissions: any;
  @Input() tableCustomActionPermissionsStatus: boolean = true;
  @Input() actionBlockKey: any;
  @Input() actionBlockValue: any;
  @Input()reduceFontsize:boolean = false;
  @Input() clientSideValidation: boolean = false;
  @Input() tableShowPagination: boolean = true;
  @Input() tablePageSizeOptions: boolean = true;
  @Input() expansionalTable: boolean = false;
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() deleteParam = new EventEmitter();
  @Output() actionParam = new EventEmitter();
  @Output() CustomActionParam = new EventEmitter();
  @Output() BulkAction = new EventEmitter();
  @Output() columnSearch = new EventEmitter();

  searchSubject = new Subject<any>();

  @ViewChild('customTablePagination') customTablePagination: PaginationControlsDirective | any;

  protected params: any;
  searchObj: any = {};
  colSearchCustomName: string = '_searchField';
  protected lastPage: any;
  protected pageSize!: number;
  protected currentPage!: number;
  protected objEntries = Object.entries;
  protected staticText: any = (textConfiguration as any).default;
  selectedpageSize: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  protected selectOptions: any = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.custom_table_page_size_options;
  protected sortingColumns: boolean[] = new Array(this.objEntries(this.columnDefs).length).fill(false);
  colData: any = [];
  public destory = new Subject<void>();
  assignPageSize:boolean = false;
  currentComponentAction:boolean = false;

  public ranges: FormGroup[] = [];
   columnProperty: any[] = []; 
  protected actionParamsList: Actions = {};
  startDate: any;
  endDate: any;
  

  constructor(
    public _common: CommonService,
    private _routerService: RouterService,
    private _lodash: LodashService,
    private cdr: ChangeDetectorRef,
    public _tableSerivce: CustomTableService,
    private router: Router,
    private _datasServices:DataService,
    private datePipe: DatePipe
  ) {

    console.log('constr: ', this.colData,this.columnDefs);

    this.searchSubject.pipe(
      debounceTime(500) // Set debounce time here 
    ).subscribe((event) => {
      this.columnSearch.emit(event);
    });

  }

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

   initializeRanges(): void {
    // Assuming you have a known number of columns or get it from your data
    const numberOfColumns = this.colData.length; // Update accordingly
    for (let i = 0; i < numberOfColumns; i++) {
      this.ranges[i] = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
      });
    }
  }


 
  
  ngAfterViewInit(): void { 
    this.resetInlineSearchColumnVal();
  }

  refreshTableData(data: any, sorting:boolean = false, offsetVal:any = 0) {
    if(this.tableCustomActionSelectAll)
      this.tableCustomActionSelectAll.model = false;
    //this.resetInlineSearchColumnVal(); //For checking purpose
    console.log('refreshTableData be:: ', this._lodash.Clonedata(data), data, sorting, offsetVal, this.tableTotalRecords, this.initialTotalRecord)
    if(offsetVal == 0) {
      this.resetPagination(data);
    }
    console.log('refreshTableData:: ', this._lodash.Clonedata(data),data, sorting, offsetVal, this.tableTotalRecords, this.initialTotalRecord)
    // if (!this.currentComponentAction) {
    //   this.currentPage = 1;
    //   this._tableSerivce.currentPage = 1;
    //   this.setTableTotalRecordsCnt(data.length || 0);
    //   this.setCurrentActivePageNo(1);
    //   // this.doLocalSearch();
    //   // this.resetInlineSearchColumnVal();
    // } else {
    //   this.currentComponentAction = false;
    // }
    if (!sorting) {
      this.sortingColumns = new Array(this.objEntries(this.columnDefs).length).fill(false);
    }
    console.log('refreshTableData onInit: ', this._lodash.Clonedata(data),data, this.tableTotalRecords, this.columnSearchOptionsList, 'this.currentComponentAction: ', this.currentComponentAction,this.currentPage, this._tableSerivce);
    // this.setTableTotalRecordsCnt(totalRecords);
    this.tableRowData = data;
    console.log('refreshTableData onInit0: ', this._lodash.Clonedata(data),data, this.tableTotalRecords, this.columnSearchOptionsList, 'this.currentComponentAction: ', this.currentComponentAction,this.currentPage, this._tableSerivce);
    this.setRowFinalData(data);
    this.showLoader = false;
    console.log('refreshTableData onInit1: ', this._lodash.Clonedata(data),data, this.tableTotalRecords, this.columnSearchOptionsList, 'this.currentComponentAction: ', this.currentComponentAction,this.currentPage, this._tableSerivce);
  }
  
  setRowFinalData(data:any) {
    this.rowFinalData = data || [];
  }

  setTableTotalRecordsCnt(cnt = 0) {
    this.initialTotalRecord = cnt;
    this.tableTotalRecords = cnt;
  }

  resetTableTotalRecordsCnt() {
    this.tableTotalRecords = this.initialTotalRecord;
  }

  setCurrentActivePageNo(pageNo = 1) {
    this.activePageNoBeforeSearch = pageNo;
  }

  routerLinkClick(link: string, rowData?: any) {
    let _rowData = rowData.route.filter((x: { mode: string; }) => x.mode == "view");
    if (_rowData.length && _rowData[0].routeStatus == false) {
      let customActionData = { Data: rowData, action: 'view' }
      this.actionParam.emit(customActionData);
    } else {
      this.router.navigate([link]);
    }
  }
  ngOnInit(): void {
    this.colData = this.objEntries(this.columnDefs);
    console.log('onInit: ', this.colData,this.columnDefs, this.localColumnSearch, this.tableTotalRecords);
    this.setRowFinalData(this.tableRowData);
    this.setTableTotalRecordsCnt(this.tableTotalRecords);
    this._tableSerivce.pageSize = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
    this.initialPagination();
    this._tableSerivce.customTotalRecords$.pipe(takeUntil(this.destory)).subscribe((o:any)=>{
     this.assignPageSize = o?.call ? o?.call : false ;
     if(this.assignPageSize)
     this.customSelectedValue(o);
    })

    this.initializeRanges();
  }

  onChangeSelectBox() {
    console.log('onChangeSelectBox:: ')
  }

  rowSelect(rowData: any) {
    if (!this.tableCustomActionSelectAll.multiSelect) {
      this.tableRowData.forEach((value: any, index: any) => {
        value.select = false;
      });
      rowData.select = true;
    }
    if (this.tableRowData.length == this.tableRowData.filter((x:any)=>x.select == true)?.length) 
     { this.tableCustomActionSelectAll.model = true;
     }
     else if(this.tableRowData[0]?.enable_bulk_action_count == this.tableRowData.filter((x:any)=>x.select == true)?.length){
      this.tableCustomActionSelectAll.model = true;
     }
    else 
      {this.tableCustomActionSelectAll.model = false;
      }
      this.selectedBulkAction(false);  
  }

  resetPagination(data:any) {
    this.currentPage = 1;
    this._tableSerivce.currentPage = 1;
    // this.setTableTotalRecordsCnt(data.length || 0);
    this.initialPagination();
    this.setCurrentActivePageNo(1);
   
  }
  
  resetInlineSearchColumnVal() {
    console.log('resetInlineSearchColumnVal: ', this.localColumnSearch, this.searchObj)
    if(this.localColumnSearch) {
      this.searchObj = {};
      this.resetLocalSearchColVal();
      this.localColumnSearch = false;
      setTimeout(() => {
        this.localColumnSearch = true;
        this.cdr.detectChanges();
      }, 100)
    }
  }

  resetLocalSearchColVal() {
    this.colData.forEach((o:any) => {
      let colName = o[0] || {};
      let colVal = o[1] || {};
      if(this._lodash.getData(colVal, 'column_options.column_search_config.search_enabled', false)) {
        o[1]['column_options']['column_search_config']['column_search_val'] = '';
        this.columnDefs[colName]['column_options']['column_search_config']['column_search_val'] = '';
      }
    })
    
    console.log('resetLocalSearchColVal if: ', this.colData, this.localColumnSearch)
  }
  
  setSearchOption(colName:string, options:any = []) {
    this.columnSearchOptionsList[colName] = options;
    
  }

  getColSearchOptionList(searchColName:string  = '') {
    let resp = this._lodash.getData(this.columnSearchOptionsList, searchColName, []);
   
    return resp;
  }

  onChangeColSearchSelect(ev:any, columnProperty : any) {
    let selectVal = ev?.target?.value;
    let colSearchFieldName = this._lodash.getData(columnProperty, '1.column_options.column_search_config.search_colname', '')
    console.log('onChangeColSearchSelect ', selectVal,ev,colSearchFieldName)
    this.columnDefs[columnProperty[0]]['column_options']['column_search_config']['column_search_val'] = selectVal;
    this.setSearchSelectObj(columnProperty[0], selectVal);
  } 

  setSearchSelectObj(colName:string, val:any) {
    this.searchObj[colName] = val;
    if(val.trim() == this.searchSelectColDefaultVal) {
      delete this.searchObj[colName];
    }
    console.log('this.setSearchSelectObj: ', this.searchObj, colName, val, this.tableRowData); 
    if (this.internalColumnSearch) {
      this.doLocalSearch();
    } else {
      this.searchSubject.next({colName, val});
      // this.columnSearch.emit({colName, val})
    }
    
  }


 
   onSearchColumn(e: any, columIndex: number, columnProperty: any) {
    let colVal = e?.target?.value || '';
   
    
    if (e.keyCode != 17 && e.keyCode != 16 && e.keyCode != 20) {
      this.columnDefs[columnProperty[0]]['column_options']['column_search_config']['column_search_val'] = colVal;
      const searchConfig = this.columnDefs[columnProperty[0]]['column_options']['column_search_config'];
      
      if (searchConfig['search_type'] === 'date-range') {
        const filterKeyStart = searchConfig['search_key_start'];
        const filterKeyEnd = searchConfig['search_key_end'];
        
         this.startDate = this.ranges[columIndex].get('start')?.value;
        this.endDate = this.ranges[columIndex].get('end')?.value;

        const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
        const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
        
        // Set the start and end date to the search object
       if(formattedStartDate && formattedEndDate){
        this.setSearchObj(columnProperty[0], formattedStartDate, filterKeyStart);
        this.setSearchObj(columnProperty[0], formattedEndDate, filterKeyEnd);
       }
       else if(!formattedStartDate && !formattedEndDate){
        this.setSearchObj(columnProperty[0], colVal, filterKeyStart);
        this.setSearchObj(columnProperty[0], colVal, filterKeyEnd);
       }
        
      } else {
        const filterKey = searchConfig['search_key'];
        
        // Regular search for other types
        this.setSearchObj(columnProperty[0], colVal, filterKey);
      }
    }
   }
  
  
  

  setSearchObj(colName:string, val:any, filterKey?:string) {
    this.searchObj[filterKey ?? colName] = val;
    if(val.trim() == '') {
      delete this.searchObj[colName];
    }
    if (this.internalColumnSearch) {
      this.doLocalSearch();
    } else {
      this.searchSubject.next({colName, val});
      // this.columnSearch.emit({colName, val})
    }
    console.log('this.searchObj: ', this.searchObj, colName, val, this.tableRowData); 
  }

  getSearchFieldColNameVal(dataObj:any, colName:any) {
    let fieldName = this._lodash.hasData(dataObj, colName+this.colSearchCustomName) ? colName+this.colSearchCustomName : colName;
    let resp = (this._lodash.getData(dataObj, fieldName, '')+'').toLowerCase();
    // console.log('getSearchFieldColNameVal:',resp, dataObj, fieldName, colName)
    return resp;
  }

  getSearchData(o:any, colName:string) {
    let colType = this._lodash.getData(this.columnDefs, colName+'.column_options.column_search_config.search_type', 'text');
    return colType == 'select' ? this.getSearchFieldColNameVal(o, colName) == this.searchObj[colName].toLowerCase() : this.getSearchFieldColNameVal(o, colName).includes(this.searchObj[colName].toLowerCase());
  }

  doLocalSearch() {
    let searchArr = Object.entries(this.searchObj);
    if(searchArr?.length && this.tableRowData?.length) {
      let finalRowData = [];
      // console.log('finalRowData if: ', finalRowData, this.tableRowData, this.searchObj)
      finalRowData = this.tableRowData.filter((o: any) => {
        let resp = true;
        for (var colName in o) {
          
          resp =  !this.searchObj?.hasOwnProperty(colName) || !this.searchObj[colName] || (this.searchObj?.hasOwnProperty(colName) && o[colName] && this.searchObj[colName] && this.getSearchData(o, colName)) ? true : false;
          // console.log('finalRowData if o: ', colName, resp, o[colName], this.searchObj[colName])
          if(!resp) {
            // console.log('ignored: ', o, colName, o[colName], this.searchObj[colName], o[colName].includes(this.searchObj?.colName))
            return resp;
          }
        }
        return resp;
      })
     
      this.setRowFinalData(finalRowData);
      this.tableTotalRecords = finalRowData.length;
      this.initialPagination();
    } else {
     
      this.setRowFinalData(this.tableRowData);
      this.tableTotalRecords = this.initialTotalRecord;
      this.initialPagination(this.activePageNoBeforeSearch);
    }
  }


  onDropdownChange(bindedEvent: any = () => {}, ev: any, fieldName:any, data: any) {
    
    bindedEvent(ev.target.value,fieldName, data);
  }

  toggleHeaderRowCheckbox() {
    let status = this.tableCustomActionSelectAll.model;
    //  this.BulkAction.emit(status);
     this.tableRowData.forEach((value: any, index: any) => {
      value.select = status && !value?.row_checkbox_disable;
     });
     const enable = this.tableRowData?.some((x:any)=>x.select === true);
     this.BulkAction.emit(enable);
     this.selectedBulkAction(true)
  }

  selectedBulkAction(trackEmit?:boolean){
    const list = this.tableRowData.filter((o: any) => o?.select);
    this._datasServices.bulkAction = list;
    if(trackEmit)
    return;  
    else if(!trackEmit && (list?.length == 1 || list?.length == 0))
    this.BulkAction.emit(list?.length ? true : false);  
  }

  
   unSelectedRowCheckbox(){
    this.tableCustomActionSelectAll.model = false;
    this.toggleHeaderRowCheckbox()
   }
  
  private initialPagination(currentPageNo:number = 1) {
    /* Page Options */
    this.pageSize = this._tableSerivce.pageSize || (this.tablePageSizeOptions ? this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow : this.tableTotalRecords);
    this.currentPage = this.tablePageSizeOptions ? currentPageNo : this.tableTotalRecords;

    /* below will set values to respective variable available in table service to used to update serial number count */
    this._tableSerivce.pageSize = this.pageSize;
    this._tableSerivce.currentPage = 1;
   // this._common.fetchPermssions();
    /* below timeout is for tableTotalRecords value delayed coz of that it return undefine on initiation */
    setTimeout(() => {
      this.lastPage = this.getLastPage();
    }, 1000);

  };

  protected selectedValue(data: any) {
    this.resetTableTotalRecordsCnt();
    /* Below function is for when page size is greater than total records it will set 
    the current page to 1 so we can sort empty table problem when we where middle of page */
    if (this.tableTotalRecords <= data?.target?.value) { 
      this.customTablePagination.setCurrent(1) 
    }
    this.pageSize = data?.target?.value;
    /* below will set values to respective variable available in table service to used to update serial number count */
    this._tableSerivce.currentPage = this.currentPage;
    this._tableSerivce.pageSize = this.pageSize;
    console.log('selectedValue: ', data, this.currentPage, this.tableTotalRecords)
    this.pageChange.emit(this.getOffsetValues());
  }
  

  protected pageChanged(evt: any) {
    this.currentPage = evt;
    this.tableCustomActionSelectAll.model = false;
    this.resetTableTotalRecordsCnt();
    
    this.setCurrentActivePageNo(this.currentPage);
    /* below will set values to respective variable available in table service to used to update serial number count */
    this._tableSerivce.currentPage = evt;
    if (!this.clientSideValidation) {
      this.currentComponentAction = true;
      this.pageChange.emit(this.getOffsetValues());
    }
  }

  protected customSelectedValue(o:any){
    this.pageSize = !o?.customPagesize ? this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.customSize:this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
    this.selectedpageSize = !o?.customPagesize ? this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.customSize:this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
    this._tableSerivce.currentPage = this.currentPage;
    this._tableSerivce.pageSize = this.pageSize;
    this.pageChange.emit(this.getOffsetValues());
    this.assignPageSize = false;
  }

  private getOffsetValues() { 
    let resp = { startValue: (this.currentPage - 1) * this.pageSize, endValue: this.currentPage * this.pageSize }; 
    if(this.tableTotalRecords && resp.endValue > this.tableTotalRecords) {
      let newCurrentPage = Math.ceil(this.tableTotalRecords/this.pageSize);
      let newEndValue = newCurrentPage*this.pageSize;
      let newStartValue = newEndValue - this.pageSize;
      this._tableSerivce.currentPage = newCurrentPage;
      this.currentPage = newCurrentPage;
      resp = { startValue : newStartValue, endValue: newEndValue};
      // console.log('getOffsetValues newEndValue:::: ',newEndValue, 'newStartValue: ',newStartValue, this.currentPage, 'pageSize: ', this.pageSize, 'resp: ', resp, 'tableTotalRecords', this.tableTotalRecords)
    }
    // console.log('getOffsetValues:::: ', this.currentPage, 'pageSize: ', this.pageSize, 'resp: ', resp, 'tableTotalRecords', this.tableTotalRecords)
    return resp;
  }

  protected getLastPage(): number {
    const numberOfPages = Math.floor(this.tableTotalRecords / this.pageSize);
    return this.tableTotalRecords % this.pageSize === 0 ? numberOfPages : numberOfPages + 1;
  }

  get shouldEnableLastPage(): boolean {
    return !this.customTablePagination?.isLastPage() && this.tableTotalRecords > this.pageSize * 2;
  }

  get shouldEnableFirstPage(): boolean {
    return !this.customTablePagination?.isFirstPage() && this.tableTotalRecords > this.pageSize * 2;
  }

  protected sortColumn(itemIndex: any, item: any) {
    let sortingCol:boolean = !this.sortingColumns[itemIndex]
    this.sortingColumns = new Array(this.objEntries(this.columnDefs).length).fill(false);
    this.sortingColumns[itemIndex] = sortingCol
    let param = item[1]?.column_options?.column_sorting_key ? item[1]?.column_options?.column_sorting_key : item[0];
    this.currentComponentAction = true;
    this.sortChange.emit(this.sortingColumns[itemIndex] ? { 'ordering': param } : { 'ordering': '-' + param })
  }

  protected tableAction(rowData: any, action: any , columnKey: any) {
    let actionData = { Data: rowData, action: action , columnName: columnKey }
    if (action?.mode === 'delete') {
      this.deleteParam.emit(actionData?.action?.path);
    } else {
      if (actionData?.action?.routeStatus === false) {
        this.actionParam.emit(actionData);
      } else {
        this._routerService.navigatePages(`${actionData?.action?.path}`, { queryParams: actionData?.action?.queryParams, queryParamsHandling: actionData?.action?.queryParamsHandling });
      }
    }
  }

  protected tableCustomAction(rowData: any, action: any) {
    let customActionData = { Data: rowData, action: action }
    this.CustomActionParam.emit(customActionData);
  }

  getDropdownKey(columnProperty: any, row: any): string {
    if (!columnProperty?.dropdown_config?.options) return '';
    const selectedOption = columnProperty.dropdown_config.options.find((o:any) => o.value === row[columnProperty[0]]);
    return selectedOption ? selectedOption.key : '';
}
  
  ngOnDestroy(): void {
    this.destory.next();
    this.destory.complete();
  }

  clearDateRange(e:any,columnIndex: number, columnProperty: any): void {
    this.ranges[columnIndex].reset();
    this.onSearchColumn(e, columnIndex, columnProperty);
   
    this.ranges[columnIndex].get('start') 
    this.ranges[columnIndex].get('end');
    
    
  }

}
