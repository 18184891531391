<div class="container-fluid px-0">
    <div class="d-flex flex-row">
        <ng-container
            *ngFor="let breadcrumb of (breadcrumbs$ | async);index as breadCrumbIndex;first as isFirst;even as even;odd as odd;last as isLast;">
            <div class="d-flex justify-content-center align-items-center"
                *ngIf="breadcrumbService?.breadCrumbLength > 1">
                <!-- breadcrumb Label -->
                <small class="fs-14 text-capitalize px-0" *ngIf="breadcrumb?.url && !isLast" [routerLink]="[breadcrumb?.url]"
                [ngClass]="{'bread-crumb1':isLast, 'cursor-pointer fa-custom-light':!isLast,'text-lowercase':breadcrumb?.textcase,'text-capitalize':!breadcrumb?.textcase}">{{breadcrumb?.label}}</small>
                <small class="fs-14 text-capitalize px-0" *ngIf="!breadcrumb?.url || isLast"
                [ngClass]="{'bread-crumb1':isLast, 'cursor-pointer fa-custom-light':!isLast,'text-lowercase':breadcrumb?.textcase,'text-capitalize':!breadcrumb?.textcase}">{{breadcrumb?.label}}</small>
                <!-- breadcrumb arrow -->
                <!-- <img *ngIf="!isLast" class="px-1 right_arrow_img" src="../../../../assets/images/layout/sidenav/icons/next.svg"> -->
                 <span *ngIf="!isLast" class="px-1">/</span>
            </div>
        </ng-container>
    </div>
</div>