import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatElementsModule } from './mat-elements/mat-elements.module';
import { ModalModule } from './modal/modal.module';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { RouterModule } from '@angular/router';
import { CustomTableModule } from './custom-table/custom-table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
// import { CustomImportComponent } from './custom-import/custom-import.component';
// import { BulkActionFooterComponent } from './bulk-action-footer/bulk-action-footer.component';
@NgModule({
  declarations: [
    BreadCrumbComponent,
    // CustomImportComponent,
    // BulkActionFooterComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    CommonModule,
    RouterModule,
    MatElementsModule,
    CustomTableModule,
    MaterialModule,
    NgxPermissionsModule
  ],
  exports: [MatElementsModule,
    CustomTableModule,
    ModalModule,
    BreadCrumbComponent
  // CustomImportComponent,
  // BulkActionFooterComponent
]
})
export class ComponentsModule { }
