import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { EMAIL_ID_REGEX, MOBILE_REGEX, NON_ZERO_CHECK, DATE_FORMAT_REGEX, PASSWORD_REGEX, ONLY_STRING_REGEX,NUMERICWITHDECIMAL, ALPHANUMERIC, SLUG_CHECK, ONLY_NUMBERS_REGEX, MOBILE_9_REGEX } from 'src/app/core/services/utils/constants';
import formtextConfiguration from 'src/assets/branding/form-validation-branding/static-form-validation-configuration.json';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { colorCodes } from 'src/app/core/services/utils/constants';



@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  staticFormBranding: any = (formtextConfiguration as any).default;
  staticText: any = (textConfiguration as any).default;

  constructor(private _snackBar: SnackbarService) { }

  requiredValidationCheck(controlName?: string) {
    return (control: AbstractControl): any => {
      if (!control?.value || ( typeof(control?.value) =="object" && control?.value?.length ==0)){
        return { validation_message: `${controlName} ${this?.staticFormBranding?.form_validation?.required_input_msg ? this?.staticFormBranding?.form_validation?.required_input_msg : 'Required'}` };
      } else {
        return null;
      }
    }
  }

  emailValidationCheck(control: AbstractControl): any {
    if (control?.value) {
      const value = !EMAIL_ID_REGEX.test(control.value);
      return value ? { validation_message: `${this?.staticFormBranding?.form_validation?.email_input_msg ? this?.staticFormBranding?.form_validation?.email_input_msg : 'Enter A Valid Email Address'}` } : null;
    }
  }

  numberValidationcheck(control: AbstractControl): any {
    // if (control.value) {
    //   const value = Number(control.value) ? false : true;
    //   return value ? { validation_message: `${this?.staticFormBranding?.form_validation?.number_input_msg}` || 'Enter Numbers Only' } : null;
    // }
    if (control.value) {
      const value = !ONLY_NUMBERS_REGEX.test(control.value.trim());
      return value ? { validation_message: 'Enter Numbers Only' } : null;
    }
  }
  /* Allow only text validation (it will not allow numbers or special characters) */
  stringValidationcheck(control: AbstractControl): any {
    if (control.value) {
      const value = !ONLY_STRING_REGEX.test(control.value.trim());
      return value ? { validation_message: 'Please Enter Only Alphabetical Letters' } : null;
    }
  }
  
  alphanumericValidationcheck(control: AbstractControl): any {
    if (control.value) {
      const value = !ALPHANUMERIC.test(control.value.trim());
      return value ? { validation_message: 'No Special Characters Allowed' } : null;
    }
  }

  alphanumericWithDecimalValidationcheck(control: AbstractControl): any {
    if (control.value) {
      const value = !NUMERICWITHDECIMAL.test(control.value.trim());
      return value ? { validation_message: 'No Characters Allowed' } : null;
    }
  }

  currencyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return { required: true };
    }

    // You can customize the currency validation logic here
    const currencyRegex = /^(\d+([.,]\d{1,2})?)?$/;

    if (!currencyRegex.test(value)) {
      return { validation_message: 'Enter the currency value' };
    }

    return null;
  }

  fileRegistryNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return { required: true };
    }

    // You can customize the currency validation logic here
    const regex = /^[a-zA-Z0-9\/-]+$/;

    if (!regex.test(value)) {
      return { validation_message: 'Enter the  valid File Registry Number' };
    }

    return null;
  }

  rangeValidationCheck(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      let value:any;
      if (isNaN(control.value)) 
        value = parseFloat(control.value.trim());
      else
        value = control.value
      if (isNaN(value) || value < 1 || value > 100) {
        return { validation_message: 'Value must be between 1 and 100' };
      }
    }
  
    return null;
  }

  apiUrlValidationCheck(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      // Simple validation for demonstration purposes
      const apiUrlRegex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  
      const isValid = apiUrlRegex.test(control.value.trim());
  
      return isValid ? null : { validation_message: 'Invalid API URL format' };
    }
    
    return null;
  }

  slugValidationcheck(control: AbstractControl): any {
    if (control.value) {
      const value = !SLUG_CHECK.test(control.value.trim());
      return value ? { validation_message: 'Enter valid slug value' } : null;
    }
  }

  mobilePatternValidationCheck(Param: any) {
    return (control: AbstractControl): ValidationErrors | any => {
      if (control.value) {
        const value = Param == 9 ? !MOBILE_9_REGEX.test(control.value.trim()) : !MOBILE_REGEX.test(control.value.trim());
        return value ? { validation_message: `Invalid Mobile Number, Must be Exactly ${Param} Digits` } : null;
      }
      return null;
    }
  }

  firstDigitZeroValidationCheck(control: AbstractControl) {
    if (control.value) {
      if (/\s/.test(control.value)) {
        return { validation_message: 'Invalid Format: Value contains spaces' };
      }
      const value = !NON_ZERO_CHECK.test(control.value.trim());
      return value ? { validation_message: `First Digit Should Not Be Zero` } : null;
    }
    return null;
  }

  maxLengthCheck(val: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | any => {
      if (control.value === 0 || Math.sign(control.value) === 0 && control.value.toString().length === val) {
        return { validation_message: `${this?.staticFormBranding?.form_validation?.max_length_check_input_msg?.zero_not_allowed_msg}` || 'Zero Not Allowed' }
      }
      if (control.value && control.value.toString().length < val) {
        return { validation_message: Math.sign(control.value) !== -1 ? `${this?.staticFormBranding?.form_validation?.max_length_check_input_msg?.max_length_msg}` || `Enter A Value Of Max ${val} Digits`  : `${this?.staticFormBranding?.form_validation?.negative_value_input_msg}` || 'Negative Value Are Not Allowed' }
      }
      return null;
    }
  }

  minLengthCheck(val: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | any => {
      if (control.value === 0 || Math.sign(control.value) === 0 && control.value.toString().length === val) {
        return { validation_message: `${this?.staticFormBranding?.form_validation?.max_length_check_input_msg?.zero_not_allowed_msg}` || 'Zero Not Allowed' }
      }
      if (control.value && control.value.toString().length > val) {
        return { validation_message: Math.sign(control.value) !== -1 ? `${this?.staticFormBranding?.form_validation?.min_length_check_input_msg?.max_length_msg}` || `Enter A Value Of Less Than ${val} Digits` : `${this?.staticFormBranding?.form_validation?.negative_value_input_msg}` || 'Negative Value Are Not Allowed' }
      }
      return null;
    }
  }

  minMaxLengthCheck(minVal: number, maxVal: number,customMsg?:string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | any => {
      if (Math.sign(control.value) === -1) {
        return { validation_message: `${this?.staticFormBranding?.form_validation?.max_equal_length_check_input_msg?.negative_value_msg}` || 'Negative Values Are Not Allowed' }
      }
      if (control.value && (control.value.toString().length > maxVal || control.value.toString().length < minVal)) {
        return {validation_message:customMsg?customMsg:this?.staticFormBranding?.form_validation?.max_equal_length_check_input_msg?.max_equal_length_msg?`${this?.staticFormBranding?.form_validation?.max_equal_length_check_input_msg?.max_equal_length_msg}`:`Enter A Value Between ${minVal} To ${maxVal} Characters`}
      }
      return null;
    }
  }
  exactLengthCheck(length: number, customMsg?:string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | any => {
      if (control.value && control.value.toString().length != length) {
        return {validation_message:customMsg?customMsg:`Length must be exactly ${length} Characters`}
      }
      return null;
    }
  }

  confirmPasswordValidationCheck(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): any => {
      const control = formGroup?.controls[controlName];
      const matchingControl = formGroup?.controls[matchingControlName];
      if (matchingControl?.errors && !matchingControl?.errors['confirmedValidator']) {
        return;
      }
      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ validation_message: `${this?.staticFormBranding?.form_validation?.confirm_password_input_msg ? this?.staticFormBranding?.form_validation?.confirm_password_input_msg : "Password Didn't Match"}` });
      } else {
        matchingControl?.setErrors(null);
      }
    }
  }

  hasValueValidationCheck() {
    return (formGroup: FormGroup): any => {
      let hasValue = Object.values(formGroup.controls).some(fc => !!fc.value);
      return !hasValue ? { hasValue: true } : { hasValue: false };
    }
  }

  dateValitionCheck(control: AbstractControl) {
    if (control.value) {
      const value = !DATE_FORMAT_REGEX.test(control?.value?.trim());
      return value ? { validation_message: `${this?.staticFormBranding?.form_validation?.date_input_msg}` || `Date Format Should be DD/MM/YYY` } : null;
    }
    return null;
  }

  passwordValidationCheck(control: AbstractControl) {
    if (control.value) {
      const value = !PASSWORD_REGEX.test(control?.value?.trim());
      return value ? { validation_message: `${this?.staticFormBranding?.form_validation?.password_input_msg ? this?.staticFormBranding?.form_validation?.password_input_msg : 'Invalid Format'}` } : null;
    }
    return null;
  }

  validateOwnershipPercentage(formData: any): boolean {
    // Check if 'ownership_info' exists and is an array
    if (!formData.ownership_info || !Array.isArray(formData.ownership_info)) {
      this._snackBar.loadSnackBar('Invalid ownership info.', colorCodes.WARNING);
      return false;
    }
  
    // Calculate the total ownership percentage
    const totalPercentage = formData.ownership_info.reduce((sum: number, owner: any) => {
      return sum + (parseFloat(owner.ownership_percentage) || 0); // Convert to number
    }, 0);
  
    // Check if the total percentage is 100
    if (totalPercentage !== 100) {
      this._snackBar.loadSnackBar(`Total ownership percentage must equal 100%, but it is ${totalPercentage}%.`, colorCodes.ERROR)
      return false;
    }
  
    // If everything is okay, return true
    return true;
  }

  validOwnershipData(): boolean {
    this._snackBar.loadSnackBar('Please fill ownership details', colorCodes.ERROR);
    return false;

  }
}
