import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadCrumbService } from '../../services/bread-crumb/bread-crumb.service';

@Component({
  selector: 'bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  url: any;
  breadcrumbs$: Observable<any[]> | any;

  constructor(protected breadcrumbService: BreadCrumbService, private router: Router) { }

  ngOnInit(): void { this.initialDependencies() }

  initialDependencies() {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    this.url = this.router.url;
  }

}
