<div class="custom-select-2" [formGroup]="inputSelectFormGroup">
    <mat-label *ngIf="inputSelectLabel" class="color-primary" [ngClass]="removePadding ? 'text-small' : ''">{{
        inputSelectLabel
        }}<sup class="color-red" *ngIf="inputSelectRequiredStatus">&ast;</sup></mat-label>
    <div class="" #mselect>
        <ng-multiselect-dropdown [ngClass]="removePadding ? 'remove-padding' : ''" [disabled]="inputSelectDisableState"
            (click)="onClickDropdown($event)" [formControlName]="inputSelectFormContolName"
            [placeholder]="inputSelectPlaceholderLabel" [settings]="dropdownSettings" [data]="inputSelectArray"
            (onDropDownClose)="onDropDownClose()" (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)"
            (onDeSelectAll)="onDeSelectAll()" (onSelectAll)="onSelectAll($event)"
            [required]="inputSelectRequiredStatus">
        </ng-multiselect-dropdown>
        <custom-error [customErrorLabel]="inputSelectErrorLabel" *ngIf="
        inputSelectFormGroup?.get(inputSelectFormContolName)?.touched &&
        inputSelectFormGroup?.get(inputSelectFormContolName)?.errors
      ">
        </custom-error>
    </div>
</div>