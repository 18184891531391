<div class="custom-autocomplete-jira" *ngIf="inputAutocompleteFormGroup" [formGroup]="inputAutocompleteFormGroup">
    <mat-label *ngIf="inputAutocompleteLabel" class="color-primary" [ngClass]="removePadding ? 'fs-6' : ''">
        {{ inputAutocompleteLabel }}
        <sup class="text-danger" *ngIf="inputAutocompleteRequiredStatus && !requiredIndicator">&ast;</sup>
    </mat-label>

    <div class="d-flex align-items-center position-relative">
        <mat-form-field class="w-100" [appearance]="inputAutocompleteAppearance" [ngClass]="{
        removeInputBottomPadding: inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.touched &&
        inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.errors &&
        !requiredIndicator
      }">
            <span matPrefix *ngIf="inputAutocompletePrefixIcon" class="me-2">
                <i [class]="inputAutocompletePrefixIcon2"
                    *ngIf="inputAutocompletePrefixIcon?.includes('fa'); else prefixImg"></i>
                <ng-template #prefixImg>
                    <img [src]="inputIconImgBasepath + inputAutocompletePrefixIcon + '.svg'" alt="icon" />
                </ng-template>
            </span>
            <input #inputBox type="text" [placeholder]="inputAutocompletePlaceholder" matInput
                [readonly]="inputAutoCompletereadonly" [style.color]="inputAutoCompletereadonlyColor"
                [formControlName]="inputAutocompleteFormControlName" [matAutocomplete]="inputAutoCompleteReference"
                [required]="inputAutocompleteRequiredStatus" (input)="adjustWidth(inputBox)" />
            <span matSuffix class="d-flex align-items-center ml-1 me-3">
                <ng-container *ngIf="inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.value">
                    <span class="clear-icon me-2" (click)="clearValue()">
                        <img src="../../../../../../assets/images/common/icons_usp/close.png" alt="Clear" class="cursor-pointer clear_width" />
                   
                    </span>
                </ng-container>
                <i [class]="inputAutocompleteSuffixIcon2"
                    *ngIf="inputAutocompleteSuffixIcon?.includes('fa'); else suffixImg"></i>
                <ng-template #suffixImg>
                    <img [src]="inputIconImgBasepath + inputAutocompleteSuffixIcon + '.svg'" alt="Dropdown Icon" />
                </ng-template>
            </span>
            <mat-autocomplete #inputAutoCompleteReference="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)="SelectedOption($event)">
                <mat-option *ngFor="let option of filterAutocompleteList | async" [value]="option" [disabled]="inputAutocompleteDisableIndex ? 
                      inputAutocompleteDisableState && inputAutocompleteDisableIndex.includes(optionIndex)
                      : inputAutocompleteDisableState">
                    {{ option?.[inputAutocompleteFilterKey] || option?.name || option }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <custom-error [customErrorLabel]="inputAutoCompleteErrorLabel" *ngIf="
        inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.touched &&
        inputAutocompleteFormGroup?.get(inputAutocompleteFormControlName)?.invalid">
        </custom-error>
    </div>
</div>