import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {

  @Input() inputCheckBoxFormGroup: FormGroup | any;
  @Input() inputCheckBoxFormControlName: any;
  @Input() inputCheckBoxClass: any;
  @Input() inputCheckBoxId: any;
  @Input() inputCheckBoxLabel: any;
  @Input() inputCheckBoxValue: any;
  @Input() inputCheckBoxDisableState: boolean = false;
  @Input() inputCheckBoxColor: any = 'accent';
  @Input() inputCheckBoxRequiredStatus: boolean = false;
  @Input() CheckedValue: boolean = false;
  @Input() inputCheckBoxIconStatus: boolean = false;
  @Input() displayLabelEnd: boolean = false;
  @Input() inputCheckBoxErrorIconName!: string;
  @Input() inputCheckBoxIconPrefixPath: string = "/assets/images/common/icons/";
  @Output() checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('checkbox') checkbox!: MatCheckbox;


  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const value = this.inputCheckBoxFormGroup.get(this.inputCheckBoxFormControlName)?.value;
    this.fieldsChange({checked : value});
  }
  

  fieldsChange(values: any): void {
    console.log('Checkbox changed:', values);
    this.checkboxChange.emit(values.checked);
  }

  toggleCheckbox(): void {
    if (!this.checkbox.disabled) {
        this.checkbox.checked = !this.checkbox.checked;
        this.fieldsChange({ checked: this.checkbox.checked }); // Emit the new state if needed
    }
}

}
