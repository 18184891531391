import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatElementsModule } from '../mat-elements/mat-elements.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTableFilterComponent } from './custom-table-filter/custom-table-filter.component';
import { MaterialModule } from '../../material/material.module';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '../../pipes/pipes.module';
import { CustomTableComponent } from './custom-table.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    CustomTableComponent,
    CustomTableFilterComponent,
  ],
  imports: [
    FormsModule,
    PipesModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatElementsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgbModule,
    NgbPopoverModule
    // PerfectScrollbarModule
  ],
  exports: [CustomTableComponent, CustomTableFilterComponent]
})
export class CustomTableModule { }
