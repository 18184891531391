import * as i0 from '@angular/core';
import { EventEmitter, Component, Output, Input, Injectable, ContentChildren, ContentChild, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
const _c0 = ["*"];
function Ng2DropdownButton_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 4);
    i0.ɵɵelement(2, "path", 5)(3, "g")(4, "g")(5, "g")(6, "g")(7, "g")(8, "g");
    i0.ɵɵelementEnd()();
  }
}
function Ng2DropdownMenu_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵlistener("click", function Ng2DropdownMenu_div_3_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.hide());
    });
    i0.ɵɵelementEnd();
  }
}
const _c1 = [[["ng2-dropdown-button"]], [["ng2-dropdown-menu"]]];
const _c2 = ["ng2-dropdown-button", "ng2-dropdown-menu"];
class Ng2DropdownButton {
  constructor(element) {
    this.element = element;
    this.onMenuToggled = new EventEmitter();
    this.showCaret = true;
  }
  /**
   * @name toggleMenu
   * @desc emits event to toggle menu
   */
  toggleMenu() {
    this.onMenuToggled.emit(true);
  }
  /**
   * @name getPosition
   * @desc returns position of the button
   */
  getPosition() {
    return this.element.nativeElement.getBoundingClientRect();
  }
}
Ng2DropdownButton.ɵfac = function Ng2DropdownButton_Factory(ɵt) {
  return new (ɵt || Ng2DropdownButton)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
Ng2DropdownButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Ng2DropdownButton,
  selectors: [["ng2-dropdown-button"]],
  inputs: {
    showCaret: "showCaret"
  },
  outputs: {
    onMenuToggled: "onMenuToggled"
  },
  ngContentSelectors: _c0,
  decls: 4,
  vars: 1,
  consts: [["type", "button", "tabindex", "0s", 1, "ng2-dropdown-button", 3, "click"], [1, "ng2-dropdown-button__label"], ["class", "ng2-dropdown-button__caret", 4, "ngIf"], [1, "ng2-dropdown-button__caret"], ["enable-background", "new 0 0 32 32", "height", "16px", "id", "\u0421\u043B\u043E\u0439_1", "version", "1.1", "viewBox", "0 0 32 32", "width", "16px", 0, "xml", "space", "preserve", "xmlns", "http://www.w3.org/2000/svg", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink"], ["d", "M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z", "fill", "#121313", "id", "Expand_More"]],
  template: function Ng2DropdownButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function Ng2DropdownButton_Template_button_click_0_listener() {
        return ctx.toggleMenu();
      });
      i0.ɵɵelementStart(1, "span", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, Ng2DropdownButton_span_3_Template, 9, 0, "span", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.showCaret);
    }
  },
  dependencies: [i1.NgIf],
  styles: [".ng2-dropdown-button[_ngcontent-%COMP%]{font-family:Roboto,Helvetica Neue,Helvetica,Arial;background:#fff;padding:.45rem .25rem;font-size:14px;letter-spacing:.08rem;color:#444;outline:0;cursor:pointer;font-weight:400;border:none;border-bottom:1px solid #efefef;text-align:left;min-width:100px;width:100%;display:flex;flex-direction:row;max-width:150px}.ng2-dropdown-button[_ngcontent-%COMP%]:hover{color:#222}.ng2-dropdown-button[_ngcontent-%COMP%]:active, .ng2-dropdown-button[_ngcontent-%COMP%]:focus{color:#222;border-bottom:2px solid #2196F3}.ng2-dropdown-button__label[_ngcontent-%COMP%]{flex:1 1 95%}.ng2-dropdown-button__caret[_ngcontent-%COMP%]{width:12px;height:12px;display:flex;flex:1 1 6%}.ng2-dropdown-button--icon[_nghost-%COMP%]   .ng2-dropdown-button[_ngcontent-%COMP%], .ng2-dropdown-button--icon   [_nghost-%COMP%]   .ng2-dropdown-button[_ngcontent-%COMP%]{border:none;min-width:40px;width:40px;border-radius:100%;transition:all .2s;text-align:center;height:40px;padding:.5em}.ng2-dropdown-button--icon[_nghost-%COMP%]   .ng2-dropdown-button[_ngcontent-%COMP%]:active, .ng2-dropdown-button--icon   [_nghost-%COMP%]   .ng2-dropdown-button[_ngcontent-%COMP%]:active{background:rgba(0,0,0,.2)}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2DropdownButton, [{
    type: Component,
    args: [{
      selector: 'ng2-dropdown-button',
      template: "<button class='ng2-dropdown-button' type=\"button\" (click)=\"toggleMenu()\" tabindex=\"0s\">\n    <span class=\"ng2-dropdown-button__label\">\n        <ng-content></ng-content>\n    </span>\n\n    <span class=\"ng2-dropdown-button__caret\" *ngIf=\"showCaret\">\n        <svg enable-background=\"new 0 0 32 32\" height=\"16px\" id=\"\u0421\u043B\u043E\u0439_1\" version=\"1.1\" viewBox=\"0 0 32 32\" width=\"16px\" xml:space=\"preserve\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"><path d=\"M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z\" fill=\"#121313\" id=\"Expand_More\"/><g/><g/><g/><g/><g/><g/></svg>\n    </span>\n</button>\n",
      styles: [".ng2-dropdown-button{font-family:Roboto,Helvetica Neue,Helvetica,Arial;background:#fff;padding:.45rem .25rem;font-size:14px;letter-spacing:.08rem;color:#444;outline:0;cursor:pointer;font-weight:400;border:none;border-bottom:1px solid #efefef;text-align:left;min-width:100px;width:100%;display:flex;flex-direction:row;max-width:150px}.ng2-dropdown-button:hover{color:#222}.ng2-dropdown-button:active,.ng2-dropdown-button:focus{color:#222;border-bottom:2px solid #2196F3}.ng2-dropdown-button__label{flex:1 1 95%}.ng2-dropdown-button__caret{width:12px;height:12px;display:flex;flex:1 1 6%}:host-context(.ng2-dropdown-button--icon) .ng2-dropdown-button{border:none;min-width:40px;width:40px;border-radius:100%;transition:all .2s;text-align:center;height:40px;padding:.5em}:host-context(.ng2-dropdown-button--icon) .ng2-dropdown-button:active{background:rgba(0,0,0,.2)}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    onMenuToggled: [{
      type: Output
    }],
    showCaret: [{
      type: Input
    }]
  });
})();
const KEYS = {
  BACKSPACE: 9,
  PREV: 38,
  NEXT: 40,
  ENTER: 13,
  ESCAPE: 27
};
/**
 * @name onSwitchNext
 * @param index
 * @param items
 * @param state
 */
const onSwitchNext = (index, items, state) => {
  if (index < items.length - 1) {
    state.select(items[index + 1], true);
  }
};
/**
 * @name onSwitchPrev
 * @param index
 * @param items
 * @param state
 */
const onSwitchPrev = (index, items, state) => {
  if (index > 0) {
    state.select(items[index - 1], true);
  }
};
/**
 * @name onBackspace
 * @param index
 * @param items
 * @param state
 */
const onBackspace = (index, items, state) => {
  if (index < items.length - 1) {
    state.select(items[index + 1], true);
  } else {
    state.select(items[0], true);
  }
};
function onEscape() {
  this.hide();
}
;
/**
 * @name onItemClicked
 * @param index
 * @param items
 * @param state
 */
const onItemClicked = (index, items, state) => {
  return state.selectedItem ? state.selectedItem.click() : undefined;
};
const ACTIONS = {
  [KEYS.BACKSPACE]: onBackspace,
  [KEYS.PREV]: onSwitchPrev,
  [KEYS.NEXT]: onSwitchNext,
  [KEYS.ENTER]: onItemClicked,
  [KEYS.ESCAPE]: onEscape
};
function arrowKeysHandler(event) {
  if ([38, 40].indexOf(event.keyCode) > -1) {
    event.preventDefault();
  }
}
class Ng2DropdownState {
  constructor() {
    this.onItemSelected = new EventEmitter();
    this.onItemClicked = new EventEmitter();
    this.onItemDestroyed = new EventEmitter();
  }
  /**
   * @name selectedItem
   * @desc getter for _selectedItem
   */
  get selectedItem() {
    return this._selectedItem;
  }
  /**
   * @name selects a menu item and emits event
   * @param item
   */
  select(item, dispatchEvent = true) {
    this._selectedItem = item;
    if (!dispatchEvent || !item) {
      return;
    }
    item.focus();
    this.onItemSelected.emit(item);
  }
  /**
   * @name unselect
   * @desc sets _selectedItem as undefined
   */
  unselect() {
    this._selectedItem = undefined;
  }
}
class DropdownStateService {
  constructor() {
    this.menuState = {
      isVisible: false,
      toString() {
        return this.isVisible === true ? 'visible' : 'hidden';
      }
    };
    this.dropdownState = new Ng2DropdownState();
  }
}
DropdownStateService.ɵfac = function DropdownStateService_Factory(ɵt) {
  return new (ɵt || DropdownStateService)();
};
DropdownStateService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DropdownStateService,
  factory: DropdownStateService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropdownStateService, [{
    type: Injectable
  }], null, null);
})();
class Ng2MenuItem {
  constructor(state, element) {
    this.state = state;
    this.element = element;
    /**
     * @preventClose
     * @desc if true, clicking on the item won't close the dropdown
     */
    this.preventClose = false;
  }
  ngOnDestroy() {
    this.state.dropdownState.onItemDestroyed.emit(this);
  }
  /**
   * @name isSelected
   * @desc returns current selected item
   */
  get isSelected() {
    return this === this.state.dropdownState.selectedItem;
  }
  /**
   * @name click
   * @desc emits select event
   */
  select($event) {
    this.state.dropdownState.select(this, true);
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }
  /**
   * @name click
   * @desc emits click event
   */
  click() {
    this.state.dropdownState.onItemClicked.emit(this);
  }
  /**
   * @name focus
   */
  focus() {
    this.element.nativeElement.children[0].focus();
  }
}
Ng2MenuItem.ɵfac = function Ng2MenuItem_Factory(ɵt) {
  return new (ɵt || Ng2MenuItem)(i0.ɵɵdirectiveInject(DropdownStateService), i0.ɵɵdirectiveInject(i0.ElementRef));
};
Ng2MenuItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Ng2MenuItem,
  selectors: [["ng2-menu-item"]],
  inputs: {
    preventClose: "preventClose",
    value: "value"
  },
  ngContentSelectors: _c0,
  decls: 2,
  vars: 2,
  consts: [["role", "button", "tabindex", "0", 1, "ng2-menu-item", 3, "keydown.enter", "click", "mouseover"]],
  template: function Ng2MenuItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("keydown.enter", function Ng2MenuItem_Template_div_keydown_enter_0_listener() {
        return ctx.click();
      })("click", function Ng2MenuItem_Template_div_click_0_listener() {
        return ctx.click();
      })("mouseover", function Ng2MenuItem_Template_div_mouseover_0_listener() {
        return ctx.select();
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassProp("ng2-menu-item--selected", ctx.isSelected);
    }
  },
  styles: [".ng2-menu-item[_ngcontent-%COMP%]{font-family:Roboto,Helvetica Neue,Helvetica,Arial;background:#fff;color:#000000de;cursor:pointer;font-size:.9em;text-transform:none;font-weight:400;letter-spacing:.03em;height:48px;line-height:48px;padding:.3em 1.25rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;transition:background .25s}.ng2-menu-item--selected[_ngcontent-%COMP%]{background:rgba(158,158,158,.2);outline:0}.ng2-menu-item[_ngcontent-%COMP%]:focus{outline:0}.ng2-menu-item[_ngcontent-%COMP%]:active{background:rgba(158,158,158,.4)}ng2-menu-item[_nghost-%COMP%]     [ng2-menu-item-icon]{vertical-align:middle;font-size:28px;width:1.5em;height:30px;color:#00000070}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2MenuItem, [{
    type: Component,
    args: [{
      selector: 'ng2-menu-item',
      template: `
        <div
            class="ng2-menu-item"
            role="button"
            tabindex="0"
            [class.ng2-menu-item--selected]="isSelected"
            (keydown.enter)="click()"
            (click)="click()"
            (mouseover)="select()"
        >
            <ng-content></ng-content>
        </div>
    `,
      styles: [".ng2-menu-item{font-family:Roboto,Helvetica Neue,Helvetica,Arial;background:#fff;color:#000000de;cursor:pointer;font-size:.9em;text-transform:none;font-weight:400;letter-spacing:.03em;height:48px;line-height:48px;padding:.3em 1.25rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;transition:background .25s}.ng2-menu-item--selected{background:rgba(158,158,158,.2);outline:0}.ng2-menu-item:focus{outline:0}.ng2-menu-item:active{background:rgba(158,158,158,.4)}:host(ng2-menu-item) ::ng-deep [ng2-menu-item-icon]{vertical-align:middle;font-size:28px;width:1.5em;height:30px;color:#00000070}\n"]
    }]
  }], function () {
    return [{
      type: DropdownStateService
    }, {
      type: i0.ElementRef
    }];
  }, {
    preventClose: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();
class Ng2DropdownMenu {
  constructor(dropdownState, element, renderer) {
    this.dropdownState = dropdownState;
    this.element = element;
    this.renderer = renderer;
    /**
     * @name width
     */
    this.width = 4;
    /**
     * @description if set to true, the first element of the dropdown will be automatically focused
     * @name focusFirstElement
     */
    this.focusFirstElement = true;
    /**
     * @name appendToBody
     */
    this.appendToBody = true;
    /**
     * @name zIndex
     */
    this.zIndex = 1000;
    this.listeners = {
      arrowHandler: undefined,
      handleKeypress: undefined
    };
  }
  /**
   * @name show
   * @shows menu and selects first item
   */
  show(position, dynamic = true) {
    const dc = typeof document !== 'undefined' ? document : undefined;
    const wd = typeof window !== 'undefined' ? window : undefined;
    if (!this.dropdownState.menuState.isVisible) {
      // setting handlers
      this.listeners.handleKeypress = this.renderer.listen(dc.body, 'keydown', this.handleKeypress.bind(this));
      this.listeners.arrowHandler = this.renderer.listen(wd, 'keydown', arrowKeysHandler);
    }
    // update state
    this.dropdownState.menuState.isVisible = true;
    if (position) {
      this.updatePosition(position, dynamic);
    }
  }
  /**
   * @name hide
   * @desc hides menu
   */
  hide() {
    this.dropdownState.menuState.isVisible = false;
    // reset selected item state
    this.dropdownState.dropdownState.unselect();
    // call function to unlisten
    this.listeners.arrowHandler && this.listeners.arrowHandler();
    this.listeners.handleKeypress && this.listeners.handleKeypress();
  }
  /**
   * @name updatePosition
   * @desc updates the menu position every time it is toggled
   * @param position {ClientRect}
   * @param dynamic {boolean}
   */
  updatePosition(position, dynamic) {
    this.position = position;
    this.updateOnChange(dynamic);
  }
  /**
   * @name handleKeypress
   * @desc executes functions on keyPress based on the key pressed
   * @param $event
   */
  handleKeypress($event) {
    const key = $event.keyCode;
    const items = this.items.toArray();
    const index = items.indexOf(this.dropdownState.dropdownState.selectedItem);
    if (!ACTIONS.hasOwnProperty(key)) {
      return;
    }
    ACTIONS[key].call(this, index, items, this.dropdownState.dropdownState);
  }
  /**
   * @name getMenuElement
   */
  getMenuElement() {
    return this.element.nativeElement.children[0];
  }
  /**
   * @name calcPositionOffset
   * @param position
   */
  calcPositionOffset(position) {
    const wd = typeof window !== 'undefined' ? window : undefined;
    const dc = typeof document !== 'undefined' ? document : undefined;
    if (!wd || !dc || !position) {
      return;
    }
    const element = this.getMenuElement();
    const supportPageOffset = wd.pageXOffset !== undefined;
    const isCSS1Compat = (dc.compatMode || '') === 'CSS1Compat';
    const x = supportPageOffset ? wd.pageXOffset : isCSS1Compat ? dc.documentElement.scrollLeft : dc.body.scrollLeft;
    const y = supportPageOffset ? wd.pageYOffset : isCSS1Compat ? dc.documentElement.scrollTop : dc.body.scrollTop;
    let {
      top,
      left
    } = this.applyOffset(`${position.top + (this.appendToBody ? y - 15 : 0)}px`, `${position.left + x - 5}px`);
    const clientWidth = element.clientWidth;
    const clientHeight = element.clientHeight;
    const marginFromBottom = parseInt(top) + clientHeight + (this.appendToBody ? 0 : y - 15);
    const marginFromRight = parseInt(left) + clientWidth;
    const windowScrollHeight = wd.innerHeight + wd.scrollY;
    const windowScrollWidth = wd.innerWidth + wd.scrollX;
    if (marginFromBottom >= windowScrollHeight) {
      top = `${parseInt(top.replace('px', '')) - clientHeight}px`;
    }
    if (marginFromRight >= windowScrollWidth) {
      const marginRight = marginFromRight - windowScrollWidth + 30;
      left = `${parseInt(left.replace('px', '')) - marginRight}px`;
    }
    return {
      top,
      left
    };
  }
  applyOffset(top, left) {
    if (!this.offset) {
      return {
        top,
        left
      };
    }
    const offset = this.offset.split(' ');
    if (!offset[1]) {
      offset[1] = '0';
    }
    top = `${parseInt(top.replace('px', '')) + parseInt(offset[0])}px`;
    left = `${parseInt(left.replace('px', '')) + parseInt(offset[1])}px`;
    return {
      top,
      left
    };
  }
  ngOnInit() {
    const dc = typeof document !== 'undefined' ? document : undefined;
    if (this.appendToBody) {
      // append menu element to the body
      dc.body.appendChild(this.element.nativeElement);
    }
  }
  updateOnChange(dynamic = true) {
    const element = this.getMenuElement();
    const position = this.calcPositionOffset(this.position);
    if (position) {
      this.renderer.setStyle(element, 'top', position.top.toString());
      this.renderer.setStyle(element, 'left', position.left.toString());
    }
    // select first item unless user disabled this option
    if (this.focusFirstElement && this.items.first && !this.dropdownState.dropdownState.selectedItem) {
      this.dropdownState.dropdownState.select(this.items.first, false);
    }
  }
  ngOnDestroy() {
    const elem = this.element.nativeElement;
    elem.parentNode.removeChild(elem);
    if (this.listeners.handleKeypress) {
      this.listeners.handleKeypress();
    }
  }
}
Ng2DropdownMenu.ɵfac = function Ng2DropdownMenu_Factory(ɵt) {
  return new (ɵt || Ng2DropdownMenu)(i0.ɵɵdirectiveInject(DropdownStateService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
Ng2DropdownMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Ng2DropdownMenu,
  selectors: [["ng2-dropdown-menu"]],
  contentQueries: function Ng2DropdownMenu_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, Ng2MenuItem, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
    }
  },
  inputs: {
    width: "width",
    focusFirstElement: "focusFirstElement",
    offset: "offset",
    appendToBody: "appendToBody",
    zIndex: "zIndex"
  },
  ngContentSelectors: _c0,
  decls: 4,
  vars: 12,
  consts: [[1, "ng2-dropdown-menu__options-container"], ["class", "ng2-dropdown-backdrop", 3, "click", 4, "ngIf"], [1, "ng2-dropdown-backdrop", 3, "click"]],
  template: function Ng2DropdownMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div")(1, "div", 0);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(3, Ng2DropdownMenu_div_3_Template, 1, 0, "div", 1);
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("ng2-dropdown-menu ng2-dropdown-menu---width--", ctx.width, "");
      i0.ɵɵstyleProp("z-index", ctx.zIndex);
      i0.ɵɵclassProp("ng2-dropdown-menu--inside-element", !ctx.appendToBody)("ng2-dropdown-menu--open", ctx.dropdownState.menuState.isVisible);
      i0.ɵɵproperty("@fade", ctx.dropdownState.menuState.toString());
      i0.ɵɵadvance();
      i0.ɵɵproperty("@opacity", ctx.dropdownState.menuState.toString());
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.dropdownState.menuState.isVisible);
    }
  },
  dependencies: [i1.NgIf],
  styles: ["[_nghost-%COMP%]{display:block}.ng2-dropdown-menu[_ngcontent-%COMP%]{overflow-y:auto;box-shadow:0 1px 2px #0000004d;position:absolute;padding:.5em 0;background:#fff;border-radius:1px;max-height:400px;width:260px;min-height:0;display:block}.ng2-dropdown-menu.ng2-dropdown-menu--inside-element[_ngcontent-%COMP%]{position:fixed}.ng2-dropdown-menu.ng2-dropdown-menu--width--2[_ngcontent-%COMP%]{width:200px}.ng2-dropdown-menu.ng2-dropdown-menu--width--4[_ngcontent-%COMP%]{width:260px}.ng2-dropdown-menu.ng2-dropdown-menu--width--6[_ngcontent-%COMP%]{width:320px}.ng2-dropdown-backdrop[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1;overflow:hidden}[_nghost-%COMP%]     .ng2-menu-divider{height:1px;min-height:1px;max-height:1px;width:100%;display:block;background:#f9f9f9}"],
  data: {
    animation: [trigger('fade', [state('visible', style({
      opacity: 1,
      height: '*',
      width: '*'
    })), state('hidden', style({
      opacity: 0,
      overflow: 'hidden',
      height: 0,
      width: 0
    })), transition('hidden => visible', [animate('250ms ease-in', style({
      opacity: 1,
      height: '*',
      width: '*'
    }))]), transition('visible => hidden', [animate('350ms ease-out', style({
      opacity: 0,
      width: 0,
      height: 0
    }))])]), trigger('opacity', [transition('hidden => visible', [animate('450ms ease-in', keyframes([style({
      opacity: 0,
      offset: 0
    }), style({
      opacity: 1,
      offset: 1
    })]))]), transition('visible => hidden', [animate('250ms ease-out', keyframes([style({
      opacity: 1,
      offset: 0
    }), style({
      opacity: 0.5,
      offset: 0.3
    }), style({
      opacity: 0,
      offset: 1
    })]))])])]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2DropdownMenu, [{
    type: Component,
    args: [{
      selector: 'ng2-dropdown-menu',
      template: `
        <!-- MENU -->
        <div
            class="ng2-dropdown-menu ng2-dropdown-menu---width--{{ width }}"
            [class.ng2-dropdown-menu--inside-element]="!appendToBody"
            [class.ng2-dropdown-menu--open]="dropdownState.menuState.isVisible"
            [style.z-index]="zIndex"
            [@fade]="dropdownState.menuState.toString()"
        >
            <div
                class="ng2-dropdown-menu__options-container"
                [@opacity]="dropdownState.menuState.toString()"
            >
                <ng-content></ng-content>
            </div>
        </div>

        <!-- BACKDROP -->
        <div
            class="ng2-dropdown-backdrop"
            *ngIf="dropdownState.menuState.isVisible"
            (click)="hide()"
        ></div>
    `,
      animations: [trigger('fade', [state('visible', style({
        opacity: 1,
        height: '*',
        width: '*'
      })), state('hidden', style({
        opacity: 0,
        overflow: 'hidden',
        height: 0,
        width: 0
      })), transition('hidden => visible', [animate('250ms ease-in', style({
        opacity: 1,
        height: '*',
        width: '*'
      }))]), transition('visible => hidden', [animate('350ms ease-out', style({
        opacity: 0,
        width: 0,
        height: 0
      }))])]), trigger('opacity', [transition('hidden => visible', [animate('450ms ease-in', keyframes([style({
        opacity: 0,
        offset: 0
      }), style({
        opacity: 1,
        offset: 1
      })]))]), transition('visible => hidden', [animate('250ms ease-out', keyframes([style({
        opacity: 1,
        offset: 0
      }), style({
        opacity: 0.5,
        offset: 0.3
      }), style({
        opacity: 0,
        offset: 1
      })]))])])],
      styles: [":host{display:block}.ng2-dropdown-menu{overflow-y:auto;box-shadow:0 1px 2px #0000004d;position:absolute;padding:.5em 0;background:#fff;border-radius:1px;max-height:400px;width:260px;min-height:0;display:block}.ng2-dropdown-menu.ng2-dropdown-menu--inside-element{position:fixed}.ng2-dropdown-menu.ng2-dropdown-menu--width--2{width:200px}.ng2-dropdown-menu.ng2-dropdown-menu--width--4{width:260px}.ng2-dropdown-menu.ng2-dropdown-menu--width--6{width:320px}.ng2-dropdown-backdrop{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1;overflow:hidden}:host ::ng-deep .ng2-menu-divider{height:1px;min-height:1px;max-height:1px;width:100%;display:block;background:#f9f9f9}\n"]
    }]
  }], function () {
    return [{
      type: DropdownStateService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    width: [{
      type: Input
    }],
    focusFirstElement: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    appendToBody: [{
      type: Input
    }],
    zIndex: [{
      type: Input
    }],
    items: [{
      type: ContentChildren,
      args: [Ng2MenuItem, {
        descendants: true
      }]
    }]
  });
})();
class Ng2Dropdown {
  constructor(state) {
    this.state = state;
    this.dynamicUpdate = true;
    // outputs
    this.onItemClicked = new EventEmitter();
    this.onItemSelected = new EventEmitter();
    this.onShow = new EventEmitter();
    this.onHide = new EventEmitter();
  }
  ngOnInit() {
    this.state.dropdownState.onItemClicked.subscribe(item => {
      this.onItemClicked.emit(item);
      if (item.preventClose) {
        return;
      }
      this.hide.call(this);
    });
    if (this.button) {
      this.button.onMenuToggled.subscribe(() => {
        this.toggleMenu();
      });
    }
    this.state.dropdownState.onItemSelected.subscribe(item => {
      this.onItemSelected.emit(item);
    });
    this.state.dropdownState.onItemDestroyed.subscribe(item => {
      let newSelectedItem;
      const items = this.menu.items.toArray();
      if (item !== this.state.dropdownState.selectedItem) {
        return;
      }
      if (this.menu.focusFirstElement) {
        newSelectedItem = item === items[0] && items.length > 1 ? items[1] : items[0];
      }
      this.state.dropdownState.select(newSelectedItem);
    });
  }
  /**
   * @name toggleMenu
   * @desc toggles menu visibility
   */
  toggleMenu(position = this.button.getPosition()) {
    this.state.menuState.isVisible ? this.hide() : this.show(position);
  }
  /**
   * - hides dropdown
   * @name hide
   */
  hide() {
    this.menu.hide();
    this.onHide.emit(this);
  }
  /**
   * - shows dropdown
   * @name show
   * @param position
   */
  show(position = this.button.getPosition()) {
    this.menu.show(position, this.dynamicUpdate);
    this.onShow.emit(this);
  }
  /**
   * @name scrollListener
   */
  scrollListener() {
    if (this.button && this.dynamicUpdate) {
      this.menu.updatePosition(this.button.getPosition(), true);
    }
  }
}
Ng2Dropdown.ɵfac = function Ng2Dropdown_Factory(ɵt) {
  return new (ɵt || Ng2Dropdown)(i0.ɵɵdirectiveInject(DropdownStateService));
};
Ng2Dropdown.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Ng2Dropdown,
  selectors: [["ng2-dropdown"]],
  contentQueries: function Ng2Dropdown_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, Ng2DropdownButton, 7);
      i0.ɵɵcontentQuery(dirIndex, Ng2DropdownMenu, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.button = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.menu = _t.first);
    }
  },
  hostBindings: function Ng2Dropdown_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("scroll", function Ng2Dropdown_scroll_HostBindingHandler() {
        return ctx.scrollListener();
      }, false, i0.ɵɵresolveWindow);
    }
  },
  inputs: {
    dynamicUpdate: "dynamicUpdate"
  },
  outputs: {
    onItemClicked: "onItemClicked",
    onItemSelected: "onItemSelected",
    onShow: "onShow",
    onHide: "onHide"
  },
  features: [i0.ɵɵProvidersFeature([DropdownStateService])],
  ngContentSelectors: _c2,
  decls: 3,
  vars: 0,
  consts: [[1, "ng2-dropdown-container"]],
  template: function Ng2Dropdown_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c1);
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵprojection(2, 1);
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2Dropdown, [{
    type: Component,
    args: [{
      selector: 'ng2-dropdown',
      template: `
        <div class="ng2-dropdown-container">
            <ng-content select="ng2-dropdown-button"></ng-content>
            <ng-content select="ng2-dropdown-menu"></ng-content>
        </div>
    `,
      providers: [DropdownStateService]
    }]
  }], function () {
    return [{
      type: DropdownStateService
    }];
  }, {
    button: [{
      type: ContentChild,
      args: [Ng2DropdownButton, {
        static: true
      }]
    }],
    menu: [{
      type: ContentChild,
      args: [Ng2DropdownMenu, {
        static: true
      }]
    }],
    dynamicUpdate: [{
      type: Input
    }],
    onItemClicked: [{
      type: Output
    }],
    onItemSelected: [{
      type: Output
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    scrollListener: [{
      type: HostListener,
      args: ['window:scroll']
    }]
  });
})();
class Ng2DropdownModule {}
Ng2DropdownModule.ɵfac = function Ng2DropdownModule_Factory(ɵt) {
  return new (ɵt || Ng2DropdownModule)();
};
Ng2DropdownModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: Ng2DropdownModule
});
Ng2DropdownModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ng2DropdownModule, [{
    type: NgModule,
    args: [{
      exports: [Ng2MenuItem, Ng2DropdownButton, Ng2DropdownMenu, Ng2Dropdown],
      declarations: [Ng2Dropdown, Ng2MenuItem, Ng2DropdownButton, Ng2DropdownMenu],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DropdownStateService, Ng2Dropdown, Ng2DropdownButton, Ng2DropdownMenu, Ng2DropdownModule, Ng2MenuItem };
