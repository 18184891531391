<div class="padding_file  file_border" [ngClass]="{'disable-file-upload': disableFileUpload}">
    <div class='file-input'>
        <ng-container>
            <input type='file' [disabled]="disableFileUpload" #fileChooser1 class="file-chooser"
                [accept]="inputFileAcceptExtenstions" name="fieldValue" (change)="onSelectFile($event)"
                multiple>
            <span class='label label_placeholder' data-js-label>{{inputFilePlaceholderLabel ?? staticText?.common?.upload_copy}}</span>
            <span class='button btn_choose btn_pos'>{{staticText?.common?.upload_browser}}</span>
        </ng-container>
    </div>



</div>
<small class="text-muted flex flex-wrap file_info" *ngIf="!disableFileUpload"> 
<small class=" flex  file_top" *ngIf="!disableFileUpload"> <img src="../../../../assets/images/common/icons_usp/info.png" alt="" class="info_icn me-1 pos_info">
    <span>
        Supported: <span class="text-uppercase" [innerHTML]="getFileFormats"></span><span>; </span>
        </span>
        <span class="">Max Size: {{maxFileSize}}MB</span></small>

<!-- custom error component -->
<custom-error [customErrorLabel]="inputFileErrorLabel" [customErrorIconShowStatus]="inputFileErrorIconStatus"
    [customErrorIconName]="inputFileErrorIconName" [customErrorPreFixPath]="inputFileErrorIconPrefixPath"
    *ngIf="inputFileFormGroup.get(inputFileFormControlName)?.touched && inputFileFormGroup.get(inputFileFormControlName)?.errors">
</custom-error>


<ng-container class="" *ngIf="fileValue">
    <div class="file-list-container" [ngClass]="{'file-exist': fileValue.length}">
        <div class="absolute-container">
            <!-- <label >Attachments</label> -->
            <div class="">
                <div class="row mx-auto">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-12" *ngFor="let item of fileValue;let i = index">
                        <div class="d-flex align-items-center mt-2" [ngClass]="{ 'file_upload': fileValue }">
                            <div class="d-flex align-items-center w-75">
                            <!-- Conditional rendering of file type icons -->
                            <img *ngIf="item?.name?.includes('.xls') || item?.name?.includes('.xlsx') || item?.name?.includes('.csv')"
                                src="/assets/images/common/icons/excel.svg" alt="excel" class="me-2 file_left_icn">
                            <img *ngIf="item?.name?.includes('.doc') || item?.name?.includes('.docx')"
                                src="/assets/images/common/icons/word_doc.svg" alt="Doc" class="me-2 file_left_icn">
                            <img *ngIf="item?.name?.includes('.png') || item?.name?.includes('.jpg') || item?.name?.includes('.svg')"
                                src="/assets/images/common/icons/image.svg" alt="svg" class="me-2 file_left_icn">
                            <img *ngIf="item?.name?.includes('.pdf')" src="/assets/images/common/icons/pdf.svg"
                                alt="pdf" class="me-2 file_left_icn">
                               
                                <span class="text_file cursor-pointer" (click)="openInNewTab(data)">{{item?.name}}</span>

                                </div>
                            <!-- File Name -->
                            
                            <div class="  text_flx">
                                <span class="close_icn_file" (click)="removeFile(i)">

                                    <img src="/assets/images/common/icons/close_black.svg" alt="icon">
                                </span>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</ng-container>