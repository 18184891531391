<section class="comments-page">
    <div class="comments-card-container d-flex flex-column">
        <!-- header part -->
        <div class="comments-header d-flex flex-column justify-content-center">
            <div class="container px-4">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <label class="text-dark fw-bold fs-20"
                        [innerHtml]="commentsDialogData?.title ? commentsDialogData?.title :'Response'">
                    </label>
                    <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
                        (click)="dialogClose(false)">
                </div>
            </div>
        </div>
        <!-- body part -->
        <div class="comments-body">
            <div class="d-flex flex-column px-4 p-3">
                <form [formGroup]="supplierStatusForm">
                    <div class="d-flex flex-column">
                        <div class="p-1">
                            <custom-text-area [inputTextAreaFormGroup]="supplierStatusForm"
                                inputTextAreaFormControlName="comments" [inputTextAreaRequiredStatus]="true"
                                [inputTextAreaLabel]="commentsDialogData?.field_label ? commentsDialogData?.field_label : staticText?.common?.add_comments"
                                [inputTextAreaPlaceHolder]="commentsDialogData?.field_label_placeholder ? commentsDialogData?.field_label_placeholder : staticText?.common?.add_comments"
                                [inputTextAreaErrorLabel]="supplierStatusForm.get('comments').errors?.validation_message">
                            </custom-text-area>
                        </div>
                        <div class="p-1" *ngIf="commentsDialogData?.formType == 'Defer'">
                            <custom-select [inputSelectFormGroup]="supplierStatusForm"
                                inputSelectFormContolName="verfication_status" inputSelectPrefixIcon="verifed_user"
                                [inputSelectRequiredStatus]="false" [inputSelectArray]=rejectStatusList
                                [inputSelectLabel]="commentsDialogData?.field_label ? commentsDialogData?.field_label : staticText?.common?.add_comments"
                                [inputSelectPlaceholder]="staticText?.prequalification?.evaluation_approval?.modal_popup?.input_2"
                                [inputSelectErrorLabel]="supplierStatusForm.get('verfication_status')?.errors?.['validation_message']">
                            </custom-select>
                        </div>


                    </div>
                </form>
            </div>
        </div>
        <!-- footer part -->
        <div class="major-footer">
            <mat-divider></mat-divider>
            <div class="major-modal-actions cursor-pointer p-3">
                <div class="major-modal-close-button px-1">
                    <button class="btn_default_user_small" mat-stroked-button (click)="dialogClose(false)"
                        [innerHtml]="commentsDialogData?.close_button ? commentsDialogData?.close_button:'Cancel'"></button>
                </div>
                <div class="major-modal-event-button px-1">
                    <button mat-raised-button [disabled]="!supplierStatusForm.valid"
                        [ngClass]="commentsDialogData?.button_class ? commentsDialogData?.button_class:'btn_login_user_small'"
                        [innerHtml]="commentsDialogData?.button_type ? commentsDialogData?.button_type:'Submit'"
                        (click)="submitComment(false, supplierStatusForm.value , commentsDialogData)"></button>
                </div>
            </div>
        </div>
    </div>
</section>