<div class="custom-table-filter" *ngIf="!customCategoryfilter">
    <div class="custom-table-container fa-custom-semi-bold">
        <div class="custom-table-filter-button px-3 d-flex justify-content-between align-items-center user-select-none bg-color-wild-sand user-select-none cursor-pointer"
            (click)="filterToggle()" *ngIf="customTableFilterHeaderBoxShow">
            <div class="d-flex flex-row justify-content-around align-items-center p-1">
             
                <img [src]="'/assets/images/common/icons/filter_black.svg'"
                    [alt]="customTableFilterTitleIcon || 'filter'">

                <p class="mb-0 ms-1 fs-16 text-capitalize color-Orient"
                    [innerHtml]="staticText?.common?.table_filter?.table_filter_title || customTableFilterTitle"></p>
            </div>
            <div class="p-1" [ngClass]="{'rotate-filter-arrow': toggleFilter}">
                <img [src]="customTableFilterIconsBasePath+customTableFilterAccordionIcon+'.svg'"
                    [alt]="customTableFilterAccordionIcon || 'filter_arrow'">
            </div>
        </div>
        <ng-container *ngIf="toggleFilter">
            <div class="custom-table-filter-row container-fluid">
                <form [formGroup]="customTableFilterFormGroup"
                    [ngClass]="customTableFilterFormClass ? customTableFilterFormClass : {'': !standAlone, 'justify-content-end':standAlone}"
                    class="fs-14 pt-0  users_end">
                    <div class="form_end">
                    <ng-container
                        *ngTemplateOutlet="control;context:{$implicit:customTableFilterSource}"></ng-container>
                        </div>
                    <!-- <ng-container *ngTemplateOutlet="btn"></ng-container> -->
                    <div class="d-flex users_aply" [class]="customTableFilterLabelClass">
                        <button class="apply_btn" mat-button
                            (click)="applyFilter()">{{staticText?.common?.table_filter?.table_filter_button_apply}}</button>
                        <button class="clear_btn" mat-button
                            (click)="resetFilter()">{{staticText?.common?.table_filter?.table_filter_button_clear}}</button>
                    </div>
                </form>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="customCategoryfilter">
    <div class="custom-table-filter-row" [ngClass]="showOverflowClass?'filter-overflow':''">
        <form [formGroup]="customTableFilterFormGroup" class="row d-flex align-items-center mx-2 filterForm ">
            <ng-container *ngFor="let parent of customCategoryTableFilterSource;index as i">
                <div class="d-flex align-items-center {{toggleCategory[i]?'active-bg':'inactive-bg'}} custom-category-filter flex-row cursor-pointer justify-content-between"
                    (click)="toggleCategoryBtn(i)">
                    <div>{{parent?.name}}</div>
                    <p><img src="/assets/images/common/icons/collapse.svg" alt=""
                            class="{{toggleCategory[i] ?'up_arrow':'down_arrow'}}"></p>
                </div>
                <ng-container *ngIf="toggleCategory[i]">
                    <ng-container *ngTemplateOutlet="control;context:{$implicit:parent?.filter}"></ng-container>
                </ng-container>
            </ng-container>
        </form>
    </div>
    <div class="p-0 custom-table-filter-row">
        <div class="filterForm mx-2 d-flex align-items-center ">
            <ng-container *ngTemplateOutlet="btn;context:{$implicit:true,customClass:true}"></ng-container>
            <div style="width: 47.5rem;overflow-x: scroll;">
                <div class="d-flex flex-row">
                    <ng-container *ngIf="selectedDateValue">
                        <ng-container
                            *ngTemplateOutlet="dateValue;context:{$implicit:selectedDateValue,label:'Date Range',control:'date_range'}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedCreatedOn">
                        <ng-container
                            *ngTemplateOutlet="dateValue;context:{$implicit:selectedCreatedOn,label:'Created On',control:'created_on'}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedLastModifiedOn">
                        <ng-container
                            *ngTemplateOutlet="dateValue;context:{$implicit:selectedLastModifiedOn,label:'Last Modified On',control:'last_modified_on'}"></ng-container>
                    </ng-container>
                    <ng-template #dateValue let-data let-label="label" let-control="control">
                        <div
                            class="d-flex flex-row  justify-content-evenly align-items-center user_box cursor-pointer mx-1">
                            (click)="deSelectedRange(control)">
                            <div class="d-flex flex-column align-items-center">
                                <div style="font-size: .6rem;color: #754113;font-weight: 800;">{{label}}:</div>
                                <div style="font-size: .6rem;padding: 0 .2rem;color: #754113">{{data?.start_date ||
                                    data?.valuation_last_updated_on_start || data?.valuation_created_on_start | date:
                                    'dd MM, yyyy'}} - {{data?.end_date || data?.valuation_last_updated_on_end ||
                                    data?.valuation_created_on_end | date: 'dd MM, yyyy' }}</div>
                            </div>
                            <div><i class="fa fa-times-circle label_color mx-1" aria-hidden="true"></i></div>
                        </div>
                    </ng-template>
                    <ng-container *ngFor="let parent of advanceTypeSelectedItem;index as i">
                        <ng-container *ngFor="let subparent of filterSource[i]?.filter">
                            <ng-container *ngIf="parent[subparent?.key]?.length && (i==0);else objectBlock">
                                <ng-container *ngFor="let data of parent[subparent?.key];index as k;first as first">
                                    <div class="d-flex flex-row  justify-content-evenly align-items-center user_box cursor-pointer mx-1"
                                        *ngIf="data?.display_name &&first "
                                        (click)="deSelectedValue(subparent?.key,true)">
                                        <div class="d-flex flex-column align-items-center">
                                            <div style="font-size: .6rem;color: #754113;font-weight: 800;">
                                                {{subparent?.name}}:</div>
                                            <div style="font-size: .6rem;padding: 0 .2rem;color: #754113">
                                                {{data?.display_name}}</div>
                                        </div>
                                        <div><i class="fa fa-times-circle label_color mx-1" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #objectBlock>
                                <ng-container *ngIf="i!=0">
                                    <div class="d-flex flex-row  justify-content-evenly align-items-center user_box cursor-pointer mx-1"
                                        *ngIf="parent[subparent?.key]?.name || parent[subparent?.key]"
                                        (click)="deSelectedValue(subparent?.key)">
                                        <div class="d-flex flex-column align-items-center">
                                            <div style="font-size: .6rem;color: #754113;font-weight: 800;">
                                                {{subparent?.name}}:</div>
                                            <div style="font-size: .6rem;padding: 0 .2rem;color: #754113;">
                                                {{parent[subparent?.key]?.name || parent[subparent?.key]}}</div>
                                        </div>
                                        <div><i class="fa fa-times-circle label_color mx-1" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #control let-data>
   
    <ng-container *ngFor="let filter of data;index as index" [ngSwitch]="filter?.type">


        <!-- col-xl-3 col-lg-4 col-sm-6  -->
        <div class=" my-2     width_filter {{customTableFilterContainerClass}} "
            [ngClass]=" {'padding-0':standAlone,'padding-2':customCategoryfilter,'date_width':filter?.type == 'date' }"
            *ngIf="filter?.status">
            <ng-container *ngSwitchCase="'dropdown-search'">
                <app-auto-complete-jira class="div_name" [inputAutocompleteFormGroup]="customTableFilterFormGroup"
                    [inputAutocompleteArray]="filter?.source" [inputAutocompleteFilterKey]="filter?.display_key"
                    [inputAutocompleteFormControlName]="filter?.key || filter?.name"
                    [inputAutocompleteLabel]="filter?.name" [inputSelectPlaceholderLabel]="filter?.placeholder"
                    [inputAutocompletePlaceholder]="filter?.placeholder || filter?.name"
                    (inputAutoCompleteEmitter)="inputAutoCompleteEmitter(accumulater($event, index, filter, true))"
                    (clearAction)="inputAutoCompleteEmitter(accumulater($event, index, filter, true))"
                    [removePadding]="customCategoryfilter">
                </app-auto-complete-jira>
            </ng-container>
            <ng-container *ngSwitchCase="'multidropdown-search'">
                <app-custom-multiselect-jira class="multi_jira" [inputSelectArray]="filter?.source"
                    [inputSelectFormContolName]="filter?.key || filter?.name"
                    [inputSelectFormGroup]="customTableFilterFormGroup" [inputSelectRequiredStatus]="false"
                    (inputEmitSelected)="inputAutoCompleteEmitter(accumulater($event, index, filter, true))"
                    (clearAction)="inputAutoCompleteEmitter(accumulater($event, index, filter, true))"
                    [inputSelectPlaceholderLabel]="filter?.placeholder" [inputSelectLabel]="filter?.name"
                    [inputSelectPlaceholder]="filter?.placeholder || filter?.name"
                    [removePadding]="customCategoryfilter">
                </app-custom-multiselect-jira>
            </ng-container>
            <ng-container *ngSwitchCase="'dropdown'">
                <custom-select [inputSelectFormGroup]="customTableFilterFormGroup"
                    [inputSelectFormContolName]="filter?.key || filter?.name"
                    [inputSelectMutipleOptionStatus]="filter?.multiple" [inputSelectArray]="filter?.source"
                    [inputSelectPlaceholder]="filter?.placeholder || filter?.name" [inputSelectLabel]="filter?.name">
                </custom-select>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">

                <custom-date-picker [inputDatePickerFormGroup]="customTableFilterFormGroup" [filterOption]="filter"
                    [inputDatePickerStartView]="'month'" [inputDatePickerMaxDate]="filter?.validations?.max"
                    [inputDatePickerMinDate]="filter?.validations?.min"
                    [inputDatePickerType]="filter?.isRange ? 'range' : 'date'"
                    [inputDatePickerFormControlName]="filter?.key || filter?.name"
                    [inputDatePickerPlaceHolder]="filter?.placeholder || filter?.name"
                    [inputDatePickerLabel]="!standAlone ? filter?.name : ''" [removePadding]="customCategoryfilter">
                </custom-date-picker>

            </ng-container>
            <ng-container *ngSwitchCase="'date_custom_range'">
                <custom-range-date-picker [inputDatePickerFormGroup]="customTableFilterFormGroup"
                    [filterOption]="filter" [inputDatePickerStartView]="'month'"
                    [inputDatePickerMaxDate]="filter?.validations?.max"
                    [inputDatePickerMinDate]="filter?.validations?.min"
                    [inputDatePickerType]="filter?.isRange ? 'range' : 'date'"
                    [inputDatePickerFormControlName]="filter?.key || filter?.name"
                    [inputDatePickerPlaceHolder]="filter?.placeholder || filter?.name"
                    [inputDatePickerLabel]="!standAlone ? filter?.name : ''" [removePadding]="customCategoryfilter">
                </custom-range-date-picker>
            </ng-container>

            <ng-container *ngSwitchCase="'input_dropdown'">
                <custom-input-dropdown [inputDropdownFormGroup]="customTableFilterFormGroup"
                    [inputDropdownFormControlName]="filter?.key?.[1]"
                    [inputFormControlName]="filter?.key?.[0] || filter?.name" [inputDropdownRequiredStatus]="true"
                    inputType="number" [inputDropdownreadonly]="false" [inputDropdownArray]="filter?.source"
                    [inputDropdownFilterKey]="filter?.display_key" [inputDropdownPreffix]="false"
                    [inputDropdownSuffix]="true" [inputDropdownPlaceholder]="staticText?.common?.select_placeholder"
                    [inputRequiredStatus]="false" [inputLabel]="filter?.placeholder || filter?.name"
                    [inputPlaceholder]="filter?.name">
                </custom-input-dropdown>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <custom-input [inputFormGroup]="customTableFilterFormGroup"
                    [inputFormControlName]="filter?.key || filter?.name" [icons]="filter.icons"
                    [inputPlaceholder]="filter?.placeholder || filter?.name" [inputLabel]="filter?.name "
                    [removePadding]="customCategoryfilter">
                </custom-input>
            </ng-container>
        </div>
    </ng-container>
   
</ng-template>


<ng-template #btn let-data>
    <div [ngClass]="{'col-xl-3 col-lg-4 col-sm-6':data}" *ngIf="customTableFilterShowActionGroup"
        [ngClass]="data?'p-0':'ps-1'">
        <div class="d-flex flex-row align-items-center filter-action-button">
            <div class="pe-2" [ngClass]="data?'margin-7':'mt-2'">
                <button id="btn_bg_none" [class]="customTableFilterClass" mat-raised-button
                    [color]="customTableFilterColor" (click)="applyFilter()"
                    [ngClass]="{'filter-apply-btn-disable-state': filterValidation()}" [disabled]="filterValidation()">
                    <img [src]="filterValidation() ? customTableFilterIconsBasePath+customTableFilterIcon+'_disable.svg':customTableFilterIconsBasePath+customTableFilterIcon+'.svg'"
                        [alt]="customTableFilterIcon || 'apply_filter'">
                    <span [class]="customTableFilterLabelClass"
                        [innerHtml]="staticText?.common?.table_filter?.table_filter_button_apply || customTableFilterLabel">
                    </span>
                </button>
            </div>
            <div class="pe-2" [ngClass]="data?'margin-7':'mt-2'">
                <button [class]="customTableResetFilterClass" mat-button [color]="customTableResetFilterColor"
                    (click)="resetFilter()" [ngClass]="{'filter-clear-btn-disable-state': filterValidation()}"
                    [disabled]="filterValidation()">
                    <img [src]="filterValidation() ? customTableFilterIconsBasePath+customTableResetFilterIcon+'_disable.svg' : customTableFilterIconsBasePath+customTableResetFilterIcon+'.svg'"
                        [alt]="customTableResetFilterIcon || 'apply_filter'">
                    <span [class]="customTableResetFilterLabelClass"
                        [innerHtml]="staticText?.common?.table_filter?.table_filter_button_clear || customTableResetFilterLabel">
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-template>