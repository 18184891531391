import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'custom-chips',
  templateUrl: './custom-chips.component.html',
  styleUrls: ['./custom-chips.component.scss']
})
export class CustomChipsComponent implements OnInit {

  @Input() inputChipsFormGroup: FormGroup | any;
  @Input() inputChipsLabel: any;
  @Input() inputChipsPlaceholderLabel: any;
  @Input() inputChipsClass: any;
  @Input() inputChipsPlaceholder: any;
  @Input() inputChipsRequiredStatus: any;
  @Input() inputChipsFormControlName: any;
  @Input() inputChipsAppearance: any = 'outline';
  @Input() inputChipsArray: any[] = [];
  @Input() selectedInputChipsArray: any[] = []
  @Input() inputChipsSeparatorKeysCodes: any[] = [ENTER, COMMA]
  @Input() inputChipsFilterKey: string = 'name';
  @Input() inputSelectedChipsFilterKey: string = 'name';
  @Output() inputChipsAdd = new EventEmitter();
  @Output() inputChipsRemove = new EventEmitter();
  @Output() inputChipsSelectedList = new EventEmitter();

  filterInputChipsArray: Observable<any[]> | any;

  @ViewChildren('inputChipReference') inputChipReference: QueryList<ElementRef> | any;

  constructor() { }

  ngOnInit(): void { this.getFilterInputChipsList() }

  protected addChips(value: any) { this.inputChipsAdd.emit(value) }

  protected removeChips(value: any) { this.inputChipsRemove.emit(value) }

  protected selectedList(value: any) { this.inputChipsSelectedList.emit(value) }

  private getFilterInputChipsList() {
    this.filterInputChipsArray = this.inputChipsFormGroup?.get(this.inputChipsFormControlName).valueChanges.pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.[this.inputChipsFilterKey]),
      map((chips: string | null) => (chips ? this._filterArray(chips, this.inputChipsArray, this.inputChipsFilterKey) : this.inputChipsArray.slice()))
    );

  }

  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }


  protected displayFn = (data: any) => {
    if (data?.[this.inputChipsFilterKey]) {
      return data && data?.[this.inputChipsFilterKey] ? data?.[this.inputChipsFilterKey] : '';
    } else {
      return data
    }
  }

}
