{
    "form_validation": {
        "required_input_msg": "Required",
        "email_input_msg": "Enter a Valid Email Address",
        "number_input_msg": "Enter Numbers Only",
        "mobile_Pattern_input_msg": "",
        "first_digit_zero_input_msg": "First digit should not be Zero",
        "max_length_check_input_msg": {
            "zero_not_allowed_msg": "Zero not allowed",
            "max_length_msg": ""
        },
        "min_length_check_input_msg": {
            "zero_not_allowed_msg": "Zero not allowed",
            "max_length_msg": ""
        },
        "max_equal_length_check_input_msg": {
            "negative_value_msg": "Negative values are not allowed",
            "max_equal_length_msg": "",
            "custom_max_length_msg1":"should allow only",
            "custom_max_length_msg2":"characters as input"
        },
        "confirm_password_input_msg": "Password didn't match.",
        "date_input_msg": "Date format Should be DD/MM/YYY",
        "password_input_msg": "sdsInvalid Format",
        "negative_value_input_msg": "Negative values are not allowed"
    }
}