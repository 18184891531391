<div class="d-flex flex-column">
  <mat-form-field appearance="fill">
    <mat-label>City</mat-label>
    <mat-select
      multiple
      [value]="selectedValues"
      (selectionChange)="updateSelectedValues($event)"
    >
      <mat-select-trigger>
        {{ getDisplayText() }}
      </mat-select-trigger>
      <mat-optgroup *ngFor="let group of optionGroups" [label]="group.name">
        <mat-option *ngFor="let option of group.options" [value]="option.value">
          {{ option.viewValue }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>
