import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): SafeHtml {
    const sanitizedContent = DOMPurify.sanitize(value, {
      SAFE_FOR_TEMPLATES: true,
    });
    return (
      this.sanitizer.sanitize(SecurityContext.HTML, sanitizedContent) ?? ''
    );
  }
}
