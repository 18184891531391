import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import moment from 'moment';

@Component({
  selector: 'custom-range-date-picker',
  templateUrl: './custom-range-date-picker.component.html',
  styleUrls: ['./custom-range-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomRangeDatePickerComponent implements OnInit {

  @Input() inputDatePickerFormGroup: FormGroup | any;
  @Input() inputDatePickerHint: any;
  @Input() inputDatePickerLabel: any;
  @Input() inputDatePickerPlaceholderLabel: any = 'Start date  -  End date';
  @Input() inputDatePickerMinDate: any;
  @Input() inputDatePickerMaxDate: any;
  @Input() inputDatePickerErrorLabel: any;
  @Input() inputDatePickerPlaceHolder: any;
  @Input() inputShowCancel: boolean = true;
  @Input() inputStartKeyName: string = 'start_date';
  @Input() inputEndKeyName: string = 'end_date';
  @Input() inputShowClearButton: boolean = true;
  @Input() inputShowCustomRangeLabel: boolean = true;
  @Input() inputAwaysShowCalendars: boolean = true;
  @Input() inputLinkedCalendars: boolean = false;
  @Input() inputShowRangeLabelOnInput: boolean = false;
  @Input() inputDatePickerFormControlName: any;
  @Input() inputDatePickerLocale: any = 'en-GB';
  @Input() inputDatePickerStartView: any = 'year';
  @Input() inputDatePickerAppearance: any = 'outline';
  @Input() inputDatePickerRequiredStatus: boolean = false;
  @Input() inputDatePickerIconImgBasepath: string = '/assets/images/common/icons/';
  @Input() inputDatePickerToggleIcon: string = 'calendar';
  @Output() inputDatePickerchange = new EventEmitter();
  @Input('inputDatePickerType') inputDatePickerType: string = 'date'; // Range
  selected:any={};
  ranges: any = {};
  @Input() filterOption: any = {};
  @Input()removePadding:boolean = false;

  @ViewChildren('inputDatePickeReference') inputDatePickeReference: QueryList<ElementRef> | any;

  constructor(private _adapter: DateAdapter<any>) {
    
    // this.inputDatePickerFormControlName = {start: null, end: null};
    this.ranges = {
      'Today': [moment(),moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment().format()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment().format()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
    console.log('cistpm rnage:')
   }

  ngOnInit(): void {
    this.setLocale();
  }

  setLocale() {
    // this._adapter.setLocale(this.inputDatePickerLocale);
  }

  onDateChanged(ev?: any) {
    console.log('onDateChanged this.selected: ', ev)
    this.inputDatePickerchange.emit();
  }

  openMethod() {
    this.inputDatePickeReference.first.open();
  }

}
